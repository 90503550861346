<template>
  <div class="notification-container">
    <NotificationBar
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
    />
  </div>
</template>

<script>
import NotificationBar from "@/components/NotificationBar"
import {mapState} from 'vuex'

export default {
  components: {NotificationBar},
  computed: mapState('notifications', ['notifications'])
}
</script>

<style scoped>
.notification-container {
  padding: 0 50px;
  text-align: center;
  left: auto;
}
</style>
