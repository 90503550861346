<template>
  <div v-if="organizers.organizer">
    <div class="columns">
      <div class="column">
        <div class="card">
          <header class="card-header">
            <h2 class="card-header-title">
              {{ organizer.name }}, #{{ organizer.id }}
            </h2>
          </header>
          <div class="card-content">
            <div class="content">
              <table>
                <tbody>
                <tr>
                  <td class="has-text-left">SBK-ID:</td>
                  <td class="has-text-left">{{ organizer.sbkId }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Distrikt:</td>
                  <td class="has-text-left">{{ organizer.district.name }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Tillhör:</td>
                  <td class="has-text-left">{{ organizationsLabel }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Koordinater:</td>
                  <td class="has-text-left">{{ organizer.latLng }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Telefon:</td>
                  <td class="has-text-left">{{ organizer.phone }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">E-post:</td>
                  <td class="has-text-left">{{ organizer.email }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Address:</td>
                  <td class="has-text-left">
                    {{ organizer.address1 }}<br/>
                    <template v-if="organizer.address2">{{ organizer.address2 }}<br/></template>
                    {{ organizer.postalCode }} {{ organizer.city }}
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Hemsida:</td>
                  <td class="has-text-left"><a :href="organizer.url" target="_blank">{{ organizer.url }}</a></td>
                </tr>
                <tr>
                  <td class="has-text-left">Betalningsuppgifter:</td>
                  <td class="has-text-left">
                    <template v-if="organizer.bankgiro">Bankgiro: {{ organizer.bankgiro }}<br/></template>
                    <template v-if="organizer.plusgiro">Plusgiro: {{ organizer.plusgiro }}<br/></template>
                    <template v-if="organizer.swish">Swish: {{ organizer.swish }}</template>
                    <template v-if="organizer.stripe">Swish: {{ organizer.stripe.accountId }}</template>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Kontaktperson:</td>
                  <td class="has-text-left">
                    <contact :contact="organizer.contact"/>
                  </td>
                </tr>
                <tr v-for="(contact, domain) in organizer.domainContacts" :key="contact.id">
                  <td class="has-text-left">Kontaktperson
                    <domain-label :domain="domain"/>
                    :
                  </td>
                  <td class="has-text-left">
                    <contact :contact="contact"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Inlagd:</td>
                  <td class="has-text-left">{{ timestamp(organizer.createdAt) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button @click="$router.go(-1)" class="button is-link">Tillbaka</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapActions, mapState} from "vuex"
import {FormattingFilters} from "@/mixins/FormattingFilters";
import Contact from "@/components/shared/Contact"
import DomainLabel from "@/components/labels/DomainLabel"

export default {
  name: "Organizer",
  props: ['id'],
  components: {Contact, DomainLabel},
  mixins: [FormattingFilters],
  methods: mapActions('organizers', ['get']),
  created() {
    this.get(this.id)
  },
  computed: {
    organizer() {
      return this.organizers.organizer
    },
    organizationsLabel() {
      return this.organizer.organizations.join(', ')
    },
    ...mapState(['organizers'])
  },
}
</script>
