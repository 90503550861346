<template>
  <div class="columns" style="margin-top: 1em;">
    <div class="column is-four-fifths">
      <pre>{{ JSON.stringify(aggregate, null, 2) }}</pre>
    </div>
    <div class="column is-one-fifth">
      <template
          v-for="it in extra"
          :key="it"
      >
        <pre>{{ JSON.stringify(it, null, 2) }}</pre><br/><br/>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Debug",
  props: {
    aggregate: {
      type: Object,
      required: true
    },
    extra: {
      type: Array,
      default:  () => ([])
    }
  }
}
</script>
