<template>
  {{ label }}
</template>

<script>
import _ from 'lodash'
import CallTranslator from "@/i18n/CallTranslator"
import MeritTranslator from "@/i18n/MeritTranslator"
import SelectableGradeTranslator from "@/i18n/SelectableGradeTranslator";

export default {
  name: "ScoreLabel",
  props: {
    result: {
      type: Object,
      required: true
    },
    useScore: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      overridingResultCalls: ['DISABLED', 'FAILED', 'ABORTED', 'DISQUALIFIED', 'INTERRUPTED']
    }
  },
  computed: {
    label() {
      let a = []
      if (this.useScore) {
        a.push(this.result.score)
      }
      if (this.result.calls.length > 0) {
        const overridingCalls = _.filter(this.overridingResultCalls, (c) => {
          return this.result.calls.includes(c)
        })
        if (overridingCalls.length > 0) return CallTranslator.translate(overridingCalls[0])
        a.push(_.map(this.result.calls, (c) => {
          return CallTranslator.translate(c)
        }))
      }
      if (this.result.selectableGrades['OUTCOME'] != null) {
        a.push(SelectableGradeTranslator.translate(this.result.selectableGrades['OUTCOME']))
      }
      if (this.result.selectableGrades['IP_GRADE'] != null) {
        a.push(SelectableGradeTranslator.translate(this.result.selectableGrades['IP_GRADE']))
      }
      if (this.result.selectableGrades['DSB'] != null) {
        a.push(SelectableGradeTranslator.translate(this.result.selectableGrades['DSB']))
      }
      _.forEach(this.result.merits, (m) => { a.push(MeritTranslator.translate(m))})
      return a.join(", ")
    }
  }
}
</script>
