<template>
  <tr>
    <td>
      <router-link :to="{name: 'Dog', params: {id: dog.id}}">
        {{ dog.id }}
      </router-link>
    </td>
    <td class="has-text-left">
      <router-link :to="{name: 'Dog', params: {id: dog.id}}">
        {{ dog.registeredName }}
      </router-link>
    </td>
    <td class="has-text-left">
      <router-link :to="{name: 'Dog', params: {id: dog.id}}">
        {{ dog.name }}
      </router-link>
    </td>
    <td class="has-text-left">
      <router-link :to="{name: 'Dog', params: {id: dog.id}}">
        {{ dog.registration }}
      </router-link>
    </td>
    <td class="has-text-left">
      {{ dog.breed.name }}
    </td>
    <td class="has-text-left">
      <router-link :to="{name: 'Account', params: {id: dog.account.id }}">
        {{ dog.account.name }}
      </router-link>
    </td>
    <td>
      <router-link :to="{name: 'Dog', params: {id: dog.id }}">
        <i class="fas fa-eye"></i>
      </router-link>
    </td>
    <td/>
  </tr>
</template>

<script>

export default {
  name: "DogSummary",
  props: {
    dog: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped></style>
