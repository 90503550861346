<template>
  <tr>
    <td>
      <router-link :to="{ name: 'Account', params: { id: account.id } }">
        {{ account.id }}
      </router-link>
    </td>
    <td class="has-text-left">
      <router-link :to="{ name: 'Account', params: { id: account.id } }">
        {{ account.name }}
      </router-link>
    </td>
    <td class="has-text-left">
      <router-link :to="{ name: 'Account', params: { id: account.id } }">
        {{ account.email }}
      </router-link>
    </td>
    <td class="has-text-left">
      <handler-link
          v-for="(handler, index) in account.handlers"
          :key="handler.id"
          :handler="handler"
          :last-entry="index === account.handlers.length - 1"
      />
    </td>
    <td class="has-text-left">
      <dog-link
          v-for="(dog, index) in account.dogs"
          :key="dog.id"
          :dog="dog"
          :last-entry="index === account.dogs.length - 1"
      />
    </td>
    <td>
      <router-link :to="{name: 'Account', params: {id: account.id}}">
        <i class="fas fa-eye"></i>
      </router-link>
    </td>
    <td/>
  </tr>
</template>

<script>
import DogLink from "@/components/dogs/DogLink";
import HandlerLink from '@/components/handlers/HandlerLink'

export default {
  name: "AccountSummary",
  components: {
    DogLink,
    HandlerLink
  },
  props: {
    account: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped></style>
