<template>
  <router-link :to="'/organizers/' + organizer.id">{{ organizer.name }}</router-link><template v-if="!lastEntry">, </template>
</template>

<script>
export default {
  name: "OrganizerLink",
  props: {
    organizer: {
      type: Object,
      required: true
    },
    lastEntry: {
      type: Boolean,
      default: true
    }
  }
}
</script>
