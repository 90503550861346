import StartService from "@/services/StartService"
import handleApiError from "@/store/handleApiError"

export const namespaced = true

export const state = {
    start: null
}

export const mutations = {
    SET_START(state, start) {
        state.start = start
    }
}

export const actions = {
    get({commit, dispatch}, id) {
        commit('SET_START', null)
        StartService.getStart(id)
            .then(response => {
                commit('SET_START', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching start ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, {root: true})
            })
    },
    getFormData({commit, dispatch}, id) {
        commit('SET_START', null)
        StartService.getStartWithFormData(id)
            .then(response => {
                commit('SET_START', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching start ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, {root: true})
            })
    }
}
