<template>
  <nav class="pagination" role="navigation" aria-label="pagination">
    <template v-if="page > 1">
      <router-link class="pagination-previous" :to="{ name: linkName, query: { ...$route.query, page: page - 1 } }" rel="prev">
        Föregående
      </router-link>
    </template>
    <template v-if="page < nPages">
      <router-link class="pagination-next" :to="{ name: linkName, query: { ...$route.query, page: page + 1 } }" rel="next">
        Nästa
      </router-link>
    </template>
    <ul class="pagination-list">
      <li>
        <router-link :to="{ name: linkName, query: { ...$route.query, page: 1 } }"
                     :class="{'is-current': page === 1}"
                     class="pagination-link">
          1
        </router-link>
      </li>
      <template v-if="page > Math.floor(7 / 2) + 2 && nPages > 7 - rightPart">
        <li>
          <span class="pagination-ellipsis">&hellip;</span>
        </li>
      </template>
      <template v-if="nPages > 1">
        <li v-for="i in centerRange" :key="i">
          <router-link :to="{ name: linkName, query: { ...$route.query, page: i } }"
                       :class="{'is-current': page === i}"
                       class="pagination-link">
            {{ i }}
          </router-link>
        </li>
      </template>
      <template v-if="page < nPages - Math.ceil(7 / 2)  && nPages > 7 - leftPart">
        <li>
          <span class="pagination-ellipsis">&hellip;</span>
        </li>
      </template>
      <template v-if="nPages > 1">
        <li>
          <router-link :to="{ name: linkName, query: { ...$route.query, page: nPages } }"
                       :class="{'is-current': page === nPages}"
                       class="pagination-link">
            {{ nPages }}
          </router-link>
        </li>
      </template>
    </ul>
    <div class="entries">Visar {{firstEntry}} till {{lastEntry}} av {{nTotal}}.</div>
  </nav>
</template>

<script>
import _ from 'lodash'

export default {
  name: "Pagination",
  props: {
    linkName: {
      type: String,
      required: true
    },
    nTotal: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      required: true
    },
    7: {
      type: Number,
      default: 7
    }
  },
  computed: {
    firstEntry() {
      return (this.page - 1) * this.perPage + 1
    },
    lastEntry() {
      return Math.min(this.nTotal, this.page * this.perPage)
    },
    nPages() {
      return Math.ceil(this.nTotal / this.perPage)
    },
    leftPart() {
      const distanceFromEnd = this.nPages - this.page
      const halfCenterSize = Math.floor(7 / 2)
      return 7 - Math.min(halfCenterSize, distanceFromEnd - 1)
    },
    rightPart() {
      const distanceFromStart = this.page - 1
      const halfCenterSize = Math.floor(7 / 2)
      return 7 - Math.min(halfCenterSize, distanceFromStart)
    },
    centerRange() {
      const startIndex = Math.max(2, this.page - this.leftPart + 1)
      const endIndex = Math.min(this.nPages, this.page + this.rightPart)
      return _.range(startIndex, endIndex)
    }
  }
}
</script>

<style scoped>
.entries {
  order: 1;
  margin-right: 50px;
}
</style>
