<template>
  {{ label }}<br/>
</template>

<script>
export default {
  name: 'FunctionaryAuthorizationLabel',
  props: {
    authorization: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      labels: {
        AG_REF: 'Domare agility',
        AG_OFFICER: 'Tävlingsledare agility',
        BR_REF_1A: 'Domare bruks 1A',
        BR_REF_1B: 'Domare bruks 1B',
        BR_REF_2A: 'Domare bruks 2A',
        BR_REF_2B: 'Domare bruks 2B',
        BR_REF_1PTR: 'Domare patrullhund, Lägre-Elit',
        BR_REF_2PTR: 'Domare patrullhund, Lägre-Högre',
        BR_FIG: 'Figurant bruks',
        BR_OFFICER_A: 'Tävlingsledare bruks A',
        BR_OFFICER_B: 'Tävlingsledare bruks B',
        BR_OFFICER_PTR: 'Tävlingsledare patrullhund',
        BR_SECRETARY: 'Tävlingssekreterare bruks',
        IP_REF: 'Domare IGP',
        IP_FIG: 'Figurant IGP',
        IP_TEST_LEADER: 'Provledare IGP',
        IPO_R_REF: 'Domare IPO-R',
        IPO_R_OFFICER: 'Provledare IPO-R',
        LY_REF_13: 'Domare lydnad Start-2',
        LY_REF_1E: 'Domare lydnad Start-3',
        LY_OFFICER: 'Tävlingsledare lydnad',
        LY_SECRETARY: 'Tävlingssekreterare lydnad',
        RA_REF: 'Domare rallylydnad',
        RA_WRITER: 'Skrivare rallylydnad',
        RA_SECRETARY: 'Tävlingssekreterare rallylydnad',
        ME_REF: 'Mentaltestdomare',
        ME_DESCRIBER: 'Mentalbeskrivare',
        ME_EXTERIOR: 'Exteriörbeskrivare',
        ME_MH_TEST_LEADER: 'Mentaltestledare MH',
        ME_MHMT_TEST_LEADER: 'Mentaltestledare MH/MT',
        ME_MH_FIG_A: 'Mentalfigurant MH (A) med avståndslek',
        ME_MHMT_FIG_A: 'Mentalfigurant MT (A) med sociallek',
        ME_MH_FIG_B: 'Mentalfigurant MH (B) utan avståndslek',
        ME_MHMT_FIG_B: 'Mentalfigurant MT (B) utan sociallek',
        MR_REF: 'Domare MR',
        MR_OFFICER: 'Tävlingsledare MR',
        MR_SECRETARY: 'Sekreterare MR',
        MR_FIG_A: 'Figurant A MR',
        MR_FIG_B: 'Figurant B MR',
        RE_REF_A: 'Domare retrieverjaktprov A',
        RE_REF_BA: 'Domare retrieverjaktprov BA',
        RE_REF_B: 'Domare retrieverjaktprov B',
        RE_REF_INT: 'Domare retrieverjaktprov FCI',
        RE_REF_WT: 'Domare retrieverjaktprov WT',
        RE_TEST_LEADER: 'Provledare retrieverjaktprov',
        SP_REF: 'Domare spanieljaktprov',
        TO_REF: 'Domare tollingjaktprov',
        VI_REF: 'Domare viltspår'
      }
    }
  },
  computed: {
    label() {
      return this.labels[this.authorization]
    }
  }
}
</script>
