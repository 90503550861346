<template>
  <div v-if="financials">
    <h2>Ekonomisk redovisning för {{ financials.label }}, #{{ financials.id }}</h2>
    <h3>{{ financials.startDate }}</h3>
    <table class="table is-fullwidth is-striped">
      <thead>
      <tr>
        <th class="has-text-left">ID</th>
        <th class="has-text-left">Klass</th>
        <th class="has-text-left">Starter</th>
        <th class="has-text-left">Stripe</th>
        <th class="has-text-left">Direkt</th>
        <th class="has-text-left">Anmälningsavgifter</th>
        <th class="has-text-left">Avgift till SBK</th>
        <th class="has-text-left">Stripe</th>
        <th class="has-text-left">Direkt</th>
        <th class="has-text-left">Netto</th>
      </tr>
      </thead>
      <tbody>
      <class-financials-table-row v-for="it in sortedClasses" :key="it" :it="it"/>
      <tr class="summary">
        <td class="has-text-left">#{{ financials.id }}</td>
        <td class="has-text-left">Totalt</td>
        <td class="has-text-left">{{ financials.nstarts }}</td>
        <td class="has-text-left">{{ financials.nonline }}</td>
        <td class="has-text-left">{{ financials.ndirect }}</td>
        <td class="has-text-left"><price-label :amount-in-cents="financials.amount"/></td>
        <td class="has-text-left"><price-label :amount-in-cents="financials.sbkFee"/></td>
        <td class="has-text-left"><price-label :amount-in-cents="financials.amountOnline"/></td>
        <td class="has-text-left"><price-label :amount-in-cents="financials.amountDirect"/></td>
        <td class="has-text-left"><price-label :amount-in-cents="financials.amountNet"/></td>
      </tr>
      </tbody>
    </table>
    <h3>Stripe</h3>
    <table class="table is-fullwidth is-striped">
      <thead>
      <tr>
        <th class="has-text-left">Datum och tid</th>
        <th class="has-text-left">Anmälningsavgifter</th>
        <th class="has-text-left">Administrativa avgifter</th>
        <th class="has-text-left">Nettoöverföring</th>
        <th class="has-text-left">Länk till Stripe</th>
      </tr>
      </thead>
      <tbody>
      <settlements-table-row v-for="it in financials.settlements" :key="it" :it="it"/>
      </tbody>
    </table>
    <router-link :to="{ name: 'Competition', id: id }" class="router-link">
      <button class="button is-link mr-3">Tillbaka till tävlingen</button>
    </router-link>
    <debug
        v-if="env.debug"
        :aggregate="financials"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import {mapActions, mapState} from 'vuex'
import Debug from "@/components/shared/Debug"
import ClassFinancialsTableRow from "@/components/competitions/ClassFinancialsTableRow"
import PriceLabel from "@/components/labels/PriceLabel"
import SettlementsTableRow from "@/components/competitions/SettlementsTableRow"

export default {
  name: "CompetitionFinancials",
  components: {
    ClassFinancialsTableRow,
    Debug,
    PriceLabel,
    SettlementsTableRow
  },
  props: ['id'],
  methods: mapActions('competitions', ['getFinancials']),
  created() {
    this.getFinancials(this.id)
  },
  computed: {
    financials() {
      return this.competitions.financials
    },
    sortedClasses() {
      return _.sortBy(this.financials.classFinancials, [function (cf) {
        return cf.label
      }])
    },
    availableOperations() {
      return this.competitions.competition.operations
    },
    ...mapState(['competitions'])
  },
}
</script>

<style scoped>
.summary {
  font-weight: bold;
}
</style>
