export default {
    scoreLabels: {
        ME_MH_1A: [
            "",
            "Avvisar kontaktförsök och/eller kontakt ej tagen inom tid",
            "Undviker kontakt genom att dra sig undan",
            "Accepterar kontakt utan att besvara. Drar sig inte undan.",
            "Tar kontakt själv eller besvarar kontaktförsök.",
            "Överdrivet kontakttagande, ex. hoppar, gnäller, skäller."
        ],
        ME_MH_1B: [
            "",
            "Följer inte med trots flera försök att locka. Ej genomfört inom tid.",
            "Följer med motvilligt.",
            "Följer med men är inte engagerad i testledaren.",
            "Följer med villigt. Engagerar sig i testledaren.",
            "Följer med villigt, är överdrivet engagerad i testledaren, ex. hoppar, gnäller, skäller."
        ],
        ME_MH_1C: [
            "",
            "Avvisar hantering och/eller hantering ej helt genomförd",
            "Undviker, drar sig undan eller söker stöd hos föraren.",
            "Accepterar hantering.",
            "Accepterar, svarar med kontaktbeteende.",
            "Accepterar, svarar med överdrivet kontaktbeteende."
        ],
        ME_MH_2A: [
            "",
            "Leker inte.",
            "Leker inte, men visar intresse.",
            "Startar långsamt, blir aktiv, leker.",
            "Startar snabbt, leker aktivt.",
            "Startar mycket snabbt, leker mycket aktivt."],
        ME_MH_2B: [
            "",
            "Griper inte.",
            "Griper inte, nosar bara på föremålet.",
            "Griper tveksamt eller med framtänderna.",
            "Griper direkt med hela munnen.",
            "Griper direkt, hugger föremålet i farten."],
        ME_MH_2C: [
            "",
            "Griper inte.",
            "Griper tveksamt, släpper, håller, men drar inte emot.",
            "Griper, drar emot, men släpper och tar om. Alt. tuggar.",
            "Griper direkt med hela munnen och drar emot tills testledaren släpper.",
            "Griper direkt med hela munnen, drar emot, rycker, alt. ruskar - även under den passiva delen - tills testledaren släpper."],
        ME_MH_3A1: [
            "",
            "Startar inte, når ej in i banan",
            "Startar, avbryter innan föremålet.",
            "Startar eller springer långsamt. Kan öka farten. Fullföljer.",
            "Startar med hög fart, målinriktad - bromsar in vid bytet.",
            "Startar direkt med hög fart. Springer förbi bytet. Kan vända."],
        ME_MH_3A2: [
            "",
            "Startar inte, når ej in i banan",
            "Startar, avbryter innan föremålet.",
            "Startar eller springer långsamt. Kan öka farten. Fullföljer.",
            "Startar med hög fart, målinriktad - bromsar in vid bytet.",
            "Startar direkt med hög fart. Springer förbi bytet. Kan vända."],
        ME_MH_3B1: [
            "",
            "Nonchalerar föremålet. Alt. springer inte fram.",
            "Griper inte, nosar på föremålet.",
            "Griper tveksamt eller med tidsfördröjning.",
            "Griper direkt, släpper.",
            "Griper direkt. Bibehåller föremålet i minst 3 sek."],
        ME_MH_3B2: [
            "",
            "Nonchalerar föremålet. Alt. springer inte fram.",
            "Griper inte, nosar på föremålet.",
            "Griper tveksamt eller med tidsfördröjning.",
            "Griper direkt, släpper.",
            "Griper direkt. Bibehåller föremålet i minst 3 sek."],
        ME_MH_4: [
            "",
            "Är ouppmärksam, ointresserad, inaktiv.",
            "Är uppmärksam och lugn - står, sitter eller ligger.",
            "Är uppmärksam och i huvudsak lugn. Enstaka aktivitetshöjningar.",
            "Är uppmärksam med ökad aktivitet eller oro efter hand.",
            "Växlar snabbt aktiviteter under momentet. Alt. oro under hela momentet."],
        ME_MH_5A: [
            "",
            "Engageras inte av figuranten.",
            "Kontroll, avbrott förekommer.",
            "Intresserad. Följer figuranten utan avbrott.",
            "Intresserad. Vill iväg, enstaka startförsök.",
            "Mycket intresserad. Vill iväg, upprepade startförsök."],
        ME_MH_5B: [
            "",
            "Visar inga hotbeteenden.",
            "Visar enstaka (1-2) hotbeteenden under momentets första del.",
            "Visar enstaka (1-2) hotbeteenden under momentets första och andra del.",
            "Visar flera hotbeteenden under momentets första del.",
            "Visar flera hotbeteenden under momentets första och andra del."],
        ME_MH_5C: [
            "",
            "Går inte fram till figuranten inom tid.",
            "Går fram när figuranten är aktiv på linjen.",
            "Går fram till den dolda men talande figuranten.",
            "Går fram till figuranten med låg kroppsställning och/eller med tidsfördröjning.",
            "Går fram direkt till figuranten utan hjälp."],
        ME_MH_5D: [
            "",
            "Visar inget intresse.",
            "Leker inte, men visar intresse.",
            "Leker, kan gripa försiktigt, drar inte emot.",
            "Griper. Drar emot, men kan släppa och ta om.",
            "Griper direkt. Drar emot, släpper inte."],
        ME_MH_5E: [
            "",
            "Visar inget intresse.",
            "Blir aktiv men avbryter.",
            "Är aktiv med figuranten när denne är aktiv.",
            "Är aktiv med figuranten. Visar även intresse mot passiv figurant.",
            "Uppmanar passiv figurant till fortsatt lek."],
        ME_MH_6A: [
            "",
            "Stannar inte eller kort stopp.",
            "Hukar sig och stannar.",
            "Gör undanmanöver utan att vända bort blicken.",
            "Flyr högst 5 meter.",
            "Flyr mer än 5 meter."],
        ME_MH_6B: [
            "",
            "Visar inga hotbeteenden.",
            "Visar enstaka hotbeteenden.",
            "Visar flera hotbeteenden under längre tid.",
            "Visar flera hotbeteenden och någon attack.",
            "Visar hotbeteenden och attacker som kan avslutas med bett."],
        ME_MH_6C: [
            "",
            "Går inte fram inom tid.",
            "Går fram när föraren sitter på huk och talar till overallen samt lockar på hunden.",
            "Går fram till overallen när föraren står bredvid.",
            "Går fram till overallen när föraren gått halva avståndet.",
            "Går fram till overallen utan hjälp."],
        ME_MH_6D: [
            "",
            "Ingen tempoförändring eller undanmanöver.",
            "Liten båge eller tempoväxling vid någon av passagerna.",
            "Båge eller tempoväxling vid 1:a passagen. Minskat utslag vid 2:a passagen.",
            "Båge eller tempoväxling vid minst 2 passager utan minskad intensitet.",
            "Visar stort mått av rädsla eller ökad rädsla efter samtliga passager."],
        ME_MH_6E: [
            "",
            "Visar inget intresse.",
            "Stannar upp. Luktar eller tittar på overallen vid 1 tillfälle.",
            "Stannar upp. Luktar eller tittar på overallen vid minst 2 tillfällen.",
            "Biter i eller leker med overallen. Intresset minskar efter hand.",
            "Biter i eller leker med overallen vid 2 eller flera passager."],
        ME_MH_7A: [
            "",
            "Stannar inte eller kort stopp.",
            "Hukar sig och stannar.",
            "Gör undanmanöver utan att vända bort blicken.",
            "Flyr högst 5 meter.",
            "FLyr mer än 5 meter."],
        ME_MH_7B: [
            "",
            "Går inte fram inom tid.",
            "Går fram när föraren sitter på huk och talar till skramlet samt lockar på hunden.",
            "Går fram till skramlet när föraren står bredvid.",
            "Går fram till skramlet när föraren gått halva avståndet.",
            "Går fram till skramlet utan hjälp."],
        ME_MH_7C: [
            "",
            "Ingen tempoförändring eller undanmanöver.",
            "Liten båge eller tempoväxling vid någon av passagerna.",
            "Båge eller tempoväxling vid 1:a passagen. Minskat utslag vid 2:a passagen.",
            "Båge eller tempoväxling vid minst 2 passager utan minskad intensitet.",
            "Visar stort mått av rädsla eller ökad rädsla efter samtliga passager."],
        ME_MH_7D: [
            "",
            "Visar inget intresse.",
            "Stannar upp. Luktar eller tittar mot skramlet vid 1 tillfälle.",
            "Stannar upp. Luktar eller tittar mot skramlet vid minst 2 tillfällen.",
            "Biter i eller leker med skramlet. Intresset minskar efter hand.",
            "Biter eller leker med skramlet vid 2 eller flera passager."],
        ME_MH_8A: [
            "",
            "Visar inga hotbeteenden.",
            "Visar enstaka hotbeteenden.",
            "Visar flera hotbeteenden under längre tid.",
            "Visar flera hotbeteenden och någon attack.",
            "Visar hotbeteenden och flera attacker."],
        ME_MH_8B: [
            "",
            "Enstaka kontroll, därefter inget intresse/engagerar sig inte.",
            "Tittar mot spökena då och då.",
            "Kontrollerar och/eller handlar mot spökena. Långa avbrott. Ex. halva sträckan båda spökena eller ett spöke hela sträckan.",
            "Kontrollerar och/eller handlar mot båda spökena. Kortare avbrott.",
            "Kontrollerar och/eller handlar mot båda spökena under hela momentet."],
        ME_MH_8C: [
            "",
            "Uppehåller sig framför eller bredvid föraren.",
            "Uppehåller sig i huvudsak framför eller bredvid föraren. Någon avståndsreglering.",
            "Uppehåller sig i huvudsak framför eller bredvid föraren. Växlar mellan flyktstart och kontroll.",
            "Uppehåller sig i huvudsak bakom föraren. Växlar mellan flyktstart och kontroll.",
            "Backar längre än kopplets längd eller lämnar platsen, alt. flyr."],
        ME_MH_8D: [
            "",
            "Går fram till fig. när föraren tagit av figurantens huvudbonad. Går inte fram inom tid.",
            "Går fram till fig. när föraren talar med fog. och lockar på hunden.",
            "Går fram till fig. när föraren står bredvid.",
            "Går fram till fig. när föraren gått halva avståndet.",
            "Går fram till fig. utan hjälp."],
        ME_MH_8E: [
            "",
            "Avvisar eller undviker kontaktförsök. Kontakt ej tagen inom tid.",
            "Accepterar kontakten från fig. utan att besvara den.",
            "Besvara kontakten från figuranten.",
            "Tar själv kontakt med figuranten.",
            "Intensivt kontaktbeteende mot figuranten. Kan ex. hoppa och gnälla."],
        ME_MH_9A: [
            "",
            "Leker inte.",
            "Leker inte, men visar intresse.",
            "Startar långsamt, blir aktiv, leker.",
            "Startar snabbt, leker aktivt.",
            "Startar mycket snabbt, leker mycket aktivt."],
        ME_MH_9B: [
            "",
            "Griper inte.",
            "Griper inte, nosar bara på föremålet.",
            "Griper tveksamt eller med framtänderna.",
            "Griper direkt med hela munnen.",
            "Griper direkt, hugger föremålet i farten."],
        ME_MH_10: [
            "",
            "Visar ingen berördhet. Snabb kontroll och sedan helt oberörd.",
            "Avtagande kontroller under lek/passivitet. Därefter oberörd.",
            "Riktar intresse mot skytten, publiken eller annat, men återgår till lek/passivitet.",
            "Avbryter lek eller passivitet. Låser sig mot publiken, skytten eller annat. Ptergår inte till lek/passivitet.",
            "Berörd, rädd. Alt. Vill lämna platsen, försöker fly. Alt. Föraren avstår skott."
        ],
        ME_MT_SAMARBETE_FOR: [
            "",
            "Visar inget eller kortvarigt intresse för föremålet.",
            "Leker själv, kommer inte tillbaka.",
            "Leker själv, kommer efter upprepade uppmaningar från föraren.",
            "Leker själv - men kommer tillbaka med tidsfördröjning.",
            "Kommer tillbaka utan uppmaning för fortsatt lek."],
        ME_MT_SAMARBETE_TL: [
            "",
            "Visar inget eller kortvarigt intresse för föremålet.",
            "Leker själv, kommer inte tillbaka.",
            "Leker själv, kommer efter upprepade uppmaningar från TL.",
            "Leker själv - men kommer tillbaka med tidsfördröjning.",
            "Kommer tillbaka utan uppmaning för fortsatt lek."],
        ME_MT_TA_5: [
            "",
            "Griper ej/nosar på föremålet.",
            "Griper försiktigt.",
            "Griper direkt men ej med hela munnen.",
            "Griper med hela munnen, någon tidsfördröjning.",
            "Griper direkt med hela munnen."],
        ME_MT_TA_40: [
            "",
            "Griper ej/nosar på föremålet.",
            "Griper försiktigt.",
            "Griper direkt men ej med hela munnen.",
            "Griper med hela munnen, någon tidsfördröjning.",
            "Griper direkt med hela munnen."],
        ME_MT_HALLA_5: [
            "",
            "Griper ej.",
            "Löst bett, tappar taget vid upprepade tillfällen.",
            "Byter tag upprepade gånger.",
            "Byter tag vid något tillfälle.",
            "Fast bett, alt växlar till bättre bett 1 ggr som bibehålls."],
        ME_MT_HALLA_40: [
            "",
            "Griper ej.",
            "Löst bett, tappar taget vid upprepade tillfällen.",
            "Byter tag upprepade gånger.",
            "Byter tag vid något tillfälle.",
            "Fast bett, alt växlar till bättre bett 1 ggr som bibehålls."],
        ME_MT_SLITA_5: [
            "",
            "Håller ej.",
            "Håller, men drar inte emot.",
            "Växlar mellan att hålla och dra emot.",
            "Drar emot under huvuddelen av momentet.",
            "Drar emot under hela momentet."],
        ME_MT_SLITA_40: [
            "",
            "Håller ej.",
            "Håller, men drar inte emot.",
            "Växlar mellan att hålla och dra emot.",
            "Drar emot under huvuddelen av momentet.",
            "Drar emot under hela momentet."],
        ME_MT_FORFOLJANDE: [
            "",
            "Startar inte eller når inte in i banan.",
            "Startar men avbryter.",
            "Startar med hög fart, springer förbi bytet.",
            "Startar tveksamt, eller håller låg fart, fullföljer.",
            "Startar med hög fart - målinriktad."],
        ME_MT_GRIPANDE: [
            "",
            "Nonchalerar föremålet.",
            "Griper inte, nosar på föremålet.",
            "Griper tveksamt eller med tidsfördröjning.",
            "Griper direkt, släpper.",
            "Griper direkt. Behåller föremålet."],
        ME_MT_UTHALLIGHET: [
            "",
            "Försöker ej.",
            "Jobbar mindre än 30 sekunder.",
            "Jobbar i 30 sekunder.",
            "Jobbar i 60 sekunder.",
            "Jobbar i 90 sekunder."],
        ME_MT_SJALVSAKERHET: [
            "",
            "Ängslig/socialt osäker/går ej att hantera.",
            "Visar social osäkerhet i flera situationer.",
            "Visar social osäkerhet i enstaka situationer.",
            "Visar otrivsel/något undvikande i någon situation.",
            "Är socialt säker i alla situationer."],
        ME_MT_SOC_NYFIKENHET: [
            "",
            "Undviker, skygg",
            "Överdrivet nyfiken, påträngande.",
            "Neutral, låter sig klappas.",
            "Besvarar när figurant bjuder.",
            "Normalt nyfiken när anledning finns."],
        ME_MT_SAMSPEL: [
            "",
            "Svarar ej på lekinviter.",
            "Svarar svagt.",
            "Svarar på lek & lekinviter.",
            "Spelar med, är aktiv.",
            "Inbjuder passiv figurant."],
        ME_MT_HANDLING: [
            "",
            "Försöker inte lösa problemen.",
            "Försöker endast kortvarigt lösa problemen.",
            "Löser uppgifter i de flesta situationer.",
            "Är aktiv för att lösa uppgifter i de flesta situationer.",
            "Är aktiv för att lösa uppgifter i samtliga situationer."],
        ME_MT_ANPASSNING: [
            "",
            "Låg intensitet i de flesta testsituationer.",
            "Hög intensitet i de flesta testsituationer.",
            "Låg intensitet i några testsituationer.",
            "Hög intensitet i några testsituationer.",
            "Anpassar intensiteten i testsituationerna."],
        ME_MT_KONCENTRATION: [
            "",
            "Okoncentrerad i de flesta testsituationer.",
            "Viss koncentration - flera långa avbrott - gör annat.",
            "I huvudsak koncentrerad, men med avbrott i flera situationer.",
            "Koncentrerad, men med något kort avbrott.",
            "Obruten koncentration."],
        ME_MT_AVREAKTION: [
            "",
            "Kan ej avreagera.",
            "Tar för lång tid för avreaktion.",
            "Avreagerar med någon tidsfördröjning i flera situationer.",
            "Avreagerar med någon tidsfördröjning i enstaka situationer.",
            "Avreagerar snabbt i alla situationer."],
        ME_MT_MINNESBILDER: [
            "",
            "Störs av minnesbilder, många undvikande beteenden.",
            "Kommer väl ihåg, ofta undvikande beteenden.",
            "Visar minnesbilder, med enstaka undvikande beteenden.",
            "Visar flera minnesbilder utan undvikande beteenden.",
            "Visar enstaka minnesbilder utan undvikande beteenden."],
        ME_MT_RADSLA: [
            "",
            "Mycket rädd. Flykt eller passivitet.",
            "Rädd. Enstaka flykter/flyktstarter.",
            "Viss rädsla. Undanmanöver - avståndsreglerar.",
            "I stort orädd. Enstaka avståndsreglering.",
            "Helt orädd."],
        ME_MT_AGGRESSIVITET: [
            "",
            "Visar kvarstående aggression.",
            "Visar stor aggression.",
            "Visar ingen eller kortvarig aggression.",
            "Visar liten aggression.",
            "Visar måttlig aggression."],
        ME_MT_NYFIKENHET: [
            "",
            "Går inte fram.",
            "Lång tid med hjälp för att gå fram.",
            "Går fram med hjälp.",
            "Går fram utan hjälp, någon tidsfördröjning.",
            "Snabbt fram utan hjälp i samtliga situationer."],
        ME_MT_SKOTT: [
            "",
            "Avviker från platsen.",
            "Undvikande reaktioner alternativt låsningar, kvar på platsen.",
            "Aktivitetshöjande reaktioner. Kvar på platsen, ingen ängslan.",
            "Kontroll vid flera skott.",
            "Ingen reaktion, kontroll första skottet."],
        ME_MT_HOT: [
            "",
            "Stel kropp morr riktat mot testledaren. Bitintention.",
            "Stel kropp morr riktat mot testledaren.",
            "Stel kropp under hela hanteringen.",
            "Stelnar till under kort stund, slappnar av.",
            "Inga imponerbeteenden."]
    }
}
