<template>
  <table
      v-if="result.partGrades.length > 1"
      class="table is-fullwidth is-striped"
  >
    <thead>
    <tr>
      <th class="has-text-left">Moment</th>
      <th>Koefficient</th>
      <template v-for="it in result.competitionClass.ngrades" :key="it">
        <th>Betyg
          <template v-if="result.competitionClass.ngrades > 1">{{ it }}</template>
        </th>
        <th>Poäng
          <template v-if="result.competitionClass.ngrades > 1">{{ it }}</template>
        </th>
      </template>
      <template v-if="result.competitionClass.ngrades > 1">
        <th>Medelbetyg</th>
        <th>Totalt</th>
      </template>
    </tr>
    </thead>
    <tbody>
    <part-grade-table-row
        v-for="it in result.partGrades"
        :key="it"
        :it="it"
        :ngrades="result.competitionClass.ngrades"
    />
    </tbody>
  </table>
</template>

<script>
import PartGradeTableRow from "@/components/results/PartGradeTableRow"

export default {
  name: "DefaultResultDetails",
  components: {
    PartGradeTableRow
  },
  props: {
    result: {
      type: Object,
      required: true
    }
  }
}
</script>
