import ApiClient from '@/services/ApiClient'
import _ from "lodash"
import SelectableGradeTranslator from "@/i18n/SelectableGradeTranslator"

export default {
    getResult(id) {
        return ApiClient.get('/results/' + id)
    },

    getWithFormData(id) {
        return ApiClient.get(`/results/${id}/form-data`)
    },

    update(result) {
        const aggregate = result.aggregate
        console.log(aggregate)
        const refereeGrades = _.reduce(
            aggregate.partGrades,
            (acc, partGrade) => ({...acc, [partGrade.id]: partGrade.refereeGrades}),
            {}
        )
        console.log(refereeGrades)
        const payload = {
            resultId: aggregate.id,
            refereeGrades: refereeGrades,
            calls: aggregate.calls,
            merits: aggregate.merits,
            selectableGrades: {}
        }
        _.forOwn(result.selectableGrades, function (value, key) {
            const propertyName = SelectableGradeTranslator.propertyName(key)
            payload.selectableGrades[key] = aggregate.selectableGrades[propertyName]
        })

        console.log(JSON.stringify(payload, null, 2))
        return ApiClient.post('/update-result', payload)
    }

}
