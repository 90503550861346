<template>
  <tr>
    <td>
      <router-link :to="{name: 'Handler', params: {id: handler.id}}">
        {{ handler.id }}
      </router-link>
    </td>
    <td class="has-text-left">
      <router-link :to="{name: 'Handler', params: {id: handler.id}}">
        {{ handler.familyName }}, {{ handler.givenName }}
      </router-link>
    </td>
    <td class="has-text-left">
      <router-link :to="{name: 'Account', params: {id: handler.account.id}}">
        {{ handler.account.name }}
      </router-link>
    </td>
    <td class="has-text-left">
      <router-link :to="'/'">
        {{ handler.organizer.name }}
      </router-link>
    </td>
    <td>
      <router-link :to="{name: 'Handler', params: {id: handler.id}}">
        <i class="fas fa-eye"></i>
      </router-link>
    </td>
    <td/>
  </tr>
</template>

<script>

export default {
  name: "HandlerSummary",
  props: {
    handler: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped></style>
