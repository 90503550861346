<template>
  <div v-if="organizers.list">
    <table class="table is-striped is-fullwidth">
      <thead>
      <tr>
        <th class="id-width">ID</th>
        <th class="has-text-left">SBK-ID</th>
        <th class="has-text-left">Name</th>
        <th class="has-text-left">Organisationer</th>
        <th class="has-text-left">Distrikt</th>
        <th class="icon-width"></th>
        <th class="icon-width"></th>
      </tr>
      </thead>
      <tbody>
      <OrganizerSummary v-for="it in organizers.list" :key="it.id" :organizer="it"/>
      </tbody>
    </table>
    <Pagination link-name="Organizers" :per-page="perPage" :page="page" :n-total="nOrganizers"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/Pagination"
import {mapState, mapActions} from "vuex"
import OrganizerSummary from "@/components/organizers/OrganizerSummary";

export default {
  name: "Organizers",
  components: {
    Pagination,
    OrganizerSummary
  },
  created() {
    this.perPage = 10
    this.getList({
      perPage: this.perPage,
      page: this.page,
      q: this.$route.query.q
    })
  },
  methods: mapActions('organizers', ['getList']),
  computed: {
    nOrganizers() {
      return this.$store.state.organizers.nTotal
    },
    page() {
      return parseInt(this.$route.query.page) || 1
    },
    hasNextPage() {
      return this.$store.state.organizers.nTotal > this.page * this.perPage
    },
    ...mapState(['organizers'])
  },
}
</script>
