import FunctionaryService from "@/services/FunctionaryService"
import handleApiError from "@/store/handleApiError"

export const namespaced = true

export const state = {
    functionary: null,
    list: [],
    nTotal: 0
}

export const mutations = {
    SET_FUNCTIONARY(state, functionary) {
        state.functionary = functionary
    },
    SET_FUNCTIONARIES(state, functionaries) {
        state.list = functionaries
    },
    SET_N_FUNCTIONARIES(state, nTotal) {
        state.nTotal = nTotal
    }
}

export const actions = {
    getList({commit, dispatch}, {perPage, page, q}) {
        commit('SET_FUNCTIONARIES', [])
        FunctionaryService.get(perPage, page, q)
            .then(response => {
                commit('SET_N_FUNCTIONARIES', parseInt(response.headers['x-total-count']))
                commit('SET_FUNCTIONARIES', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching functionaries: ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    },
    get({commit, dispatch}, id) {
        commit('SET_FUNCTIONARY', null)
        FunctionaryService.getById(id)
            .then(response => {
                commit('SET_FUNCTIONARY', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching functionary ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    }
}
