import {createRouter, createWebHistory} from 'vue-router'
import Account from '@/views/accounts/Account'
import Accounts from '@/views/accounts/Accounts'
import Competition from '@/views/competitions/Competition'
import CompetitionFinancials from '@/views/competitions/CompetitionFinancials'
import CompetitionForm from '@/views/competitions/CompetitionForm'
import Competitions from '@/views/competitions/Competitions'
import CompetitionClass from '@/views/competition-classes/CompetitionClass'
import CompetitionClassForm from '@/views/competition-classes/CompetitionClassForm'
import Dog from '@/views/dogs/Dog'
import Dogs from '@/views/dogs/Dogs'
import Functionary from "@/views/functionaries/Functionary"
import Functionaries from "@/views/functionaries/Functionaries"
import Handler from '@/views/handlers/Handler'
import Handlers from '@/views/handlers/Handlers'
import LogIn from '@/views/LogIn'
import Organizer from "@/views/organizers/Organizer"
import Organizers from "@/views/organizers/Organizers"
import Result from "@/views/results/Result"
import ResultForm from "@/views/results/ResultForm"
import ResultList from "@/views/result-lists/ResultList"
import ResultLists from "@/views/result-lists/ResultLists"
import Start from "@/views/starts/Start"
import StartForm from "@/views/starts/StartForm"
import NewCompetitionWizard from "@/views/wizards/NewCompetitionWizard"

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Competitions
    },
    {
        path: '/accounts',
        name: 'Accounts',
        component: Accounts
    },
    {
        path: '/accounts/:id',
        name: 'Account',
        component: Account,
        props: true
    },
    {
        path: '/competitions',
        name: 'Competitions',
        component: Competitions
    },
    {
        path: '/new-competition-wizard',
        name: 'NewCompetitionWizard',
        component: NewCompetitionWizard
    },
    {
        path: '/competitions/:id',
        name: 'Competition',
        component: Competition,
        props: true
    },
    {
        path: '/competitions/:id/edit',
        name: 'CompetitionForm',
        component: CompetitionForm,
        props: true
    },
    {
        path: '/competitions/:id/financials',
        name: 'CompetitionFinancials',
        component: CompetitionFinancials,
        props: true
    },
    {
        path: '/competitions/:id/result-lists',
        name: 'ResultLists',
        component: ResultLists,
        props: true
    },
    {
        path: '/competition-classes/:id',
        name: 'CompetitionClass',
        component: CompetitionClass,
        props: true
    },
    {
        path: '/competition-classes/:id/result-list',
        name: 'ResultList',
        component: ResultList,
        props: true
    },
    {
        path: '/competition-classes/:id/edit',
        name: 'CompetitionClassForm',
        component: CompetitionClassForm,
        props: true
    },
    {
        path: '/dogs',
        name: 'Dogs',
        component: Dogs
    },
    {
        path: '/dogs/:id',
        name: 'Dog',
        component: Dog,
        props: true
    },
    {
        path: '/functionaries',
        name: 'Functionaries',
        component: Functionaries
    },
    {
        path: '/functionaries/:id',
        name: 'Functionary',
        component: Functionary,
        props: true
    },
    {
        path: '/handlers',
        name: 'Handlers',
        component: Handlers
    },
    {
        path: '/handlers/:id',
        name: 'Handler',
        component: Handler,
        props: true
    },
    {
        path: '/log-in',
        name: 'LogIn',
        component: LogIn
    },
    {
        path: '/organizers',
        name: 'Organizers',
        component: Organizers
    },
    {
        path: '/organizers/:id',
        name: 'Organizer',
        component: Organizer,
        props: true
    },
    {
        path: '/results/:id',
        name: 'Result',
        component: Result,
        props: true
    },
    {
        path: '/results/:id/edit',
        name: 'ResultForm',
        component: ResultForm,
        props: true
    },
    {
        path: '/starts/:id',
        name: 'Start',
        component: Start,
        props: true
    },
    {
        path: '/starts/:id/edit',
        name: 'StartForm',
        component: StartForm,
        props: true
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: 'about' */ '../views/About.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'LogIn' && localStorage.getItem('user') == null) {
        next('/log-in')
    } else {
        next()
    }
})

export default router
