<template>
  <div v-if="competitions.list">
    <table class="table is-striped is-fullwidth">
      <thead>
      <tr>
        <th>ID</th>
        <th class="has-text-left">Namn</th>
        <th>Datum</th>
        <th>Sista anmälningdatum</th>
        <th class="has-text-left">Arrangör</th>
        <th>Status</th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <CompetitionSummary v-for="c in competitions.list" :key="c.id" :competition="c"/>
      </tbody>
    </table>
    <router-link
        v-if="false"
        :to="{ name: 'NewCompetitionWizard' }"
        class="router-link mr-4"
    >
      <button class="button is-link mb-3">
        <span class="icon">
          <i class="fa fa-plus-circle"></i>
        </span>
        <span>Lägg till en tävling</span>
      </button>
    </router-link>
    <router-link
        v-if="!showArchived"
        :to="{ name: 'Competitions', query: {'states': 'archived'} }"
        class="router-link"
    >
      <button class="button is-link mb-3">
        <span class="icon">
          <i class="fa fa-edit"></i>
        </span>
        <span>Visa arkiverade</span>
      </button>
    </router-link>
    <router-link
        v-if="showArchived"
        :to="{ name: 'Competitions', query: {'states': 'non-archived'} }"
        class="router-link"
    >
      <button class="button is-link mb-3">
        <span class="icon">
          <i class="fa fa-edit"></i>
        </span>
        <span>Visa ej arkiverade</span>
      </button>
    </router-link>
    <Pagination link-name="Competitions" :per-page="perPage" :page="page" :n-total="nCompetitions"/>
    <debug
        v-if="env.debug"
        :aggregate="competitions"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import CompetitionSummary from "@/components/competitions/CompetitionSummary"
import Debug from "@/components/shared/Debug"
import Pagination from "@/components/Pagination"
import {mapState, mapActions} from "vuex"
import {useRoute} from 'vue-router'

export default {
  name: "Competitions",
  components: {
    Debug,
    Pagination,
    CompetitionSummary
  },
  data() {
    return {
      perPage: 10
    }
  },
  created() {
    console.log(useRoute().query.states)
    this.list({
      perPage: this.perPage,
      page: this.page,
      states: useRoute().query.states
    })
  },
  methods: {
    ...mapActions('competitions', ['list']),
  },
  computed: {
    showArchived() {
      return this.$route.query.states === 'archived'
    },
    nCompetitions() {
      return this.$store.state.competitions.nTotal
    },
    page() {
      return parseInt(this.$route.query.page) || 1
    },
    hasNextPage() {
      return this.$store.state.competitions.nTotal > this.page * this.perPage
    },
    ...mapState(['competitions'])
  },
}
</script>
