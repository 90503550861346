import ApiClient from '@/services/ApiClient'

export default {
  get(perPage, page, q) {
    let qParam
    if (typeof q !== 'undefined') {
      qParam = '&q=' + q
    } else {
      qParam = ''
    }
    return ApiClient.get('/organizers?perPage=' + perPage + '&page=' + page + qParam)
  },

  getById(id) {
    return ApiClient.get('/organizers/' + id)
  }
}
