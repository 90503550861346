<template>
  <div v-if="competitionClasses.competitionClass">
    <form @submit.prevent="handleSubmit">

      <base-input
          v-if="isEditable('NAME')"
          v-model="competitionClass.name"
          label="Namn"
          type="text"
      />

      <base-select
          v-if="isEditable('TYPE')"
          :options="typeOptions"
          v-model="competitionClass.type"
          label="Class"
      />

      <base-checkbox
          v-if="isEditable('TYPE')"
          v-model="competitionClass.official"
          label="Officiell"
      />

      <base-multiple-select
          v-if="isEditable('CATEGORIES')"
          :options="categoryOptions"
          v-model="competitionClass.categories"
          label="Typ"
          multiple
      />

      <base-checkbox
          v-if="isEditable('KEEP_REGISTRATION_CLOSED')"
          v-model="competitionClass.keepRegistrationClosed"
          label="Behåll anmälan stängd"
          help="Kryssa i denna ruta om du vill att klassen ska förbli stängd för anmälan även om tävlingen öppnas för anmälan igen."
      />

      <base-input
          v-if="isEditable('TYPE')"
          v-model="priceInSek"
          label="Anmälningsavgift"
          type="text"
      />

      <base-input
          v-if="isEditable('TYPE')"
          v-model="maxStarts"
          label="Max antal starter"
          type="text"
      />

      <base-input
          v-if="isEditable('TYPE')"
          v-model="competitionClass.nreferees"
          label="Antal domare"
          type="text"
      />

      <base-input
          v-if="isEditable('TYPE')"
          v-model="competitionClass.ngrades"
          label="Antal betyg"
          type="text"
          disabled="disabled"
      />

      <div v-if="isEditable('FUNCTIONARIES')">
        <base-select
            v-for="index in competitionClass.nreferees"
            :key="index"
            :options="refereeOptions"
            v-model="competitionClass.referees[index.toString()]"
            :label="'Domare ' + index"
        />

        <base-select
            :options="competitionOfficerOptions"
            v-model="competitionClass.competitionOfficer"
            label="Tävlingsledare"
        />

        <base-select
            v-for="index in competitionClass.nfigs"
            :key="index"
            :options="figOptions"
            v-model="competitionClass.figs[index.toString()]"
            :label="'Figurant ' + index"
        />

        <base-select
            :options="secretaryOptions"
            v-model="competitionClass.secretary"
            label="Sekreterare/Skrivare"
        />

      </div>

      <br/>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label"></label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <button
                  class="button mr-6"
                  @click.prevent="onCancel"
              >
                Avbryt
              </button>
              <button class="button is-link">Spara ändringarna</button>
            </div>
          </div>
        </div>
      </div>

    </form>
    <debug
        v-if="env.debug"
        :aggregate="competitionClass"
        :extra="[
            availableOperations,
            editableFields,
            competitionClasses.competitionClass.functionaries
        ]"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import {mapActions, mapState} from "vuex";
import BaseCheckbox from "@/components/forms/BaseCheckbox"
import BaseInput from "@/components/forms/BaseInput"
import BaseMultipleSelect from "@/components/forms/BaseMultipleSelect"
import BaseSelect from "@/components/forms/BaseSelect"
import CompetitionClassService from "@/services/CompetitionClassService"
import CompetitionClassCategoryTranslator from "@/i18n/CompetitionClassCategoryTranslator"
import Debug from "@/components/shared/Debug"

export default {
  components: {
    BaseCheckbox,
    BaseInput,
    BaseMultipleSelect,
    BaseSelect,
    Debug
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('competitionClasses', ['get', 'getFormData']),
    isEditable(field) {
      return _.includes(this.editableFields, field)
    },
    onCancel() {
        this.$router.back()
    },
    handleSubmit() {
      const competitionClassId = this.id
      const router = this.$router
      CompetitionClassService.updateCompetitionClass(this.competitionClasses.competitionClass)
          .then(function (response) {
            console.log('Successfully updated competition class ' + competitionClassId, response)
            router.push({name: 'CompetitionClass', id: competitionClassId})
          })
          .catch(function (error) {
            console.log('An exception occurred while updating competition ' + competitionClassId, error)
          })
    }
  },
  created() {
    this.getFormData(this.id)
  },
  computed: {
    competitionClass() {
      return this.competitionClasses.competitionClass.aggregate
    },
    availableOperations() {
      return this.competitionClasses.competitionClass.operations
    },
    editableFields() {
      return this.competitionClasses.competitionClass.editableFields
    },
    typeOptions() {
      return this.competitionClasses.competitionClass.siblingTypes
    },
    categoryOptions() {
      return _.map(this.competitionClasses.competitionClass.categories, function (it) {
        return {id: it.id, name: CompetitionClassCategoryTranslator.translate(it.name)}
      })
    },
    refereeOptions() {
      let tmp = _.map(this.competitionClasses.competitionClass.functionaries.REFEREE, function (it) {
        return {id: it.id, name: it.familyName + ', ' + it.givenName}
      })
      tmp.unshift({id: "null", name: 'Ej angiven'})
      return tmp
    },
    competitionOfficerOptions() {
      let tmp = _.map(this.competitionClasses.competitionClass.functionaries.COMPETITION_OFFICER, function (it) {
        return {id: it.id, name: it.familyName + ', ' + it.givenName}
      })
      tmp.unshift({id: "null", name: 'Ej angiven'})
      return tmp
    },
    figOptions() {
      let tmp = _.map(this.competitionClasses.competitionClass.functionaries.FIG, function (it) {
        return {id: it.id, name: it.familyName + ', ' + it.givenName}
      })
      tmp.unshift({id: "null", name: 'Ej angiven'})
      return tmp
    },
    maxStarts: {
      get() {
        return this.competitionClass.maxStarts
      },
      set(val) {
        this.competitionClass.maxStarts = val
      }
    },
    secretaryOptions() {
      let tmp = _.map(this.competitionClasses.competitionClass.functionaries.SECRETARY, function (it) {
        return {id: it.id, name: it.familyName + ', ' + it.givenName}
      })
      tmp.unshift({id: "null", name: 'Ej angiven'})
      return tmp
    },
    priceInSek: {
      get() {
        return this.competitionClass.price / 100
      },
      set(val) {
        this.competitionClass.price = val * 100
      }
    },
    ...mapState(['competitionClasses'])
  },
}
</script>
