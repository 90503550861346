<template>
  {{ label }}
</template>

<script>
export default {
  name: "ResultPartLabel",
  data() {
    return {
      labels: {
        BR_1_LINFORIGHET: 'Linförighet',
        BR_1_FRAMFORGAENDE: 'Framförgående',
        BR_1_PLATSLAGGANDE: 'Platsläggande',
        BR_1_INKALLANDE: 'Inkallande',
        BR_1_APPORTERING: 'Apportering',
        BR_1_HOPP_OVER_HINDER: 'Hopp över hinder',
        BR_1_PLATSLIGGANDE: 'Platsliggande m skott',
        BR_1_SPECIAL: 'Huvudmoment',
        BR_1_SPECIAL_SPAR: 'Spårning',
        BR_1_BUDFORING: 'Budföring',
        BR_2_LINFORIGHET: 'Linförighet',
        BR_2_FRAMFORGAENDE: 'Framförgående',
        BR_2_LAGGANDE: 'Platsläggande',
        BR_2_INKALLANDE: 'Inkallande',
        BR_2_KRYPANDE: 'Krypande',
        BR_2_APPORTERING: 'Apportering',
        BR_2_HOPP: 'Hopp över hinder',
        BR_2_PLATSLIGGANDE: 'Platsliggande',
        BR_2_SPECIAL_RAPPORT: 'Rapportföring',
        BR_2_SPECIAL_SOK: 'Genomsök',
        BR_2_SPECIAL_SPAR: 'Spårning',
        BR_2_SPECIAL: 'Huvudmoment',
        BR_2_PASLAPP: 'Påsläpp på spår',
        BR_2_BUDFORING: 'Budföring',
        BR_2_SPARNING: 'Spårning',
        BR_2_TRANSPORT: 'Transport',
        BR_2_OVERFALL: 'Överfall',
        BR_2_FASTTAGANDE: 'Fasttagande',
        BR_2_BEVAKNING: 'Bevakning',
        BR_3_FRITT_FOLJ: 'Fritt följ',
        BR_3_INKALLANDE: 'Ink med stående',
        BR_3_FRAMATSANDANDE: 'Framåtsändande',
        BR_3_KRYPANDE: 'Krypande',
        BR_3_SKALL: 'Skall',
        BR_3_APPORTERING: 'Tungt föremål',
        BR_3_HOPP: 'Hopp över hinder',
        BR_3_PLATSLIGGANDE: 'Platsliggande m skott',
        BR_3_SPECIAL: 'Huvudmoment',
        BR_3_SPECIAL_RAPPORT: 'Rapportföring',
        BR_3_SPECIAL_SOK: 'Genomsök',
        BR_3_SPECIAL_SPAR: 'Spårning',
        BR_3_UPPTAG: 'Uppl av spår',
        BR_3_UPPLETANDE: 'Uppl av föremål',
        BR_3_SPARNING: 'Spårning',
        BR_3_TRANSPORT_I: 'Transport I',
        BR_3_OVERFALL_I: 'Överfall I',
        BR_3_FASTTAGANDE: 'Fasttagande',
        BR_3_DUBBELRETNING: 'Dubbelretning',
        BR_3_TRANSPORT_II: 'Transport II',
        BR_3_OVERFALL_II: 'Överfall II',
        BR_3_MODPROV: 'Modprov',
        BR_3_SLAPPANDE: 'Släppande',
        BR_4_FRITT_FOLJ: 'Fritt följ',
        BR_4_INKALLANDE: 'Ink m stå och lägg',
        BR_4_FRAMATSANDANDE: 'Framåtsändande',
        BR_4_KRYPANDE: 'Krypande',
        BR_4_SKALL: 'Skall',
        BR_4_APPORTERING: 'Metallföremål',
        BR_4_TUNG_APPORTERING: 'Tungt föremål',
        BR_4_HOPP: 'Hopp över hinder',
        BR_4_STEGKLATTRING: 'Stegklättring',
        BR_4_PLATSLIGGANDE: 'Platsligg. m skott',
        BR_4_SPECIAL: 'Huvudmoment',
        BR_4_SPECIAL_RAPPORT: 'Rapportföring',
        BR_4_SPECIAL_SOK: 'Genomsök',
        BR_4_special_SPAR: 'Spårning',
        BR_4_UPPTAG: 'Uppl av spår',
        BR_4_UPPLETANDE: 'Uppl. av föremål',
        BR_4_SPARNING: 'Spårning',
        BR_4_AVSOKANDE: 'Avsökande',
        BR_4_STANDSKALL_I: 'Ståndskall I',
        BR_4_STANDSKALL_II: 'Ståndskall II',
        BR_4_SANDANDE: 'Sändande',
        BR_4_TRANSPORT_I: 'Transport I',
        BR_4_OVERFALL_I: 'Överfall I',
        BR_4_FASTTAGANDE: 'Fasttagande',
        BR_4_BEVAKNING_I: 'Bevakning I',
        BR_4_DUBBELRETNING: 'Dubbelretning',
        BR_4_TRANSPORT_II: 'Transport II',
        BR_4_OVERFALL_II: 'Överfall II',
        BR_4_MODPROV: 'Modprov',
        BR_4_SLAPPANDE: 'Släppande',
        BR_4_BEVAKNING_II: 'Bevakning II',
        BR_4_FLYKT: 'Flykt med skott',
        IP_GRADE_A: 'Avd-A',
        IP_GRADE_B: 'Avd-B',
        IP_GRADE_C: 'Avd-C',
        MR_MR1_FRITT_FOLJ: 'Fritt följ',
        MR_MR1_FRANVARO: 'Frånvaro',
        MR_MR1_MATVAGRAN: 'Matvägran',
        MR_MR1_FRAMATSANDANDE: 'Framåtsändande',
        MR_MR1_APPORTERING: 'Apportering',
        MR_MR1_FJARR: 'Fjärr',
        MR_MR1_HOPP: 'Hopp',
        MR_MR1_FRONTALANGREPP_START: 'Frontalangrepp start',
        MR_MR1_FRONTALANGREPP_ANGREPP: 'Frontalangrepp angrepp',
        MR_MR1_FRONTALANGREPP_INKALLNING: 'Frontalangrepp inkallning',
        MR_MR1_FASTTAGANDE_START: 'Fasttagande start',
        MR_MR1_FASTTAGANDE_ANGREPP: 'Fasttagande angrepp',
        MR_MR1_FASTTAGANDE_INKALLNING: 'Fasttagande inkallning',
        MR_MR1_FORARFORSVAR: 'Förarförsvar',
        MR_MR2_FRITT_FOLJ: 'Fritt följ',
        MR_MR2_FRANVARO: 'Frånvaro',
        MR_MR2_MATVAGRAN: 'Matvägran',
        MR_MR2_FRAMATSANDANDE: 'Framåtsändande',
        MR_MR2_APPORTERING: 'Apportering',
        MR_MR2_FJARR: 'Fjärr',
        MR_MR2_VITTRING: 'Vittring',
        MR_MR2_HOPP: 'Hopp',
        MR_MR2_ALT_HOPP: 'Alternativt hopp',
        MR_MR2_FRONTALANGREPP_START: 'Frontalangrepp start',
        MR_MR2_FRONTALANGREPP_ANGREPP: 'Frontalangrepp angrepp',
        MR_MR2_FRONTALANGREPP_INKALLNING: 'Frontalangrepp inkallning',
        MR_MR2_FASTTAGANDE_START: 'Fasttagande start',
        MR_MR2_FASTTAGANDE_ANGREPP: 'Fasttagande angrepp',
        MR_MR2_FASTTAGANDE_INKALLNING: 'Fasttagande inkallning',
        MR_MR2_SOK: 'Sök',
        MR_MR2_TRANSPORT: 'Transport',
        MR_MR2_FRONTALANGREPP2_START: 'Frontalangrepp 2 start',
        MR_MR2_FRONTALANGREPP2_ANGREPP: 'Frontalangrepp 2 angrepp',
        MR_MR2_FRONTALANGREPP2_INKALLNING: 'Frontalangrepp 2 inkallning',
        MR_MR2_FORARFORSVAR: 'Förarförsvar',
        MR_MR3_FRITT_FOLJ: 'Fritt följ',
        MR_MR3_FRANVARO: 'Frånvaro',
        MR_MR3_MATVAGRAN: 'Matvägran',
        MR_MR3_FRAMATSANDANDE: 'Framåtsändande',
        MR_MR3_APPORTERING: 'Apportering',
        MR_MR3_FJARR: 'Fjärr',
        MR_MR3_HOPP: 'Hopp',
        MR_MR3_PLANK_HOPP: 'Plankhopp',
        MR_MR3_LANG_HOPP: 'Långhopp',
        MR_MR3_VITTRING: 'Vittring',
        MR_MR3_FRONTALANGREPP_START: 'Frontalangrepp start',
        MR_MR3_FRONTALANGREPP_ANGREPP: 'Frontalangrepp angrepp',
        MR_MR3_FRONTALANGREPP_INKALLNING: 'Frontalangrepp inkallning',
        MR_MR3_FASTTAGANDE_START: 'Fasttagande start',
        MR_MR3_FASTTAGANDE_ANGREPP: 'Fasttagande angrepp',
        MR_MR3_FASTTAGANDE_INKALLNING: 'Fasttagande inkallning',
        MR_MR3_SOK: 'Sök',
        MR_MR3_TRANSPORT: 'Transport',
        MR_MR3_FRONTALANGREPP2_START: 'Frontalangrepp 2 start',
        MR_MR3_FRONTALANGREPP2_ANGREPP: 'Frontalangrepp 2 angrepp',
        MR_MR3_FRONTALANGREPP2_INKALLNING: 'Frontalangrepp 2 inkallning',
        MR_MR3_FASTTAGANDE2_START: 'Fasttagande 2 start',
        MR_MR3_FASTTAGANDE2_INKALLNING: 'Fasttagande 2 inkallning',
        MR_MR3_FORARFORSVAR: 'Förarförsvar',
        MR_MR3_BEVAKNING_FOREMAL: 'Bevakning föremål',
        MR_ST_FRITT_FOLJ: 'Fritt följ',
        MR_ST_INKALLANDE: 'Inkallande',
        MR_ST_APPORTERING: 'Apportering',
        MR_ST_FRANVARO: 'Frånvaro',
        MR_ST_PERSONER: 'Personer',
        MR_ST_MOTE_CYKLIST: 'Möte cyklist',
        MR_ST_MOTE_BIL: 'Möte bil',
        MR_ST_MOTE_JOGGARE: 'Möte joggare',
        MR_ST_MOTE_HUND: 'Möte hund',
        MR_ST_UPPBINDNING: 'Uppbindning',
        NS_PLATSLIGGNING1: 'Platsliggning, dag 1',
        NS_PLATSLIGGNING2: 'Platsliggning, dag 2',
        NS_INKALLNING1: 'Inkallning, dag 1',
        NS_INKALLNING2: 'Inkallning, dag 2',
        NS_FRITT_FOLJ1: 'Fritt följ, dag 1',
        NS_FRITT_FOLJ2: 'Fritt följ, dag 2',
        NS_STALLANDE1: 'Ställande, dag 1',
        NS_STALLANDE2: 'Ställande, dag 2',
        NS_LAGGANDE1: 'Läggande, dag 1',
        NS_LAGGANDE2: 'Läggande, dag 2',
        NS_TUNG_APPORT1: 'Tung apport, dag 1',
        NS_TUNG_APPORT2: 'Tung apport, dag 2',
        NS_UPPLETANDE1: 'Uppletande, dag 1',
        NS_UPPLETANDE2: 'Uppletande, dag 2',
        NS_RAPPORT_DAG1: 'Rapport, dag 1',
        NS_RAPPORT_DAG2: 'Rapport, dag 2',
        NS_AVSOK: 'Avsök',
        NS_SKALL1: 'Skall, dag 1',
        NS_SKALL2: 'Skall, dag 2',
        NS_TRANSPORT: 'Transport',
        NS_OVERFALL: 'Överfall',
        NS_SANDANDE: 'Sändande',
        NS_MODPROV: 'Modprov',
        NS_SLAPPANDE: 'Släppande',
        NS_BEVAKNING: 'Bevakning',
        NS_FLYKT: 'Flykt',
        NS_SPAR: 'Spår',
        NS_GENOMSOK_DAG1: 'Genomsök, dag 1',
        NS_GENOMSOK_DAG2: 'Genomsök, dag 2',
        NS_SPAR_DAG1: 'Spår, dag 1',
        NS_SPAR_DAG2: 'Spår, dag 2',
        NS_SPARUPPTAG_DAG1: 'Spårupptag, dag 1',
        NS_SPARUPPTAG_DAG2: 'Spårupptag, dag 2',
        LY_1_FOLJSAMHET: 'Följsamhet',
        LY_1_INKALLANDE: 'Inkallande',
        LY_1_SITT_UNDER_MARSCH: 'Sitt under marsch',
        LY_1_APPORTERING: 'Apportering',
        LY_1_FJARRDIRIGERING: 'Fjärrdirigering',
        LY_1_HOPP: 'Hopp',
        LY_1_HELHET: 'Helhet',
        LY_2_SITTANDE_I_GRUPP: 'Sittande i grupp',
        LY_2_FRITT_FOLJ: 'Fritt följ',
        LY_2_STALLANDE: 'Ställande',
        LY_2_INKALLANDE: 'Inkallande',
        LY_2_SATTANDE: 'Sättande',
        LY_2_SANDANDE: 'Sändande',
        LY_2_APPORTERING: 'Approtering',
        LY_2_FJARRDIRIGERING: 'Fjärrdirigering',
        LY_2_INKALLANDE_MED_HOPP: 'Inkallande med hopp',
        LY_2_SANDANDE_KON: 'Sändande till kon',
        LY_2_HELHET: 'Helhet',
        LY_3_PLATSLIGGANDE: 'Platsliggande',
        LY_3_FRITT_FOLJ: 'Fritt följ',
        LY_3_STALLANDE: 'Ställande',
        LY_3_INKALLANDE: 'Inkallande',
        LY_3_SANDANDE: 'Sändande',
        LY_3_APPORTERING: 'Apportering',
        LY_3_VITTRINGSPROV: 'Vittringsprov',
        LY_3_FJARRDIRIGERING: 'Fjärrdirigering',
        LY_3_HOPP: 'Hopp',
        LY_3_HELHET: 'Helhet',
        LY_4_SITTANDE_I_GRUPP: 'Sittande i grupp',
        LY_4_PLATSLIGGANDE: 'Platsliggande',
        LY_4_FRITT_FOLJ: 'Fritt följ',
        LY_4_FYRKANTEN: 'Fyrkanten',
        LY_4_INKALLANDE: 'Inkallande',
        LY_4_RUTAN: 'Rutan',
        LY_4_APPORTERING_MED_DIRIGERING: 'Apportering med dirigering',
        LY_4_SANDANDE_KON: 'Sändande till kon',
        LY_4_VITTRINGSPROV: 'Vittringsprov',
        LY_4_FJARRDIRIGERING: 'Fjärrdirigering',
        ME_MH_1A: '1a. KONTAKT Hälsning',
        ME_MH_1B: '1b. KONTAKT Samarbete',
        ME_MH_1C: '1c. KONTAKT Hantering',
        ME_MH_2A: '2a. LEK 1 Leklust',
        ME_MH_2B: '2b. LEK 1 Gripande',
        ME_MH_2C: '2c. LEK 1 Gripande och dragkamp',
        ME_MH_3A1: '3a1. FÖRFÖLJANDE',
        ME_MH_3A2: '3a2. FÖRFÖLJANDE',
        ME_MH_3B1: '3b1. GRIPANDE',
        ME_MH_3B2: '3b2. GRIPANDE',
        ME_MH_4: '4. AKTIVITET',
        ME_MH_5A: '5a. AVSTÅNDSLEK Intresse',
        ME_MH_5B: '5b. AVSTÅNDSLEK Hot/agg',
        ME_MH_5C: '5c. AVSTÅNDSLEK Nyfikenhet',
        ME_MH_5D: '5d. AVSTÅNDSLEK Leklust',
        ME_MH_5E: '5e. AVSTÅNDSLEK Samarbete',
        ME_MH_6A: '6a. ÖVERRASKNING Rädsla',
        ME_MH_6B: '6b. ÖVERRASKNING Hot/agg',
        ME_MH_6C: '6c. ÖVERRASKNING Nyfikenhet',
        ME_MH_6D: '6d. ÖVERRASKNING Kvarstående rädsla',
        ME_MH_6E: '6e. ÖVERRASKNING Kvarstående intresse',
        ME_MH_7A: '7a. LJUDKÄNSLIGHET Rädsla',
        ME_MH_7B: '7b. LJUDKÄNSLIGHET Nyfikenhet',
        ME_MH_7C: '7c. LJUDKÄNSLIGHET Kvarstående rädsla',
        ME_MH_7D: '7d. LJUDKÄNSLIGHET Kvarstående intresse',
        ME_MH_8A: '8a. SPÖKEN Hot/agg',
        ME_MH_8B: '8b. SPÖKEN Kontroll',
        ME_MH_8C: '8c. SPÖKEN Rädsla',
        ME_MH_8D: '8d. SPÖKEN Nyfikenhet',
        ME_MH_8E: '8e. SPÖKEN Kontakttagande',
        ME_MH_9A: '9a. LEK 2 Leklust',
        ME_MH_9B: '9b. LEK 2 Gripande',
        ME_MH_10: '10. SKOTT',
        ME_MT_SAMARBETE_FOR: 'Samarbete/Lek Föremål/Förare',
        ME_MT_SAMARBETE_TL: 'Samarbete/Lek Föremål/TL',
        ME_MT_TA_5: 'Gripa; Ta tag 5 m',
        ME_MT_TA_40: 'Gripa; Ta tag 40 m',
        ME_MT_HALLA_5: 'Gripa; Hålla i 5 m',
        ME_MT_HALLA_40: 'Gripa; Hålla i 40 m',
        ME_MT_SLITA_5: 'Gripa; Slita/dra 5 m',
        ME_MT_SLITA_40: 'Gripa; Slita/dra 40 m',
        ME_MT_FORFOLJANDE: 'Förföljande',
        ME_MT_GRIPANDE: 'Förföljande; Gripande',
        ME_MT_UTHALLIGHET: 'Uthållighet',
        ME_MT_SJALVSAKERHET: 'Social självsäkerhet',
        ME_MT_SOC_NYFIKENHET: 'Social nyfikenhet',
        ME_MT_SAMSPEL: 'Socialt samspel',
        ME_MT_HANDLING: 'Handlingsförmåga',
        ME_MT_ANPASSNING: 'Anpassningsförmåga',
        ME_MT_KONCENTRATION: 'Koncentration',
        ME_MT_AVREAKTION: 'Avreaktion',
        ME_MT_MINNESBILDER: 'Minnesbilder',
        ME_MT_RADSLA: 'Rädsla',
        ME_MT_AGGRESSIVITET: 'Aggressivitet',
        ME_MT_NYFIKENHET: 'Nyfikenhet',
        ME_MT_SKOTT: 'Skott',
        ME_MT_HOT: 'Imponer-/hotbeteenden',
        SCORE: 'Betyg'
      }
    }
  },
  props: {
    resultPart: {
      type: String,
      required: true
    }
  },
  computed: {
    label() {
      return this.labels[this.resultPart] || this.resultPart
    }
  }
}
</script>
