<template>
  <tr>
    <td class="has-text-left">
      <result-part-label :result-part="it.id"/>
    </td>
    <td>
      {{ it.coefficient }}
    </td>
    <template v-for="igrade in ngrades" :key="igrade">
      <td class="has-text-weight-semibold">{{ it.refereeGrades[igrade] }}</td>
      <td>{{ it.refereeGrades[igrade] * it.coefficient }}</td>
    </template>
    <template v-if="ngrades > 1">
      <td>
        {{ it.grade }}
      </td>
      <td class="has-text-weight-semibold">
        {{ it.grade * it.coefficient }}
      </td>
    </template>
  </tr>
</template>

<script>
import ResultPartLabel from "@/components/labels/ResultPartLabel"

export default {
  name: "PartGradeTableRow",
  components: {
    ResultPartLabel
  },
  props: {
    it: {
      type: Object,
      required: true
    },
    ngrades: {
      type: Number,
      required: true
    }
  }
}
</script>
