<template>
  <div class="notification is-primary">
    {{ notification.message }}
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timeout: null
    }
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), 5000)
  },
  beforeUnmount() {
    clearTimeout(this.timeout)
  },
  methods: mapActions('notifications', ['remove'])
}
</script>
