<template>
  <div class="container is-fluid" id="main-container">
    <navigation/>
    <div id="main-content">
      <router-view :key="$route.fullPath"/>
    </div>
  </div>
  <NotificationContainer/>
</template>

<script>
import Navigation from '@/components/Navigation'
import NotificationContainer from "@/components/NotificationContainer"
import {mapActions} from "vuex";

export default {
  components: {
    Navigation,
    NotificationContainer
  },
  created() {
    const userString = localStorage.getItem('user')
    if (userString) {
      this.setUser(JSON.parse(userString))
    }
  },
  methods: {
    ...mapActions('user', ['setUser'])
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#main-container {
  padding: 0;
  margin: 0;
}

.nav-top {
  background: #f8f6f1;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 3px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) 0 3px 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 3px;
  z-index: 10000;
}

#navbar-brand {
  margin-left: 32px;
}

#main-content {
  margin: 10px 32px;
}

.logo {
  padding-right: 8px;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.2em;
  text-decoration: underline;
}

a {
  color: #0f5252;
}

#nav a {
  font-weight: bold;
  color: #000000;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.id-width {
  width: 100px;
}

.icon-width {
  width: 25px;
}

.card-header {
  padding: 0 1.1rem;
}

.field-label {
  flex-grow: 2;
}

.referee-grade {
  width: 3em;
}

.draggable {
  cursor: move;
}

.sortable-ghost, .sortable-ghost td {
  background-color: #6293e9;
  opacity: 1;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(even).warning,
.warning {
  background-color: #ffe8e4;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(even).disabled,
.disabled {
  background-color: #efe3c3;
}
</style>
