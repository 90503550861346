<template>
  <table class="table is-striped is-fullwidth">
    <thead>
    <tr>
      <th class="id-width">ID</th>
      <th class="has-text-left">Användarnamn</th>
      <th class="has-text-left">E-post</th>
      <th class="has-text-left">Förare</th>
      <th class="has-text-left">Hundar</th>
      <th class="icon-width"></th>
      <th class="icon-width"></th>
    </tr>
    </thead>
    <tbody>
    <AccountSummary v-for="it in accounts.list" :key="it.id" :account="it"/>
    </tbody>
  </table>
  <Pagination link-name="Accounts" :per-page="perPage" :page="page" :n-total="nAccounts"/>
</template>

<script>
import AccountSummary from "@/components/accounts/AccountSummary"
import Pagination from "@/components/Pagination"
import {mapState, mapActions} from "vuex"

export default {
  name: "Accounts",
  components: {
    Pagination,
    AccountSummary
  },
  created() {
    this.perPage = 10
    this.getList({
      perPage: this.perPage,
      page: this.page,
      q: this.$route.query.q
    })
  },
  methods: mapActions('accounts', ['getList']),
  computed: {
    nAccounts() {
      return this.$store.state.accounts.nTotal
    },
    page() {
      return parseInt(this.$route.query.page) || 1
    },
    hasNextPage() {
      return this.$store.state.accounts.nTotal > this.page * this.perPage
    },
    ...mapState(['accounts'])
  },
}
</script>
