<template>
  <div v-if="accounts.account">
    <div class="columns">
      <div class="column">
        <div class="card">
          <header class="card-header">
            <h2 class="card-header-title">
              {{ account.name }}, #{{ account.id }}
            </h2>
          </header>
          <div class="card-content">
            <div class="content">
              <table>
                <tbody>
                <tr>
                  <td class="has-text-left">Klubb:</td>
                  <td class="has-text-left">{{ account.organizer.name }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Roller:</td>
                  <td class="has-text-left">
                    <role v-for="(role, index) in account.roles" :key="role" :role="role"
                          :last-entry="index === account.roles.length -1"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Förare:</td>
                  <td class="has-text-left">
                    <handler-link
                        v-for="(handler, index) in account.handlers"
                        :key="handler.id"
                        :handler="handler"
                        :last-entry="index === account.handlers.length - 1"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Hundar:</td>
                  <td class="has-text-left">
                    <dog-link v-for="(dog, index) in account.dogs"
                              :key="dog.id"
                              :dog="dog"
                              :line-break="true"
                              :last-entry="index === account.dogs.length - 1"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Skapad:</td>
                  <td class="has-text-left">{{ timestamp(account.createdAt) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button @click="$router.go(-1)" class="button is-link">Tillbaka</button>
    <debug
        v-if="env.debug"
        :aggregate="accounts.account"
        :extra="[]"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import {mapActions, mapState} from "vuex"
import {FormattingFilters} from "@/mixins/FormattingFilters";
import Debug from '@/components/shared/Debug'
import DogLink from "@/components/dogs/DogLink";
import HandlerLink from '@/components/handlers/HandlerLink'
import Role from "@/components/accounts/Role"

export default {
  name: "Account",
  props: ['id'],
  components: {
    Debug,
    DogLink,
    HandlerLink,
    Role
  },
  mixins: [FormattingFilters],
  methods: mapActions('accounts', ['get']),
  created() {
    this.get(this.id)
  },
  computed: {
    account() {
      return this.accounts.account.aggregate
    },
    ...mapState(['accounts'])
  },
}
</script>
