<template>
  <input type="file" id="file" ref="file" v-on:change="handleFileUpload" style=""/>
  <button class="button" v-on:click="submitFile()">Ladda upp</button>
</template>

<script>
import axios from "axios";

export default {
  props: {
    aggregateId: {
      type: String,
      required: true
    },
    aggregateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String],
      default: ''
    },
  },
  data() {
    return {
      file: ''
    }
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0]
    },
    submitFile() {
      let formData = new FormData()
      formData.append(this.file.name, this.file)
      formData.append('assetType', this.assetType)
      formData.append('aggregateId', this.aggregateId)
      axios
          .post(process.env.VUE_APP_API_BASE_URL + '/upload-competition-asset',
              formData,
              {
                headers: {
                  'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
                  'Content-Type': 'multipart/form-data'
                }
              }
          )
          .then(function () {
            console.log('SUCCESS!!')
          })
          .catch(function () {
            console.log('FAILURE!!')
          });
    }
  }
}
</script>
