<template>
  <tr>
    <td class="has-text-left">#{{ it.id }}</td>
    <td class="has-text-left">
      <router-link :to="{ name: 'CompetitionClass', params: { id: it.id } }">
        {{ it.label }}
      </router-link>
    </td>
    <td class="has-text-left">{{ it.nstarts }}</td>
    <td class="has-text-left">{{ it.nonline }}</td>
    <td class="has-text-left">{{ it.ndirect }}</td>
    <td class="has-text-left"><price-label :amount-in-cents="it.amount"/></td>
    <td class="has-text-left"><price-label :amount-in-cents="it.sbkFee"/></td>
    <td class="has-text-left"><price-label :amount-in-cents="it.amountOnline"/></td>
    <td class="has-text-left"><price-label :amount-in-cents="it.amountDirect"/></td>
    <td class="has-text-left"><price-label :amount-in-cents="it.amountNet"/></td>
  </tr>
</template>

<script>
import {FormattingFilters} from "@/mixins/FormattingFilters";
import PriceLabel from "@/components/labels/PriceLabel"

export default {
  name: "ClassFinancialsTableRow",
  components: {
    PriceLabel
  },
  props: {
    it: {
      type: Object,
      required: true
    }
  },
  mixins: [FormattingFilters],
  computed: {
    maxRefereeIndex() {
      let maxIndex = 0
      if (this.it.referees['1']) maxIndex = 1
      if (this.it.referees['2']) maxIndex = 2
      if (this.it.referees['3']) maxIndex = 3
      if (this.it.referees['4']) maxIndex = 4
      if (this.it.referees['5']) maxIndex = 5
      if (this.it.referees['6']) maxIndex = 6
      if (this.it.referees['7']) maxIndex = 7
      if (this.it.referees['8']) maxIndex = 8
      return maxIndex
    }
  },
  methods: {
    resultList(id) {
      window.open(process.env.VUE_APP_API_BASE_URL + '/result-lists/' + id, "_blank")
    },
    startList(id) {
      window.open(process.env.VUE_APP_API_BASE_URL + '/start-lists/' + id, "_blank")
    }
  }
}
</script>
