<template>
  <router-link :to="{name: 'ResultList', params: {id: competitionClass.id}}">
    {{ competitionClass.type.name }}
  </router-link><template v-if="!lastEntry">, </template>
</template>

<script>
export default {
  name: "ResultListLink",
  props: {
    competitionClass: {
      type: Object,
      required: true
    },
    lastEntry: {
      type: Boolean,
      default: true
    }
  }
}
</script>
