<template>
  <tr>
    <td>
      <router-link :to="{name: 'Competition', params: {id: competition.id}}">
        {{ competition.id }}
      </router-link>
    </td>
    <td class="has-text-left">
      <router-link :to="{name: 'Competition', params: {id: competition.id}}">
        {{ competition.name }}
      </router-link>
    </td>
    <td>{{ competition.startDate }}</td>
    <td>{{ competition.registrationDeadline }}</td>
    <td class="has-text-left">{{ competition.organizer.name }}</td>
    <td>
      <CompetitionState :state="competition.state"/>
    </td>
    <td>
      <router-link :to="{name: 'Competition', params: {id: competition.id}}">
        <i class="fas fa-eye"></i>
      </router-link>
    </td>
    <td>
      <router-link :to="{name: 'CompetitionForm', params: {id: competition.id}}">
        <i class="far fa-edit"></i>
      </router-link>
    </td>
  </tr>
</template>

<script>
import CompetitionState from "@/components/competitions/CompetitionState"

export default {
  name: "CompetitionSummary",
  components: {CompetitionState},
  props: {
    competition: Object
  }
}
</script>

<style scoped></style>
