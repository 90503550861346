<template>
  <table class="table is-fullwidth is-bordered">
    <tbody>
    <tr
        v-for="partGrade in result.partGrades"
        :key="partGrade.id"
    >
      <th>
        <result-part-label :result-part="partGrade.id"/>
      </th>
      <td
          v-for="(label, index) in scoreLabels[partGrade.id].slice(1)"
          :key="index"
          :class="{selected: partGrade.grade === index + 1}"
      >
        {{label}}
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import MentalTranslator from "@/i18n/MentalTranslator"
import ResultPartLabel from "@/components/labels/ResultPartLabel"

export default {
  name: "MentalResultDetails",
  components: {
    ResultPartLabel
  },
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  computed: {
    scoreLabels() {
      return MentalTranslator.scoreLabels
    }
  }
}
</script>

<style scoped>
.selected {
  background-color: #fcdeab;
}
</style>
