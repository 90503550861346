<template>
  <div class="buttons">
    <router-link
        v-if="showOperation('EDIT')"
        :to="{ name: 'CompetitionClassForm', id: competitionClass.id }"
        class="router-link"
    >
      <button class="button is-fullwidth is-link mb-3">
        <span class="icon">
          <i class="fa fa-edit"></i>
        </span>
        <span>Redigera</span>
      </button>
    </router-link>
    <button v-if="showStartList" class="button is-fullwidth is-link mb-3" @click="startList">
        <span class="icon">
          <i class="fa fa-clipboard-list"></i>
        </span>
      <span>Startlista som PDF</span>
    </button>
    <router-link
        v-if="hasResults"
        :to="{ name: 'ResultList', params: { id: competitionClass.id } }"
        class="router-link"
    >
      <button class="button is-fullwidth is-link mb-3">
        <span class="icon">
          <i class="fa fa-clipboard-list"></i>
        </span>
        <span>Resultatlista</span>
      </button>
    </router-link>
    <button v-if="hasResults" class="button is-fullwidth is-link mb-3" @click="resultList">
        <span class="icon">
          <i class="fa fa-clipboard-list"></i>
        </span>
      <span>Resultatlista som PDF</span>
    </button>
    <button
        v-if="canVerify"
        class="button is-fullwidth is-link mb-3"
        @click="changeState('VERIFIED')"
    >
      <span class="icon">
        <i class="fa fa-edit"></i>
      </span>
      <span>Verifiera klassen</span>
    </button>
    <button
        v-if="canUnverify"
        class="button is-fullwidth is-link mb-3"
        @click="changeState('ENDED')"
    >
      <span class="icon">
        <i class="fa fa-edit"></i>
      </span>
      <span>Ta tillbaka verifiering</span>
    </button>
    <button v-if="showOperation('SHUFFLE')" class="button is-fullwidth is-link mb-3" @click="shuffle">
      <span class="icon">
        <i class="fa fa-list-ol"></i>
      </span>
      <span>Lotta klassen</span>
    </button>
  </div>
</template>

<script>
import _ from 'lodash'
import CompetitionClassService from "@/services/CompetitionClassService";

0
export default {
  name: "CompetitionClassOperations",
  props: {
    competitionClass: {
      type: Object,
      required: true
    },
    operations: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    canVerify() {
      return this.competitionClass.state === 'ENDED' &&
          this.competitionClass.competition.state === 'ENDED'
    },
    canUnverify() {
      return this.competitionClass.state === 'VERIFIED' &&
          this.competitionClass.competition.state === 'ENDED'
    },
    showStartList() {
      return this.competitionClass.state !== 'INITIALIZED'
    },
    hasResults() {
      return this.competitionClass.state === 'ENDED' || this.competitionClass.state === 'VERIFIED'
    }
  },
  methods: {
    changeState(state) {
      CompetitionClassService.changeState(this.competitionClass, state)
          .then(() => this.$router.go())
    },
    showOperation(op) {
      return _.includes(this.operations, op)
    },
    resultList() {
      window.open(process.env.VUE_APP_API_BASE_URL + '/result-lists/' + this.competitionClass.id, "_blank")
    },
    shuffle() {
      CompetitionClassService.shuffle(this.competitionClass)
          .then(() => this.$router.go())
    },
    startList() {
      window.open(process.env.VUE_APP_API_BASE_URL + '/start-lists/' + this.competitionClass.id, "_blank")
    }
  }
}
</script>

<style scoped>
.router-link {
  display: inline-block;
  width: 100%;
}
</style>
