<template>
  <router-link :to="'/dogs/' + dog.id">
    {{ dog.registeredName }}
  </router-link><template v-if="dog.owner">
      (delad från kontot <account-link :account="dog.owner"/>)
    </template>
  <template v-if="!lastEntry">, <template v-if="lineBreak"><br/></template></template>
</template>

<script>
import AccountLink from "@/components/accounts/AccountLink"

export default {
  name: "DogLink",
  components: {
    AccountLink
  },
  props: {
    dog: {
      type: Object,
      required: true
    },
    lineBreak: {
      type: Boolean,
      default: false
    },
    lastEntry: {
      type: Boolean,
      default: true
    }
  }
}
</script>
