export default {
    translate(id) {
        switch (id) {
            case 'ABORTED':
                return 'Ägaren avbryter'
            case 'DISABLED':
                return 'Struken'
            case 'DISQUALIFIED':
                return 'Diskvalificerad'
            case 'FAILED':
                return 'Underkänd'
            case 'INTERRUPTED':
                return 'Avbruten'
            case 'RED_CARD':
                return 'Rött kort'
            case 'YELLOW_CARD':
                return 'Gult kort'
            case 'UNACCEPTABLE':
                return 'Oacceptabelt beteende'
            default:
                return id
        }
    }
}
