<template>
  <table class="table is-striped is-fullwidth">
    <thead>
    <tr>
      <th class="id-width">ID</th>
      <th class="has-text-left">Efternamn</th>
      <th class="has-text-left">Förnamn</th>
      <th class="has-text-left">E-postadress</th>
      <th class="has-text-left">Distrikt</th>
      <th class="has-text-left">Auktorisationer</th>
      <th class="has-text-left">Personnummer</th>
      <th class="icon-width"></th>
      <th class="icon-width"></th>
    </tr>
    </thead>
    <tbody>
    <functionary-summary v-for="it in functionaries.list" :key="it.id" :functionary="it"/>
    </tbody>
  </table>
  <Pagination link-name="Functionaries" :per-page="perPage" :page="page" :n-total="nFunctionaries"/>
</template>

<script>
// @ is an alias to /src
import FunctionarySummary from "@/components/functionaries/FunctionarySummary"
import Pagination from "@/components/Pagination"
import {mapState, mapActions} from "vuex"

export default {
  name: "Functionaries",
  components: {
    Pagination,
    FunctionarySummary
  },
  created() {
    this.perPage = 10
    this.getList({
      perPage: this.perPage,
      page: this.page,
      q: this.$route.query.q
    })
  },
  methods: mapActions('functionaries', ['getList']),
  computed: {
    nFunctionaries() {
      return this.$store.state.functionaries.nTotal
    },
    page() {
      return parseInt(this.$route.query.page) || 1
    },
    hasNextPage() {
      return this.$store.state.functionaries.nTotal > this.page * this.perPage
    },
    ...mapState(['functionaries'])
  },
}
</script>
