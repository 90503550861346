<template>
  <tr>
    <td>
      <router-link :to="{name: 'Organizer', params: {id: organizer.id}}">
        {{ organizer.id }}
      </router-link>
    </td>
    <td class="has-text-left">
      <router-link :to="{name: 'Organizer', params: {id: organizer.id}}">
        {{ organizer.sbkId }}
      </router-link>
    </td>
    <td class="has-text-left">
      <router-link :to="{name: 'Organizer', params: {id: organizer.id}}">
        {{ organizer.name }}
      </router-link>
    </td>
    <td class="has-text-left">
      {{ organizationsLabel }}
    </td>
    <td class="has-text-left">
      <district-link :district="organizer.district" :last-entry="true"/>
    </td>
    <td>
      <router-link :to="{name: 'Organizer', params: {id: organizer.id}}">
        <i class="fas fa-eye"></i>
      </router-link>
    </td>
    <td/>
  </tr>
</template>

<script>
import DistrictLink from '@/components/districts/DistrictLink'

export default {
  name: "OrganizerSummary",
  components: {
    DistrictLink
  },
  props: {
    organizer: {
      type: Object,
      required: true
    }
  },
  computed: {
    organizationsLabel() {
      return this.organizer.organizations.join(', ')
    }
  }
}
</script>

<style scoped></style>
