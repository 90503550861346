<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <form @submit.prevent="doLogIn" class="box">
              <div class="field">
                <label for="" class="label">Användarnamn</label>
                <div class="control has-icons-left">
                  <input
                      v-model="username"
                      class="input"
                      type="text"
                      name="username"
                      value
                      required
                  >
                  <span class="icon is-small is-left">
                  <i class="fa fa-user"></i>
                </span>
                </div>
              </div>
              <div class="field">
                <label for="" class="label">Lösenord</label>
                <div class="control has-icons-left">
                  <input
                      v-model="password"
                      type="password"
                      placeholder="*******"
                      class="input"
                      required
                  >
                  <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
                </div>
              </div>
              <div class="field mt-5">
                <button class="button is-success is-fullwidth">
                  Logga in
                </button>
              </div>
            </form>
            <a style="margin-left: 180px; color: #898989;" href="https://sbktavling.se/users/sign_in?organization=sbk"
               target="_blank">
              Glömt lösenord?
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions} from "vuex"

export default {
  data() {
    return {
      username: null,
      password: null
    }
  },
  methods: {
    doLogIn() {
      this.logIn({username: this.username, password: this.password})
    },
    ...mapActions('user', ['logIn'])
  }
}
</script>
