<template>
  <tr v-if="it.result" :class="{ disabled: isDisabled }">
    <td class="has-text-left">{{ it.result.position }}</td>
    <td class="has-text-left">{{ it.number }}</td>
    <td class="has-text-left">#{{ it.id }}</td>
    <td class="has-text-left">
      <score-label
          :result="it.result"
          :use-score="useScore"
      />
    </td>
    <td class="has-text-left">
      <dog-link :dog="it.dog"/>
    </td>
    <td class="has-text-left">
      <handler-link :handler="it.handler"/>
    </td>
    <td class="has-text-left">
      <organizer-link :organizer="it.organizer"/>
    </td>
    <td>
      <router-link :to="{name: 'Result', params: {id: it.result.id}}">
        <i class="fas fa-eye"></i>
      </router-link>
    </td>
    <td>
      <router-link :to="{name: 'ResultForm', params: {id: it.result.id}}">
        <i class="far fa-edit"></i>
      </router-link>
    </td>
  </tr>
  <tr class="warning" v-if="!it.result">
    <td class="has-text-left">-</td>
    <td class="has-text-left">{{ it.number }}</td>
    <td class="has-text-left">#{{ it.id }}</td>
    <td class="has-text-left">-</td>
    <td class="has-text-left">
      <dog-link :dog="it.dog"/>
    </td>
    <td class="has-text-left">
      <handler-link :handler="it.handler"/>
    </td>
    <td class="has-text-left">
      <organizer-link :organizer="it.organizer"/>
    </td>
    <td></td>
    <td>
      <router-link :to="{name: 'ResultForm', params: {id: 'NEW_' + it.id}}" title="Lägg till ett resultat">
        <i class="fas fa-plus-square"></i>
      </router-link>
    </td>
  </tr>
</template>

<script>
import _ from 'lodash'
import DogLink from "@/components/dogs/DogLink"
import HandlerLink from "@/components/handlers/HandlerLink"
import OrganizerLink from "@/components/organizers/OrganizerLink"
import ScoreLabel from "@/components/labels/ScoreLabel"
import DomainConfig from "@/domain/DomainConfig";

export default {
  name: "ResultTableRow",
  components: {
    DogLink,
    HandlerLink,
    OrganizerLink,
    ScoreLabel
  },
  props: {
    domain: {
      type: String,
      required: true
    },
    it: {
      type: Object,
      required: true
    }
  },
  computed: {
    isDisabled() {
      return _.includes(this.it.result.calls, 'DISABLED')
    },
    useScore() {
      return DomainConfig[this.domain].useScore
    }
  }
}
</script>
