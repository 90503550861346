<template>
  {{ stateLabel }}
</template>

<script>
const labels = {
  "INITIALIZED": "Ej lottad",
  "SHUFFLED": "Lottad",
  "STARTED": "Pågående",
  "ENDED": "Genomförd",
  "VERIFIED": "Verifierad"
}
export default {
  name: "CompetitionClassState",
  props: {
    state: String
  },
  computed: {
    stateLabel() {
      return labels[this.state]
    }
  }
}
</script>

<style scoped></style>
