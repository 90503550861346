import OrganizerService from "@/services/OrganizerService"
import handleApiError from "@/store/handleApiError"

export const namespaced = true

export const state = {
    organizer: null,
    list: [],
    nTotal: 0
}

export const mutations = {
    SET_ORGANIZER(state, organizer) {
        state.organizer = organizer
    },
    SET_ORGANIZERS(state, organizers) {
        state.list = organizers
    },
    SET_N_ORGANIZERS(state, nTotal) {
        state.nTotal = nTotal
    }
}

export const actions = {
    getList({commit, dispatch}, {perPage, page, q}) {
        commit('SET_ORGANIZERS', [])
        OrganizerService.get(perPage, page, q)
            .then(response => {
                commit('SET_N_ORGANIZERS', parseInt(response.headers['x-total-count']))
                commit('SET_ORGANIZERS', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching organizers: ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    },
    get({commit, dispatch}, id) {
        commit('SET_ORGANIZER', null)
        OrganizerService.getById(id)
            .then(response => {
                commit('SET_ORGANIZER', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching organizer ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    }
}
