import ApiClient from '@/services/ApiClient'
import _ from "lodash";

export default {
    getCompetitionClass(id) {
        return ApiClient.get('/competition-classes/' + id)
    },

    getCompetitionClassWithFormData(id) {
        return ApiClient.get('/competition-classes/' + id + '/form-data')
    },

    updateCompetitionClass(competitionClass) {
        const aggregate = competitionClass.aggregate
        const payload = {
            aggregateId: aggregate.id
        }
        if (_.includes(competitionClass.editableFields, 'NAME')) {
            payload['name'] = aggregate.name
        }
        if (_.includes(competitionClass.editableFields, 'TYPE')) {
            payload['typeId'] = aggregate.type.id
            payload['official'] = aggregate.official
            payload['price'] = aggregate.price
            if (parseInt(aggregate.nreferees) >= parseInt(aggregate.ngrades)) {
                payload['nReferees'] = parseInt(aggregate.nreferees)
            } else {
                payload['nReferees'] = parseInt(aggregate.ngrades)
            }
        }
        if (_.includes(competitionClass.editableFields, 'KEEP_REGISTRATION_CLOSED')) {
            payload['keepRegistrationClosed'] = aggregate.keepRegistrationClosed
        }
        if (_.includes(competitionClass.editableFields, 'CATEGORIES')) {
            payload['categoryIds'] = aggregate.categories.map(it => it.id)
        }
        if (_.includes(competitionClass.editableFields, 'FUNCTIONARIES')) {
            _.each(aggregate.referees, (referee, i) => {
                payload[`referee${i}Id`] = referee.id
            })
            if (aggregate.competitionOfficer) {
                payload['competitionOfficerId'] = aggregate.competitionOfficer.id
            }
            _.each(aggregate.figs, (fig, i) => {
                payload[`fig${i}Id`] = fig.id
            })
            if (aggregate.secretary) {
                payload['secretaryId'] = aggregate.secretary.id
            }
        }
        console.log(JSON.stringify(payload, null, 2))
        return ApiClient.post('/update-competition-class', payload)
    },

    updateStartOrder(competitionClass) {
        const startOrder = _.map(competitionClass.starts, (s) => { return s.id })
        const payload = {
            aggregateId: competitionClass.id,
            startOrder: startOrder
        }
        console.log('Start order', payload)
        return ApiClient.post('update-start-order', payload)
    },

    changeState(competitionClass, toState) {
        const payload = {
            aggregateId: competitionClass.id,
            toState: toState
        }
        console.log(JSON.stringify(payload, null, 2))
        return ApiClient.post('/change-class-state', payload)
    },

    shuffle(competitionClass) {
        const payload = {
            aggregateId: competitionClass.id
        }
        console.log(JSON.stringify(payload, null, 2))
        return ApiClient.post('/shuffle-class', payload)
    }
}
