<template>
  <div v-if="dogs.list">
    <table class="table is-striped is-fullwidth">
      <thead>
      <tr>
        <th class="id-width">ID</th>
        <th class="has-text-left">Registrerat namn</th>
        <th class="has-text-left">Tilltalsnamn</th>
        <th class="has-text-left">Registreringsnummer</th>
        <th class="has-text-left">Ras</th>
        <th class="has-text-left">Konto</th>
        <th class="icon-width"></th>
        <th class="icon-width"></th>
      </tr>
      </thead>
      <tbody>
      <DogSummary v-for="it in dogs.list" :key="it.id" :dog="it"/>
      </tbody>
    </table>
    <Pagination link-name="Dogs" :per-page="perPage" :page="page" :n-total="nDogs"/>

    <debug
        v-if="env.debug"
        :aggregate="dogs.list"
    />
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex"
import Debug from "@/components/shared/Debug"
import DogSummary from "@/components/dogs/DogSummary"
import Pagination from "@/components/Pagination"

export default {
  name: "Dogs",
  components: {
    Debug,
    DogSummary,
    Pagination
  },
  created() {
    this.perPage = 10
    this.getList({
      perPage: this.perPage,
      page: this.page,
      q: this.$route.query.q
    })
  },
  methods: mapActions('dogs', ['getList']),
  computed: {
    nDogs() {
      return this.$store.state.dogs.nTotal
    },
    page() {
      return parseInt(this.$route.query.page) || 1
    },
    hasNextPage() {
      return this.$store.state.dogs.nTotal > this.page * this.perPage
    },
    ...mapState(['dogs'])
  },
}
</script>
