<template>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <div v-if="label" class="select is-multiple">
            <select
                multiple
                :size="size"
                v-model="selected"
                @change="updateValue"
            >
              <option
                  v-for="option in adaptedOptions"
                  :value="option.id"
                  :key="option.id"
                  v-text="option.label"
              />
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import _ from 'lodash'

export default {
  model: {
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 5
    },
    optionAdapter: {
      type: Function,
      default: value => ({
        id: value.id,
        label: value.name,
        value,
      }),
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selected: this.modelValue.map(x => this.optionAdapter(x).id)
    }
  },
  computed: {
    adaptedOptions() {
      return this.options.map(x => this.optionAdapter(x));
    },
    multiple() {
      return true;
    },
  },
  methods: {
    updateValue() {
      this.$emit('update:modelValue', this.selected.map(id => this.adaptedOptions.find(x => x.id === id).value))
    },
    selectedOptions() {
      return this.selected.map(id => this.adaptedOptions.find(x => x.id === id).value)
    }
  }
}
</script>
