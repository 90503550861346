<template>
  <tr>
    <td>
      <router-link :to="'/functionaries/' + functionary.id">
        {{ functionary.id }}
      </router-link>
    </td>
    <td class="has-text-left">
      <router-link :to="'/functionaries/' + functionary.id">
        {{ functionary.familyName }}
      </router-link>
    </td>
    <td class="has-text-left">
      <router-link :to="'/functionaries/' + functionary.id">
        {{ functionary.givenName }}
      </router-link>
    </td>
    <td class="has-text-left">
      {{ functionary.email }}
    </td>
    <td class="has-text-left">
      {{ functionary.district.name }}
    </td>
    <td class="has-text-left">
      <functionary-authorization-label v-for="it in functionary.authorizations" :key="it" :authorization="it"/>
    </td>
    <td class="has-text-left">
      {{ functionary.personnummer }}
    </td>
    <td>
      <router-link to="/">
        <i class="fas fa-eye"></i>
      </router-link>
    </td>
    <td/>
  </tr>
</template>

<script>
import FunctionaryAuthorizationLabel from "@/components/labels/FunctionaryAuthorizationLabel"

export default {
  name: "FunctionarySummary",
  components: {FunctionaryAuthorizationLabel},
  props: {
    functionary: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped></style>
