<template>
  <tr>
    <td class="has-text-left">#{{ it.id }}</td>
    <td class="has-text-left">
      <router-link :to="{ name: 'CompetitionClass', params: { id: it.id } }">
        {{ it.name }}
      </router-link>
    </td>
    <td class="has-text-left">{{ it.nstarts }}</td>
    <td class="has-text-left">{{ maxStartsLabel(it.maxStarts) }}</td>
    <td class="has-text-left">
      <boolean-label :predicate="it.official"/>
    </td>
    <td class="has-text-left">
      <price-label :amount-in-cents="it.price"/>
    </td>
    <td class="has-text-left">
      <competition-class-state :state="it.state"/>
    </td>
    <td class="has-text-left">
      <functionary-link v-for="(functionary, index) in it.referees" :key="functionary"
                        :functionary="functionary" :last-entry="parseInt(index) === maxRefereeIndex"/>
    </td>
    <td title="Visa detaljer om klassen">
      <router-link :to="{ name: 'CompetitionClass', params: { id: it.id } }">
        <i class="fas fa-eye"></i>
      </router-link>
    </td>
    <td title="Startlista" v-if="it.state === 'SHUFFLED'">
      <a @click="startList(it.id)">
        <i class="fas fa-clipboard-list"></i>
      </a>
    </td>
    <td title="Resultatlista" v-if="it.state === 'ENDED'">
      <a @click="resultList(it.id)">
        <i class="fas fa-clipboard-list"></i>
      </a>
    </td>
    <td title="Redigera detaljer om klassen">
      <router-link :to="{ name: 'CompetitionClassForm', params: { id: it.id } }">
        <i class="far fa-edit"></i>
      </router-link>
    </td>
  </tr>
</template>

<script>
import {FormattingFilters} from "@/mixins/FormattingFilters";
import BooleanLabel from "@/components/labels/BooleanLabel"
import CompetitionClassState from "@/components/competition-classes/CompetitionClassState"
import FunctionaryLink from "@/components/functionaries/FunctionaryLink"
import PriceLabel from "@/components/labels/PriceLabel"

export default {
  name: "CompetitionClassTableRow",
  components: {
    BooleanLabel,
    CompetitionClassState,
    FunctionaryLink,
    PriceLabel
  },
  props: {
    it: {
      type: Object,
      required: true
    }
  },
  mixins: [FormattingFilters],
  computed: {
    maxRefereeIndex() {
      let maxIndex = 0
      if (this.it.referees['1']) maxIndex = 1
      if (this.it.referees['2']) maxIndex = 2
      if (this.it.referees['3']) maxIndex = 3
      if (this.it.referees['4']) maxIndex = 4
      if (this.it.referees['5']) maxIndex = 5
      if (this.it.referees['6']) maxIndex = 6
      if (this.it.referees['7']) maxIndex = 7
      if (this.it.referees['8']) maxIndex = 8
      return maxIndex
    }
  },
  methods: {
    resultList(id) {
      window.open(process.env.VUE_APP_API_BASE_URL + '/result-lists/' + id, "_blank")
    },
    startList(id) {
      window.open(process.env.VUE_APP_API_BASE_URL + '/start-lists/' + id, "_blank")
    }
  }
}
</script>
