import UserService from "@/services/UserService"
import handleApiError from "@/store/handleApiError"

export const namespaced = true

export const state = {
    user: null
}

export const mutations = {
    SET_USER (state, user) {
        state.user = user
        localStorage.setItem('user', JSON.stringify(user))
    },
    CLEAR_USER (state) {
        state.user = {}
        localStorage.removeItem('user')
    }
}

export const actions = {
    logIn({commit, dispatch}, credentials) {
        commit('CLEAR_USER')
        UserService
            .logIn(credentials)
            .then(response => {
                console.log(response.data)
                commit('SET_USER', {token: response.data.token, username: credentials.username})
                window.location = "/"
            })
            .catch(error => {
                if (error.response.status === 401) {
                    const notification = {
                        type: 'error',
                        message: 'Fel användarnamn eller lösenord.'
                    }
                    dispatch('notifications/add', notification, {root: true})
                } else {
                    handleApiError(error)
                    const notification = {
                        type: 'error',
                        message: 'There was a problem logging in: ' + error.message
                    }
                    dispatch('notifications/add', notification, {root: true})
                }
            })
    },

    logOut({commit}) {
        commit('CLEAR_USER')
        window.location = "/"
    },

    setUser({commit}, user) {
        commit('SET_USER', user)
    }
}
