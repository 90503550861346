<template>
  {{ label }}<template v-if="!lastEntry">, </template>
</template>

<script>
export default {
  name: "Role",
  props: {
    role: {
      type: String,
      required: true
    },
    lastEntry: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    label() {
      switch(this.role) {
        case 'DISTRICT':
          return "Distrikt"
        case 'ORGANIZER':
          return "Arrangör"
        case 'SKK':
          return "SKK"
        case 'STAFF':
          return "SBK"
        default:
          return this.role
      }
    }
  }
}
</script>
