<template>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <input
              class="checkbox"
              type="checkbox"
              v-bind="$attrs"
              :checked="modelValue"
              @change="$emit('update:modelValue', $event.target.checked)"
          > <template v-if="help" class="help">{{ help }}</template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    help: {
      type: String,
      default: null
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  }
}
</script>
