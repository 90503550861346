<template>
  {{ stateLabel }}
</template>

<script>
const labels = {
  "INITIALIZED": "Ej ansökt",
  "APPLIED": "Inväntar godkännande",
  "APPROVED": "Godkänd",
  "PUBLISHED": "Publicerad",
  "OPENED": "Öppnad för anmälan",
  "CLOSED": "Stängd för anmälan",
  "STARTED": "Pågående",
  "ENDED": "Genomförd",
  "VERIFIED": "Verifierad",
  "REPORTED": "Rapporterad till SBK",
  "ARCHIVED": "Arkiverad",
  "SETTLED": "Ekonomiskt reglerad"
}
export default {
  name: "CompetitionState",
  props: {
    state: String
  },
  computed: {
    stateLabel() {
      return labels[this.state]
    }
  }
}
</script>

<style scoped></style>
