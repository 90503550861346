<template>
  <div v-if="results.result">
    <div class="columns">
      <div class="column is-one-fifth">
        <div class="buttons">
          <router-link
              v-if="showOperation('EDIT')"
              :to="{ name: 'ResultForm', id: result.id }"
              class="router-link"
          >
            <button class="button is-fullwidth is-link mb-3">
              <span class="icon">
                <i class="fa fa-edit"></i>
              </span>
              <span>Redigera</span>
            </button>
          </router-link>
        </div>
      </div>
      <div class="column is-four-fifths">
        <div class="card">
          <header class="card-header">
            <h3 class="card-header-title">
              Resultat för {{ result.dog.registeredName }} och
              {{ result.handler.givenName }} {{ result.handler.familyName }}
            </h3>
          </header>
          <div class="card-content">
            <div class="content">
              <table>
                <tbody>
                <tr>
                  <td class="has-text-left">Tävling:</td>
                  <td class="has-text-left">
                    <result-lists-link :competition="result.competitionClass.competition"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Klass:</td>
                  <td class="has-text-left">
                    <result-list-link :competition-class="result.competitionClass"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Hund:</td>
                  <td class="has-text-left">
                    <dog-link :dog="result.dog"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Förare:</td>
                  <td class="has-text-left">
                    <handler-link :handler="result.handler"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Resultat:</td>
                  <td class="has-text-left">
                    <score-label :result="result" :use-score="useScore"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <default-result-details
        v-if="resultType === 'DEFAULT'"
        :result="result"
    />
    <mental-result-details
        v-if="resultType === 'MENTAL'"
        :result="result"
    />
    <button @click="$router.go(-1)" class="button is-link">Tillbaka</button>
    <br/>
    <debug
        v-if="env.debug"
        :aggregate="result"
        :extra="[results.result.operations, results.result.editableFields]"
    />
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapState} from "vuex"
import Debug from "@/components/shared/Debug"
import DefaultResultDetails from "@/components/results/DefaultResultDetails"
import DogLink from "@/components/dogs/DogLink"
import HandlerLink from "@/components/handlers/HandlerLink"
import MentalResultDetails from "@/components/results/MentalResultDetails"
import ResultListLink from "@/components/result-lists/ResultListLink"
import ResultListsLink from "@/components/result-lists/ResultListsLink"
import ResultType from "@/helpers/ResultType"
import ScoreLabel from "@/components/labels/ScoreLabel"
import DomainConfig from "@/domain/DomainConfig"

export default {
  name: "Result",
  components: {
    Debug,
    DefaultResultDetails,
    DogLink,
    HandlerLink,
    MentalResultDetails,
    ResultListLink,
    ResultListsLink,
    ScoreLabel
  },
  props: ['id'],
  methods: {
    showOperation(op) {
      return _.includes(this.results.result.operations, op)
    },
    ...mapActions('results', ['get'])
  },
  created() {
    this.get(this.id)
  },
  computed: {
    result() {
      return this.results.result.aggregate
    },
    resultType() {
      return ResultType.get(this.result.competitionClass.type.id)
    },
    useScore() {
      return DomainConfig[this.result.competitionClass.type.domain].useScore
    },
    ...mapState(['results'])
  },
}
</script>
