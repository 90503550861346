<template>
  <div class="buttons">
    <router-link :to="'/competitions/' + competition.id + '/edit'" class="router-link">
      <button v-if="showOperation('EDIT')" class="button is-fullwidth is-link mb-3">
        <span class="icon">
          <i class="fa fa-edit"></i>
        </span>
        <span>Redigera</span>
      </button>
    </router-link>
    <button v-if="showOperation('ADD_CLASS')" class="button is-fullwidth is-link mb-3">
      <span class="icon">
        <i class="fa fa-plus-circle"></i>
      </span>
      <span>Lägg till en klass</span>
    </button>
    <button v-if="showOperation('APPLY')" class="button is-fullwidth is-link mb-3" @click="apply">
      <span class="icon">
        <i class="fa fa-paper-plane"></i>
      </span>
      <span>Ansök om godkännande</span>
    </button>
    <button v-if="showOperation('PUBLISH')" class="button is-fullwidth is-link mb-3" @click="publish">
      <span class="icon">
        <i class="fa fa-bullhorn"></i>
      </span>
      <span>Publicera</span>
    </button>
    <button v-if="showOperation('OPEN')" class="button is-fullwidth is-link mb-3" @click="open">
      <span class="icon">
        <i class="fa fa-clipboard-list"></i>
      </span>
      <span>Öppna</span>
    </button>
    <button v-if="showOperation('CLOSE')" class="button is-fullwidth is-link mb-3" @click="close">
      <span class="icon">
        <i class="fa fa-stop-circle"></i>
      </span>
      <span>Stäng anmälan</span>
    </button>
    <button v-if="showOperation('SHUFFLE')" class="button is-fullwidth is-link mb-3" @click="shuffle">
      <span class="icon">
        <i class="fa fa-list-ol"></i>
      </span>
      <span>Lotta alla klasser</span>
    </button>
    <button v-if="showOperation('START')" class="button is-fullwidth is-link mb-3" @click="start">
      <span class="icon">
        <i class="fa fa-play-circle"></i>
      </span>
      <span>Påbörja tävlingen</span>
    </button>
    <button v-if="showOperation('END')" class="button is-fullwidth is-link mb-3" @click="end">
      <span class="icon">
        <i class="fa fa-flag-checkered"></i>
      </span>
      <span>Avsluta tävlingen</span>
    </button>
    <button v-if="showOperation('VERIFY')" class="button is-fullwidth is-link mb-3" @click="verify">
      <span class="icon">
        <i class="fa fa-check-circle"></i>
      </span>
      <span>Verifiera tävlingen</span>
    </button>
    <button v-if="showOperation('REPORT')" class="button is-fullwidth is-link mb-3" @click="report">
      <span class="icon">
        <i class="fa fa-paper-plane"></i>
      </span>
      <span>Rapportera tävlingen</span>
    </button>
    <button v-if="showOperation('START')" class="button is-fullwidth is-link mb-3" @click="startLists">
      <span class="icon">
        <i class="fa fa-clipboard-list"></i>
      </span>
      <span>Startlistor</span>
    </button>
    <router-link
        v-if="showResultLists()"
        :to="{ name: 'ResultLists', params: {id: competition.id }}" class="router-link"
    >
      <button class="button is-fullwidth is-link mb-3">
        <span class="icon">
          <i class="fa fa-clipboard-list"></i>
        </span>
        <span>Resultatlistor</span>
      </button>
    </router-link>
    <router-link
        v-if="isArchived"
        :to="{name: 'CompetitionFinancials', params: {id: competition.id}}"
        class="router-link"
    >
      <button class="button is-fullwidth is-link mb-3">
        <span class="icon">
          <i class="fa fa-money-bill-alt"></i>
        </span>
        <span>Ekonomisk redovisning</span>
      </button>
    </router-link>
  </div>
</template>

<script>
import _ from 'lodash'
import CompetitionService from "@/services/CompetitionService"

export default {
  name: "CompetitionOperations",
  props: {
    competition: {
      type: Object,
      required: true
    },
    operations: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    showOperation(op) {
      return _.includes(this.operations, op)
    },
    inResultState() {
      return this.competition.state === 'ENDED'
    },
    isArchived() {
      return this.competition.state === 'ARCHIVED' || this.competition.state === 'SETTLED'
    },
    showResultLists() {
      return this.isArchived() || (this.inResultState() && this.showOperation('EDIT'))
    },
    apply() {
      this.changeState('APPLIED')
    },
    publish() {
      this.changeState('PUBLISHED')
    },
    open() {
      this.changeState('OPENED')
    },
    close() {
      this.changeState('CLOSED')
    },
    shuffle() {
      CompetitionService.shuffle(this.competition)
          .then(() => this.$router.go())
    },
    start() {
      this.changeState('STARTED')
    },
    startLists() {
      const ids = _.map(this.competition.competitionClasses, cc => {
        return cc.id
      }).join(",")
      window.open(process.env.VUE_APP_API_BASE_URL + '/start-lists/' + ids, "_blank")
    },
    end() {
      this.changeState('ENDED')
    },
    verify() {
      this.changeState('VERIFIED')
    },
    report() {
      this.changeState('REPORTED')
    },
    changeState(state) {
      CompetitionService.changeState(this.competition, state)
          .then(() => this.$router.go())
    }
  }
}
</script>

<style scoped>
.router-link {
  display: inline-block;
  width: 100%;
}
</style>
