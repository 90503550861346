<template>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <textarea
              class="textarea"
              v-bind="$attrs"
              :placeholder="label"
              :value="modelValue"
              @input="$emit('update:modelValue', $event.target.value)"
          />
        </div>
        <p v-if="help" class="help">{{ help }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    help: {
      type: String,
      default: null
    },
    modelValue: {
      type: [String, Number],
      default: ''
    }
  }
}
</script>
