<template>
  <table class="table is-striped">
    <tbody>
    <tr v-for="partGrade in result.partGrades" :key="partGrade.id">
      <td class="has-text-left">
        <result-part-label :result-part="partGrade.id"/>
      </td>
      <td>
        <select class="meScore" :id="partGrade.id">
          <option
              v-for="(label, index) in scoreLabels[partGrade.id].slice(1)"
              :value="index + 1"
              :key="label"
              :selected="partGrade.grade === index + 1"
          >
            {{index + 1}} - {{label}}
          </option>
        </select>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import ResultPartLabel from "@/components/labels/ResultPartLabel"
import MentalTranslator from "@/i18n/MentalTranslator"

export default {
  name: "MentalResultForm",
  components: {
    ResultPartLabel
  },
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  computed: {
    scoreLabels() {
      return MentalTranslator.scoreLabels
    }
  }
}
</script>
