export default {
    translate(identifier) {
        switch (identifier) {
            case 'CERTIFICATE':
                return 'Certifikat'
            case 'HP':
                return 'HP'
            case 'CK':
                return 'CK'
            case 'CACIT':
                return 'CACIT'
            case 'R_CACIT':
                return 'R-CACIT'
            case 'CACIT_BRUKS':
                return 'CACIT'
            case 'R_CACIT_BRUKS':
                return 'R-CACIT'
            case 'CACIOB':
                return 'CACIOB'
            case 'R_CACIOB':
                return 'R-CACIOB'
            case 'KAND_MENTAL_STATUS':
                return 'Känd mental status'
            case 'AVSTEG_FRAN_AVREAKTION':
                return 'Avsteg från avreaktion'
            case 'FORAREN_AVSTAR_SKOTT':
                return 'Föraren avstår skott'
            case 'PLAC_1':
                return '1:a plac.'
            case 'PLAC_2':
                return '2:a plac.'
            case 'PLAC_3':
                return '3:a plac.'
            case 'PLAC_4':
                return '4:e plac.'
            case 'UTBILDNINGSTECKEN':
                return 'Utbildningstecken'
            case 'DISQUALIFIED_IGP':
                return 'Diskvalificerad (IGP)'
            case 'DISQUALIFIED_RALLY':
                return 'Diskvalificerad (Rallylydnad)'
            default:
                return identifier
        }
    }
}
