<template>
  <div v-if="competitions.competition">
    <form @submit.prevent="handleSubmit">

      <base-input
          v-if="isEditable('NAME')"
          v-model="competition.name"
          label="Namn"
          type="text"
      />

      <base-input
          v-if="isEditable('START_DATE')"
          v-model="competition.startDate"
          label="Datum"
          type="date"
      />

      <base-input
          v-if="isEditable('START_DATE')"
          v-model="competition.secondDate"
          label="Datum dag 2"
          type="date"
          help="Anges endast för dubblerad eller delad tävling."
      />

      <base-input
          v-if="isEditable('START_DATE')"
          v-model="competition.registrationDeadline"
          label="Sista anmälningsdag"
          type="date"
      />

      <base-select
          v-if="isEditable('DISTRICT')"
          :options="districtOptions"
          v-model="competition.district"
          label="Distrikt"
      />

      <base-multiple-select
          v-if="isEditable('DISTRICT')"
          :options="organizerOptions"
          :size="3"
          v-model="competition.coOrganizers"
          label="Medarrangörer"
          multiple
      />

      <base-input
          v-if="isEditable('LOCATION')"
          v-model="competition.city"
          label="Ort"
          type="text"
      />

      <base-input
          v-if="isEditable('LOCATION')"
          v-model="competition.location"
          label="Tävlingsplats"
          type="text"
      />

      <base-input
          v-if="isEditable('LOCATION')"
          v-model="competition.locationCoords"
          label="GPS-koordinater"
          type="text"
      />

      <base-input
          v-if="isEditable('CONTACT')"
          v-model="competition.contact.name"
          label="Kontaktperson"
          type="text"
      />
      <base-input
          v-if="isEditable('CONTACT')"
          v-model="competition.contact.email"
          label="E-post till kontaktperson"
          type="text"
      />
      <base-input
          v-if="isEditable('CONTACT')"
          v-model="competition.contact.phone"
          label="Telefonnummer till kontaktperson"
          type="text"
      />
      <base-input
          v-if="isEditable('CONTACT')"
          v-model="competition.url"
          label="Hemsida"
          type="text"
      />

      <base-input
          v-if="isEditable('PAYMENT_CHANNELS')"
          v-model="competition.bankgiro"
          label="Bankgiro"
          type="text"
      />
      <base-input
          v-if="isEditable('PAYMENT_CHANNELS')"
          v-model="competition.plusgiro"
          label="Plusgiro"
          type="text"
      />
      <base-input
          v-if="isEditable('PAYMENT_CHANNELS')"
          v-model="competition.swish"
          label="Swish"
          type="text"
      />
      <base-input
          v-if="isEditable('PAYMENT_CHANNELS')"
          v-model="competition.organizer.stripe.accountId"
          label="Kontonummer hos Stripe"
          type="text"
          disabled="disabled"
      />

      <base-select
          v-if="isEditable('FUNCTIONARIES')"
          :options="competitionOfficerOptions"
          v-model="competition.competitionOfficer"
          label="Tävlingsledare"
      />

      <base-textarea
          v-if="isEditable('NAME')"
          v-model="competition.freeText"
          label="Fritextinformation"
      />

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label"></label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <button
                  class="button mr-6"
                  @click.prevent="onCancel"
              >
                Avbryt
              </button>
              <button class="button is-link">Spara ändringarna</button>
            </div>
          </div>
        </div>
      </div>

    </form>

    <debug
        v-if="env.debug"
        :aggregate="competition"
        :extra="[
            availableOperations,
            editableFields,
            competitions.competition.functionaries
        ]"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import {mapActions, mapState} from "vuex";
import BaseInput from "@/components/forms/BaseInput"
import BaseSelect from "@/components/forms/BaseSelect"
import BaseMultipleSelect from "@/components/forms/BaseMultipleSelect"
import BaseTextarea from "@/components/forms/BaseTextarea"
import CompetitionService from "@/services/CompetitionService"
import Debug from "@/components/shared/Debug"

export default {
  components: {
    BaseInput,
    BaseMultipleSelect,
    BaseSelect,
    BaseTextarea,
    Debug
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('competitions', ['get', 'getFormData']),
    isEditable(field) {
      return _.includes(this.editableFields, field)
    },
    onCancel() {
      this.$router.back()
    },
    handleSubmit() {
      const competitionId = this.id
      const router = this.$router
      CompetitionService.updateCompetition(this.competitions.competition)
          .then(function (response) {
            console.log('Successfully updated competition ' + competitionId, response)
            router.push({ name: 'Competition', id: competitionId})
          })
          .catch(function (error) {
            console.log('An exception occurred while updating competition ' + competitionId, error)
          })
    }
  },
  created() {
    this.getFormData(this.id)
  },
  computed: {
    competition() {
      return this.competitions.competition.aggregate
    },
    availableOperations() {
      return this.competitions.competition.operations
    },
    editableFields() {
      return this.competitions.competition.editableFields
    },
    competitionOfficerOptions() {
      let tmp = _.map(this.competitions.competition.functionaries.COMPETITION_OFFICER, function (it) {
        return {id: it.id, name: it.familyName + ', ' + it.givenName}
      })
      tmp.unshift({id: "null", name: 'Ej angiven'})
      return tmp
    },
    districtOptions() {
      return this.competitions.competition.districts
    },
    organizerOptions() {
      return this.competitions.competition.organizers
    },
    ...mapState(['competitions'])
  },
}
</script>
