<template>
  <tr class="draggable">
    <td class="has-text-left">
      <router-link :to="{name: 'Start', params: {id: it.id}}">
        #{{ it.id }}
      </router-link>
    </td>
    <td class="has-text-left">{{ it.number }}</td>
    <td class="has-text-left">
      <account-link :account="it.account"/>
    </td>
    <td class="has-text-left">
      <template v-if="!it.account.publiclyVisible">
        Ja
      </template>
    </td>
    <td class="has-text-left">
      <organizer-link :organizer="it.organizer"/>
    </td>
    <td class="has-text-left" title="Hunden är officiellt inmätt">
      <template v-if="it.dog.officialMankhojd">
        Ja
      </template>
    </td>
    <td class="has-text-left">
      <dog-link :dog="it.dog"/>
    </td>
    <td class="has-text-left">
      <handler-link :handler="it.handler"/>
    </td>
    <td class="has-text-left">
      <payment-link :payment="it.payment"/>&nbsp;
      <payment-state-label :identifier="it.payment.state"/>
      {{ it.payment.createdAt.substring(0, 10) }}
    </td>
    <td v-if="!it.result" title="Visa detaljer om starten">
      <router-link :to="{name: 'Start', params: {id: it.id}}">
        <i class="fas fa-eye"></i>
      </router-link>
    </td>
    <td v-if="it.result" title="Visa resultatet">
      <router-link :to="{name: 'Result', params: {id: it.result.id}}">
        <i class="fa fa-clipboard-list"></i>
      </router-link>
    </td>
    <td>
      <router-link :to="{name: 'StartForm', params: {id: it.id}}" title="Redigera starten">
        <i class="fas fa-user-edit"></i>
      </router-link>
    </td>
    <td v-if="it.result">
      <router-link :to="{name: 'ResultForm', params: {id: it.result.id}}" title="Redigera resultatet">
        <i class="far fa-edit"></i>
      </router-link>
    </td>
    <td v-if="canAddResult">
      <router-link :to="{name: 'ResultForm', params: {id: 'NEW_' + it.id}}" title="Lägg till ett resultat">
        <i class="fas fa-plus-square"></i>
      </router-link>
    </td>
    <td title="Återbetala starten">
      <a @click="reimburse(it.id)">
        <i class="fas fa-hand-holding-usd"></i>
      </a>
    </td>
  </tr>
</template>

<script>
import AccountLink from "@/components/accounts/AccountLink"
import DogLink from "@/components/dogs/DogLink"
import HandlerLink from "@/components/handlers/HandlerLink"
import OrganizerLink from "@/components/organizers/OrganizerLink"
import PaymentLink from "@/components/payments/PaymentLink"
import PaymentStateLabel from "@/components/labels/PaymentStateLabel"
import StartService from "@/services/StartService"

export default {
  name: "StartTableRow",
  components: {
    AccountLink,
    DogLink,
    HandlerLink,
    OrganizerLink,
    PaymentLink,
    PaymentStateLabel
  },
  props: {
    competitionClassState: {
      type: String,
      required: true
    },
    it: {
      type: Object,
      required: true
    }
  },
  methods: {
    reimburse(id) {
      StartService.reimburse(id)
    }
  },
  computed: {
    canAddResult() {
      return this.competitionClassState === 'ENDED'
    }
  }
}
</script>
