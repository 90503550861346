<template>
  {{ label }}
</template>

<script>

export default {
  name: "ShuffleTypeLabel",
  props: {
    shuffleType: {
      type: String,
      required: true
    }
  },
  computed: {
    label() {
      switch (this.shuffleType) {
        case "BRUKS":
          return "Bruks"
        case "SSRK_EXCLUSION":
          return "SSRK med manuell exkludering"
        case "RANDOM":
          return "Slumpmässig"
        default:
          return this.shuffleType
      }
    }
  }
}
</script>
