import AccountService from "@/services/AccountService"
import handleApiError from "@/store/handleApiError"

export const namespaced = true

export const state = {
    account: null,
    list: [],
    nTotal: 0
}

export const mutations = {
    SET_ACCOUNT(state, account) {
        state.account = account
    },
    SET_ACCOUNTS(state, accounts) {
        state.list = accounts
    },
    SET_N_ACCOUNTS(state, nTotal) {
        state.nTotal = nTotal
    }
}

export const actions = {
    getList({commit, dispatch}, {perPage, page, q}) {
        commit('SET_ACCOUNTS', [])
        AccountService.get(perPage, page, q)
            .then(response => {
                commit('SET_N_ACCOUNTS', parseInt(response.headers['x-total-count']))
                commit('SET_ACCOUNTS', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching accounts: ' + error.message
                }
                dispatch('notifications/add', notification, {root: true})
            })
    },
    get({commit, dispatch}, id) {
        commit('SET_ACCOUNT', null)
        AccountService.getById(id)
            .then(response => {
                commit('SET_ACCOUNT', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching account ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, {root: true})
            })
    }
}
