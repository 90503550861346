<template>
  <div class="field" :class="{'is-horizontal': layout.horizontal}">
    <div class="is-normal" :class="{'field-label': layout.horizontal}">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <div v-if="label" class="select">
            <select
                :value="safeModelValue.id"
                v-bind="{
                  ...$attrs,
                  onChange: ($event) => { $emit('update:modelValue', selectedOption($event.target.value)) }
                }"
                @blur="validationCallback"
            >
              <option
                  v-for="option in options"
                  :value="option.id"
                  :key="option.id"
                  :selected="option.id === safeModelValue.id"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
        <p v-if="!isValid" class="help is-danger">{{ validationMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Array,
      required: true
    },
    layout: {
      type: Object,
      default: () => {
        return {
          horizontal: true
        }
      }
    },
    validationCallback: {
      type: Function,
      default: () => {}
    },
    validationMessage: {
      type: String,
      default: 'Uppgiften måste anges'
    }
  },
  computed: {
    safeModelValue() {
      return this.modelValue || {};
    }
  },
  methods: {
    selectedOption(id) {
      return _.find(this.options, ['id', id])
    }
  }
}
</script>
