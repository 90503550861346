import axios from "axios"

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
})

export default {
  logIn(credentials) {
    return apiClient.post('/log-in', credentials)
  }
}
