<template>
  <div v-if="competitions.competition">
    <div class="columns">
      <div class="column is-one-fifth">
        <competition-operations :operations="availableOperations" :competition="competition"/>
      </div>
      <div class="column is-three-fifths">
        <div class="card">
          <header class="card-header">
            <h2 class="card-header-title">
              {{ competition.name }}, #{{ competition.id }}
            </h2>
          </header>
          <div class="card-content">
            <div class="content">
              <table>
                <tbody>
                <tr>
                  <td class="has-text-left">Status:</td>
                  <td class="has-text-left">
                    <competition-state :state="competition.state"/>
                    <template v-if="competition.state === 'ARCHIVED' || competition.state === 'SETTLED'">
                      <br/>
                      Arkiverad {{ competition.archivedAt }}.<br/>
                      <a target="_blank"
                         href="http://www.brukshundklubben.se/funktionar/prov-tavling/sbk-tavling/ansokan-om-andring-i-sbk-tavling/">
                        Ansök om ändring i arkiverad tävling
                      </a>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Arrangör:</td>
                  <td class="has-text-left">
                    <organizer-link :organizer="competition.organizer"/>
                    ({{ competition.district.name }})<br/>
                    <div v-if="competition.coOrganizers && competition.coOrganizers.length > 0">
                      <br/>Medarrangörer:<br/>
                      <div v-for="coOrganizer in competition.coOrganizers" :key="coOrganizer.id">
                        {{ coOrganizer.name }}<br/>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Information:</td>
                  <td class="has-text-left">{{ competition.freeText }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Extern länk:</td>
                  <td class="has-text-left">{{ competition.url }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Tävlingstyp:</td>
                  <td class="has-text-left">
                    <domain-label :domain="competition.domain"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Datum:</td>
                  <td class="has-text-left">{{ competition.startDate }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Sista anmälningsdag:</td>
                  <td class="has-text-left">{{ competition.registrationDeadline }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Godkända betalningsmetoder:</td>
                  <td class="has-text-left">
                    <payment-method-label v-for="(it, index) in competition.paymentMethods" :identifier="it" :key="it"
                                          :last-entry="index === competition.paymentMethods.length - 1"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Betalningsuppgifter:</td>
                  <td class="has-text-left">
                    <template v-if="competition.bankgiro">
                      Bankgiro: {{ competition.bankgiro }}<br/>
                    </template>
                    <template v-if="competition.plusgiro">
                      Plusgiro: {{ competition.plusgiro }}<br/>
                    </template>
                    <template v-if="competition.swish">
                      Swish: {{ competition.swish }}<br/>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Antal starter:</td>
                  <td class="has-text-left">{{ competition.nstarts }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Tävlingsplats:</td>
                  <td class="has-text-left">
                    <template v-if="competition.location">{{ competition.location }}<br/></template>
                    <template v-if="competition.city">
                      {{ competition.city }}
                    </template>
                    <template v-else>
                      Ej angiven stad
                    </template>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-fifth">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <h4>Kontaktperson</h4>
              <template v-if="competition.contact.name">
                {{ competition.contact.name }}<br/>
              </template>
              <template v-if="competition.contact.email">
                {{ competition.contact.email }}<br/>
              </template>
              <template v-if="competition.contact.phone">
                {{ competition.contact.phone }}<br/>
              </template>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <div class="content">
              <h4>Tävlingsledare</h4>
              <template v-if="competition.competitionOfficer">
                <functionary-link :functionary="competition.competitionOfficer"/>
              </template>
              <template v-else>
                Inte angiven
              </template>
            </div>
          </div>
        </div>
        <div class="card" v-if="false">
          <div class="card-content">
            <div class="content">
              <h4>Inbjudan</h4>
              <base-file
                  :aggregate-id="competition.id"
                  aggregate-type="COMPETITION"
                  asset-type="COMPETITION_INVITATION"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="table is-fullwidth is-striped" v-if="competitions.competition">
      <thead>
      <tr>
        <th class="has-text-left">ID</th>
        <th class="has-text-left">Klass</th>
        <th class="has-text-left">Anmälda</th>
        <th class="has-text-left">Max</th>
        <th class="has-text-left">Officiell</th>
        <th class="has-text-left">Avgift</th>
        <th class="has-text-left">Status</th>
        <th class="has-text-left">Domare</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <competition-class-table-row v-for="it in sortedClasses" :key="it" :it="it"/>
      </tbody>
    </table>
    <debug
        v-if="env.debug"
        :aggregate="competition"
        :extra="[availableOperations]"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import {mapActions, mapState} from 'vuex'
import BaseFile from "@/components/forms/BaseFile"
import CompetitionClassTableRow from "@/components/competitions/CompetitionClassTableRow"
import CompetitionOperations from "@/components/competitions/CompetitionOperations"
import CompetitionState from "@/components/competitions/CompetitionState"
import Debug from "@/components/shared/Debug"
import DomainLabel from '@/components/labels/DomainLabel'
import FunctionaryLink from "@/components/functionaries/FunctionaryLink"
import OrganizerLink from '@/components/organizers/OrganizerLink'
import PaymentMethodLabel from '@/components/labels/PaymentMethodLabel'

export default {
  name: "Competition",
  components: {
    BaseFile,
    CompetitionClassTableRow,
    CompetitionOperations,
    CompetitionState,
    Debug,
    DomainLabel,
    FunctionaryLink,
    OrganizerLink,
    PaymentMethodLabel
  },
  props: ['id'],
  methods: mapActions('competitions', ['get']),
  created() {
    this.get(this.id)
  },
  computed: {
    competition() {
      return this.competitions.competition.aggregate
    },
    sortedClasses() {
      return _.sortBy(this.competition.competitionClasses, [function(cc) { return cc.type.identifier }])
    },
    availableOperations() {
      return this.competitions.competition.operations
    },
    ...mapState(['competitions'])
  },
}
</script>

<style scoped>
div.card {
  margin-bottom: 1em;
}
</style>
