<template>
  {{ label }}
</template>

<script>
export default {
  name: "DomainLabel",
  props: {
    amountInCents: {
      type: Number,
      required: true
    }
  },
  computed: {
    label() {
      return `${this.amountInCents / 100} kr`
    }
  }
}
</script>
