<template>
  {{ label }}<template v-if="!lastEntry">, </template>
</template>

<script>
import _ from 'lodash'

export default {
  name: "PaymentStateLabel",
  props: {
    identifier: {
      type: String,
      required: true
    },
    lastEntry: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    label() {
      switch (this.identifier) {
        case "INITIALIZED":
          return "Påbörjad"
        case "PAID_ONLINE":
          return "Betald online"
        case "PAID_DIRECTLY":
          return "Direktbetald"
        case "REVOKED_ONLINE":
          return "Återbetald"
        case "REVOKED_DIRECTLY":
          return "Återbetalt direkt"
        case "NOT_PAID":
          return "Ej betald"
        default:
          return _.capitalize(this.identifier)
      }
    }
  }
}
</script>
