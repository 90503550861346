import handleApiError from "@/store/handleApiError"
import WizardService from "@/services/WizardService"

export const namespaced = true

export const state = {
    newCompetition: null,
}

export const mutations = {
    SET_NEW_COMPETITION(state, newCompetition) {
        state.newCompetition = newCompetition
    }
}

export const actions = {
    getNewCompetition({commit, dispatch}) {
        commit('SET_NEW_COMPETITION', null)
        WizardService.getNewCompetition()
            .then(response => {
                commit('SET_NEW_COMPETITION', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching new competition wizard: ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    }
}
