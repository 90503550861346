<template>
  <div v-if="handlers.handler">
    <div class="columns">
      <div class="column">
        <div class="card">
          <header class="card-header">
            <h2 class="card-header-title">
              {{ fullName }}
            </h2>
          </header>
          <div class="card-content">
            <div class="content">
              <table class="is-fullwidth">
                <tbody>
                <tr>
                  <td class="has-text-left">Tillhör konto:</td>
                  <td class="has-text-left">
                    <router-link :to="'/accounts/' + handlers.handler.account.id">
                      {{ handlers.handler.account.name }}
                    </router-link>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Medlem SBK:</td>
                  <td class="has-text-left">
                    <template v-if="handlers.handler.medlemsnummer !== ''">
                      Medlemsnummer {{ handlers.handler.medlemsnummer }}.
                    </template>
                    <template v-if="handlers.handler.activeMembership">
                      Aktivt medlemskap.
                      <template v-if="handlers.handler.membershipExpiresAt !== ''">
                        Giltigt till {{ handlers.handler.membershipExpiresAt }}.
                      </template>
                    </template>

                    <template v-if="handlers.handler.medlemsnummer === ''">
                      Inget medlemsnummer angivet.
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Medlemskap:</td>
                  <td class="has-text-left">
                    <template  v-for="m in handlers.handler.memberships" :key="m.organizer.id">
                      <organizer-link :organizer="m.organizer"/> <span class="date-range">({{ m.validFrom }} - {{ m.validThrough }})</span><br/>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Adress:</td>
                  <td class="has-text-left">
                    {{ handlers.handler.address1 }}<br/>
                    <template v-if="handlers.handler.address2 !== ''">
                      {{ handlers.handler.address2 }}<br/>
                    </template>
                    {{ handlers.handler.postalCode }}, {{ handlers.handler.city }}<br/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Email:</td>
                  <td class="has-text-left">{{ handlers.handler.email }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Phone:</td>
                  <td class="has-text-left">{{ phoneNumbers }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button @click="$router.go(-1)" class="button is-link">Tillbaka</button>
    </div>

    <debug
        v-if="env.debug"
        :aggregate="handlers.handler"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import {mapActions, mapState} from "vuex"
import _ from 'lodash'
import Debug from "@/components/shared/Debug"
import OrganizerLink from "@/components/organizers/OrganizerLink"

export default {
  name: "Handler",
  components: {
    Debug,
    OrganizerLink
  },
  props: ['id'],
  methods: mapActions('handlers', ['get']),
  created() {
    this.get(this.id)
  },
  computed: {
    fullName() {
      return this.handlers.handler.givenName + ' ' + this.handlers.handler.familyName
    },
    phoneNumbers() {
      const numbers = [
        this.handlers.handler.phoneMobile,
        this.handlers.handler.phoneWork,
        this.handlers.handler.phoneHome,
      ]
      return _.join(_.filter(numbers, function (obj) {
        return obj !== ''
      }), ', ')
    },
    ...mapState(['handlers'])
  },
}
</script>

<style scoped>
.date-range {
  font-size: 85%;
}
</style>
