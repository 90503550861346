<template>
  {{ label }}
</template>

<script>
export default {
  name: "BooleanLabel",
  props: {
    predicate: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    label() {
      return this.predicate ? "Ja" : "Nej"
    }
  }
}
</script>
