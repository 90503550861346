<template>
  <nav class="navbar nav-top">
    <div class="navbar-brand" id="navbar-brand">
      <a class="navbar-item brand-text" href="../index.html">
        <img src="../assets/images/logo.png" alt="Svenska brukshundklubben logo" class="logo"/>
      </a>
      <div @click="toggleMenu" :class="{'is-active': menuExpanded}" class="navbar-burger burger" data-target="navMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div
        v-if="loggedIn"
        id="navMenu"
        class="navbar-menu"
        :class="{'is-active': menuExpanded}"
    >
      <div class="navbar-start">
        <router-link class="navbar-item" :to="{ name: 'Accounts' }">Konton</router-link>
        <router-link class="navbar-item" to="/dogs">Hundar</router-link>
        <router-link class="navbar-item" to="/handlers">Förare</router-link>
        <router-link class="navbar-item" to="/organizers">Arrangörer</router-link>
        <router-link class="navbar-item" to="/functionaries">Funktionärer</router-link>
        <router-link class="navbar-item" to="/competitions">Tävlingar</router-link>
        <a class="navbar-item" @click="logOut">Logga ut</a>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <Search/>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Search from "@/components/Search"
import {mapActions} from "vuex";

export default {
  name: "Navigation",
  components: {
    Search
  },
  data() {
    return {
      menuExpanded: false
    }
  },
  methods: {
    toggleMenu() {
      this.menuExpanded = !this.menuExpanded
    },
    ...mapActions('user', ['logOut'])
  },
  computed: {
    loggedIn() {
      return localStorage.getItem('user')
    }
  }
}
</script>
