import DogService from "@/services/DogService"
import handleApiError from "@/store/handleApiError"

export const namespaced = true

export const state = {
    dog: null,
    list: [],
    nTotal: 0
}

export const mutations = {
    SET_DOG(state, dog) {
        state.dog = dog
    },
    SET_DOGS(state, dogs) {
        state.list = dogs
    },
    SET_N_DOGS(state, nTotal) {
        state.nTotal = nTotal
    }
}

export const actions = {
    getList({commit, dispatch}, {perPage, page, q}) {
        commit('SET_DOGS', [])
        DogService.get(perPage, page, q)
            .then(response => {
                commit('SET_N_DOGS', parseInt(response.headers['x-total-count']))
                commit('SET_DOGS', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching dogs: ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    },
    get({commit, dispatch}, id) {
        commit('SET_DOG', null)
        DogService.getById(id)
            .then(response => {
                commit('SET_DOG', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching dog ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    }
}
