<template>
  <tr>
    <td class="has-text-left">{{ timestamp(it.createdAt) }}</td>
    <td class="has-text-left"><price-label :amount-in-cents="it.grossStripe"/></td>
    <td class="has-text-left">
      <price-label :amount-in-cents="it.feesSbk"/>
      (varav moms <price-label :amount-in-cents="it.feesSbkVat"/>)
    </td>
    <td class="has-text-left"><price-label :amount-in-cents="it.net"/></td>
    <td>
      <a
          v-if="it.providerReference"
          :href="'https://dashboard.stripe.com/connect/transfers/' + it.providerReference" target="_blank">
        {{ it.providerReference }}
      </a>
      <template v-if="!it.providerReference">
        Ingen Stripe-referens tillgänglig
      </template>
    </td>
  </tr>
</template>

<script>
import PriceLabel from "@/components/labels/PriceLabel"
import {FormattingFilters} from "@/mixins/FormattingFilters"

export default {
  name: "SettlementTableRow",
  components: {
    PriceLabel
  },
  mixins: [FormattingFilters],
  props: {
    it: {
      type: Object,
      required: true
    }
  }
}
</script>
