<template>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <div v-if="label" class="select is-multiple">
            <select
                multiple
                :size="size"
                v-model="selected"
                @change="updateValue"
            >
              <option
                  v-for="option in options"
                  :value="option"
                  :key="option"
              >
                {{ translator(option) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  model: {
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 5
    },
    translator: {
      type: Function,
      default: id => {
        return 'Default translator: ' + id
      }
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selected: this.modelValue
    }
  },
  methods: {
    updateValue() {
      this.$emit('update:modelValue', this.selected)
    }
  }
}
</script>
