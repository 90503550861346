<template>
  <div class="field" :class="{'is-horizontal': layout.horizontal}">
    <div class="is-normal" :class="{'field-label': layout.horizontal}">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <input
              class="input"
              :class="{'is-danger': !isValid}"
              v-bind="$attrs"
              :placeholder="label"
              :value="modelValue"
              @input="$emit('update:modelValue', $event.target.value)"
              @blur="validationCallback"
          >
        </div>
        <p v-if="help" class="help">{{ help }}</p>
        <p v-if="!isValid" class="help is-danger">{{ validationMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: true
    },
    help: {
      type: String,
      default: null
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    validationCallback: {
      type: Function,
      default: () => {}
    },
    layout: {
      type: Object,
      default: () => {
        return {
          horizontal: true
        }
      }
    },
    validationMessage: {
      type: String,
      default: 'Uppgiften måste anges'
    }
  }
}
</script>
