<template>
  <router-link :to="'/handlers/' + handler.id">{{ fullName }}</router-link><template v-if="!lastEntry">, </template>
</template>

<script>
export default {
  name: "HandlerLink",
  props: {
    handler: {
      type: Object,
      required: true
    },
    lastEntry: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    fullName() {
      return `${this.handler.givenName} ${this.handler.familyName}`
    },
    sortName() {
      return `${this.handler.familyName}, ${this.handler.givenName}`
    }
  }
}
</script>
