<template>
  <router-link :to="'/accounts/' + payment.id">{{ payment.id }}</router-link><template v-if="!lastEntry">, </template>
</template>

<script>
export default {
  name: "PaymentLink",
  props: {
    payment: {
      type: Object,
      required: true
    },
    lastEntry: {
      type: Boolean,
      default: true
    }
  }
}
</script>
