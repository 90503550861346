import CompetitionService from "@/services/CompetitionService"
import handleApiError from "@/store/handleApiError"

export const namespaced = true

export const state = {
    competition: null,
    financials: null,
    list: [],
    nTotal: 0
}

export const mutations = {
    SET_COMPETITION(state, competition) {
        state.competition = competition
    },
    SET_COMPETITIONS(state, competitions) {
        state.list = competitions
    },
    SET_N_COMPETITIONS(state, nTotal) {
        state.nTotal = nTotal
    },
    SET_FINANCIALS(state, financials) {
        state.financials = financials
    }
}

export const actions = {
    list({commit, dispatch}, {perPage, page, states}) {
        commit('SET_COMPETITIONS', [])
        CompetitionService.getCompetitions(perPage, page, states)
            .then(response => {
                commit('SET_N_COMPETITIONS', parseInt(response.headers['x-total-count']))
                commit('SET_COMPETITIONS', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching competitions: ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    },
    get({commit, dispatch}, id) {
        commit('SET_COMPETITION', null)
        CompetitionService.getCompetition(id)
            .then(response => {
                commit('SET_COMPETITION', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching competition ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    },
    getFinancials({commit, dispatch}, id) {
        commit('SET_FINANCIALS', null)
        CompetitionService.getFinancials(id)
            .then(response => {
                commit('SET_FINANCIALS', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching financials for competition ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    },
    getFormData({commit, dispatch}, id) {
        commit('SET_COMPETITION', null)
        CompetitionService.getCompetitionWithFormData(id)
            .then(response => {
                commit('SET_COMPETITION', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching competition ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    }
}
