import ApiClient from '@/services/ApiClient'

export default {
  getStart(id) {
    return ApiClient.get('/starts/' + id)
  },

  getStartWithFormData(id) {
    return ApiClient.get(`/starts/${id}/form-data`)
  },

  update(start) {
    const aggregate = start.aggregate
    console.log(aggregate)
    const payload = {
      aggregateId: aggregate.id,
      competitionClassId: aggregate.competitionClass.id,
      handlerId: aggregate.handler.id,
      organizerId: aggregate.organizer.id
    }
    console.log(JSON.stringify(payload, null, 2))
    return ApiClient.post('/update-start', payload)
  },

  reimburse(id) {
    const payload = {
      aggregateId: id
    }
    console.log('Reimbursing start ' + id)
    return ApiClient.post('/reimburse-start', payload)
  }
}
