<template>
  <div v-if="handlers.list">
    <table class="table is-striped is-fullwidth">
      <thead>
      <tr>
        <th class="id-width">ID</th>
        <th class="has-text-left">Namn</th>
        <th class="has-text-left">Konto</th>
        <th class="has-text-left">Klubb</th>
        <th class="icon-width"></th>
        <th class="icon-width"></th>
      </tr>
      </thead>
      <tbody>
      <HandlerSummary v-for="it in handlers.list" :key="it.id" :handler="it"/>
      </tbody>
    </table>
    <Pagination link-name="Handlers" :per-page="perPage" :page="page" :n-total="nHandlers"/>

    <debug
        v-if="env.debug"
        :aggregate="handlers.list"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import {mapState, mapActions} from "vuex"
import Debug from "@/components/shared/Debug"
import HandlerSummary from "@/components/handlers/HandlerSummary"
import Pagination from "@/components/Pagination"

export default {
  name: "Handlers",
  components: {
    Debug,
    Pagination,
    HandlerSummary
  },
  created() {
    this.perPage = 10
    this.getList({
      perPage: this.perPage,
      page: this.page,
      q: this.$route.query.q
    })
  },
  methods: mapActions('handlers', ['getList']),
  computed: {
    nHandlers() {
      return this.$store.state.handlers.nTotal
    },
    page() {
      return parseInt(this.$route.query.page) || 1
    },
    hasNextPage() {
      return this.$store.state.handlers.nTotal > this.page * this.perPage
    },
    ...mapState(['handlers'])
  },
}
</script>
