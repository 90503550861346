<template>
  <router-link :to="{name: 'ResultLists', params: {id: competition.id}}">
    {{ competition.name }}
  </router-link><template v-if="!lastEntry">, </template>
</template>

<script>
export default {
  name: "ResultListsLink",
  props: {
    competition: {
      type: Object,
      required: true
    },
    lastEntry: {
      type: Boolean,
      default: true
    }
  }
}
</script>
