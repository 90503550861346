<template>
  <div v-if="competitionClasses.competitionClass">
    <div class="columns">
      <div class="column is-one-fifth">
        <div class="buttons">
          <button
              v-if="showOperation('VERIFY')"
              class="button is-fullwidth is-link mb-3"
              @click="changeState('VERIFIED')"
          >
              <span class="icon">
                <i class="fa fa-edit"></i>
              </span>
            <span>Verifiera klassen</span>
          </button>
        </div>
      </div>
      <div class="column is-three-fifths">
        <div class="card">
          <header class="card-header">
            <h3 class="card-header-title">
              Resultatlista&nbsp;&nbsp;•&nbsp;
              {{ competitionClass.name }}&nbsp;&nbsp;•&nbsp;
              #{{ competitionClass.id }}
            </h3>
          </header>
          <div class="card-content">
            <div class="content">
              <table>
                <tbody>
                <tr>
                  <td class="has-text-left">Status:</td>
                  <td class="has-text-left">
                    <competition-class-state :state="competitionClass.state"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Tävling:</td>
                  <td class="has-text-left">
                    <result-lists-link :competition="competitionClass.competition"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Antal starter:</td>
                  <td class="has-text-left">
                    {{ competitionClass.nstarts }} st
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-fifth">
      </div>
    </div>
    <table class="table is-fullwidth is-striped">
      <thead>
      <tr>
        <th class="has-text-left">Plac.</th>
        <th class="has-text-left">Startnr</th>
        <th class="has-text-left">Start-ID</th>
        <th class="has-text-left">Resultat</th>
        <th class="has-text-left">Hund</th>
        <th class="has-text-left">Förare</th>
        <th class="has-text-left">Klubb</th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <result-table-row
          v-for="it in sortedStarts"
          :key="it"
          :it="it"
          :domain="competitionClass.type.domain"
      />
      </tbody>
    </table>
    <router-link :to="{ name: 'Competition', id: competitionClass.competition.id }" class="router-link">
      <button class="button is-link mr-3">Tillbaka till tävlingen</button>
    </router-link>
    <br/>
    <debug
        v-if="env.debug"
        :aggregate="competitionClasses.competitionClass.aggregate"
        :extra="[competitionClasses.competitionClass.operations, competitionClasses.competitionClass.editableFields]"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import CompetitionClassService from "@/services/CompetitionClassService"
import CompetitionClassState from "@/components/competition-classes/CompetitionClassState"
import Debug from "@/components/shared/Debug"
import ResultListsLink from "@/components/result-lists/ResultListsLink"
import ResultTableRow from '@/components/result-lists/ResultTableRow'
import _ from "lodash";

export default {
  name: 'ResultList',
  components: {
    CompetitionClassState,
    Debug,
    ResultListsLink,
    ResultTableRow
  },
  props: ['id'],
  methods: {
    showOperation(op) {
      return _.includes(this.competitionClasses.competitionClass.operations, op)
    },
    changeState(state) {
      CompetitionClassService.changeState(this.competitionClasses.competitionClass.aggregate, state)
          .then(() => this.$router.push({
            name: 'ResultLists',
            params: {id: this.competitionClasses.competitionClass.aggregate.competition.id}
          }))
    },
    ...mapActions('competitionClasses', ['get'])
  },
  created() {
    this.get(this.id)
  },
  computed: {
    competitionClass() {
      return this.competitionClasses.competitionClass.aggregate
    },
    sortedStarts() {
      return _.sortBy(this.competitionClass.starts, (it) => {
        return it.result ? it.result.position : 10000
      })
    },
    ...mapState(['competitionClasses'])
  },
}
</script>

