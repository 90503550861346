export default {
    'BRUKS': {
        useRefereeGrades: true,
        useScore: true,
        hasFigs: true,
        validGrade: (grade) => {
            console.log(grade)
            if (grade === 0 || (grade >= 5 && grade <= 10))
                if ((grade * 2.0) % 1 === 0)
                    return true;
            return false;
        }
    },
    'IGP': {
        useRefereeGrades: true,
        useScore: true,
        hasFigs: true,
        validGrade: (grade) => {
            return grade >= 0 && grade <= 100 && (grade % 1 === 0)
        }
    },
    'LYDNAD': {
        useRefereeGrades: true,
        useScore: true,
        hasFigs: false,
        validGrade: (grade) => {
            if (grade === 0 || (grade >= 5 && grade <= 10))
                if ((grade * 2.0) % 1 === 0)
                    return true;
            return false;
        }
    },
    'MENTAL': {
        useRefereeGrades: true,
        useScore: true,
        hasFigs: true,
        validGrade: () => {
            return true
        }
    },
    'MR': {
        useRefereeGrades: true,
        useScore: true,
        hasFigs: true,
        validGrade: (grade) => {
            if (grade === 0 || (grade >= 5 && grade <= 10))
                if ((grade * 2.0) % 1 === 0)
                    return true;
            return false;
        }
    },
    'RALLY': {
        useRefereeGrades: true,
        useScore: true,
        hasFigs: false,
        validGrade: (grade) => {
            return grade >= 0 && grade <= 100 && (grade % 1 === 0)
        }
    },
    'RETRIEVER': {
        useRefereeGrades: false,
        useScore: false,
        hasFigs: false,
        validGrade: () => {
            return true
        }
    },
    'SPANIEL': {
        useRefereeGrades: false,
        useScore: false,
        hasFigs: false,
        validGrade: () => {
            return true
        }
    },
    'TOLLING': {
        useRefereeGrades: false,
        useScore: false,
        hasFigs: false,
        validGrade: () => {
            return true
        }
    },
    'VILTSPAR': {
        useRefereeGrades: false,
        useScore: false,
        hasFigs: false,
        validGrade: () => {
            return true
        }
    }
}
