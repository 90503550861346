<template>
  <router-link :to="'/accounts/' + district.id">{{ district.name }}</router-link><template v-if="!lastEntry">, </template>
</template>

<script>
export default {
  name: "DistrictLink",
  props: {
    district: {
      type: Object,
      required: true
    },
    lastEntry: {
      type: Boolean,
      required: true
    }
  }
}
</script>
