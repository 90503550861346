<template>
  <template v-if="contact.name">{{ contact.name }}<br/></template>
  <template v-if="contact.email">{{ contact.email }}<br/></template>
  <template v-if="contact.phone">{{ contact.phone }}</template>
</template>

<script>
export default {
  name: "Contact",
  props: {
    contact: {
      type: Object,
      required: true
    }
  }
}
</script>
