<template>
  <tr>
    <td class="has-text-left">#{{ it.id }}</td>
    <td class="has-text-left"><account-link :account="it.account"/></td>
    <td class="has-text-left"><dog-link :dog="it.dog"/></td>
    <td class="has-text-left"><handler-link :handler="it.handler"/></td>
    <td class="has-text-left">
      <payment-link :payment="it.payment"/>&nbsp;
      <payment-state-label :identifier="it.payment.state"/>
      {{ it.payment.createdAt.substring(0, 10) }}
    </td>
    <td title="Visa detaljer om starten">
      <router-link :to="'/starts/' + it.id">
        <i class="fas fa-eye"></i>
      </router-link>
    </td>
  </tr>
</template>

<script>
import AccountLink from "@/components/accounts/AccountLink"
import DogLink from "@/components/dogs/DogLink"
import HandlerLink from "@/components/handlers/HandlerLink"
import PaymentLink from "@/components/payments/PaymentLink"
import PaymentStateLabel from "@/components/labels/PaymentStateLabel"

export default {
  name: "StartTableRow",
  components: {
    AccountLink,
    DogLink,
    HandlerLink,
    PaymentLink,
    PaymentStateLabel
  },
  props: {
    it: {
      type: Object,
      required: true
    }
  }
}
</script>
