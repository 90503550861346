<template>
  <div v-if="competitions.competition">
    <div class="columns">
      <div class="column is-three-fifths">
        <div class="card">
          <header class="card-header">
            <h3 class="card-header-title">
              Resultatlistor för&nbsp;
              <competition-link :competition="competition"/>&nbsp;
              #{{ competition.id }}
            </h3>
          </header>
        </div>
      </div>
      <div class="column is-one-fifth">
      </div>
    </div>
    <table class="table is-fullwidth is-striped">
      <thead>
      <tr>
        <th class="has-text-left">ID</th>
        <th class="has-text-left">Klass</th>
        <th class="has-text-left">Anmälda</th>
        <th class="has-text-left">Officiell</th>
        <th class="has-text-left">Status</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <result-list-table-row v-for="it in competition.competitionClasses" :key="it" :it="it"/>
      </tbody>
    </table>
    <router-link :to="{ name: 'Competition', id: competition.id }" class="router-link">
      <button class="button is-link mr-3">Tillbaka till tävlingen</button>
    </router-link>
    <!--
    <div>{{ competition }}</div>
    <div>{{ availableOperations }}</div>
    -->
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import ResultListTableRow from "@/components/result-lists/ResultListTableRow"
import CompetitionLink from "@/components/competitions/CompetitionLink";

export default {
  name: "ResultLists",
  components: {
    CompetitionLink,
    ResultListTableRow
  },
  props: ['id'],
  methods: mapActions('competitions', ['get']),
  created() {
    this.get(this.id)
  },
  computed: {
    competition() {
      return this.competitions.competition.aggregate
    },
    availableOperations() {
      return this.competitions.competition.operations
    },
    ...mapState(['competitions'])
  },
}
</script>

<style scoped>
div.card {
  margin-bottom: 1em;
}
</style>
