<template>
  <div v-if="starts.start">
    <form @submit.prevent="handleSubmit">

      <base-select
          :options="starts.start.competitionClasses"
          v-model="start.competitionClass"
          label="Klass"
      />

      <base-select
          :options="starts.start.handlers"
          v-model="start.handler"
          label="Förare"
      />

      <base-select
          :options="starts.start.organizers"
          v-model="start.organizer"
          label="Klubb"
      />

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label"></label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <button
                  class="button mr-6"
                  @click.prevent="onCancel"
              >
                Avbryt
              </button>
              <button class="button is-link">Spara ändringarna</button>
            </div>
          </div>
        </div>
      </div>

    </form>
    <debug
        v-if="env.debug"
        :aggregate="starts.start.aggregate"
        :extra="[starts.start.operations, starts.start.competitionClasses, starts.start.handlers]"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import BaseSelect from "@/components/forms/BaseSelect"
import Debug from "@/components/shared/Debug"
import StartService from "@/services/StartService"

export default {
  components: {
    BaseSelect,
    Debug
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    onCancel() {
      this.$router.back()
    },
    handleSubmit() {
      const startId = this.id
      const router = this.$router
      StartService.update(this.starts.start)
          .then(function (response) {
            console.log('Successfully updated start ' + startId, response)
            router.push({name: 'Start', params: {id: startId}})
          })
          .catch(function (error) {
            console.log('An exception occurred while updating start ' + startId, error)
          })
    },
    ...mapActions('starts', ['get', 'getFormData']),
  },
  created() {
    this.getFormData(this.id)
  },
  computed: {
    start() {
      return this.starts.start.aggregate
    },
    availableOperations() {
      return this.starts.start.operations
    },
    ...mapState(['starts'])
  },
}
</script>
