export default {
    translate(identifier) {
        switch (identifier) {
            case 'DELAD':
                return 'Delad'
            case 'DUBBLERAD':
                return 'Dubblerad'
            case 'INTERNATIONELL':
                return 'Internationell'
            case 'FORMIDDAG':
                return 'Förmiddag'
            case 'INOMHUS':
                return 'Inomhus'
            case 'RASMASTERSKAP':
                return 'Rasmästerskap'
            case 'KVALL':
                return 'Kväll'
            case 'SM':
                return 'SM'
            case 'DM':
                return 'DM'
            case 'NATT':
                return 'Natt'
            case 'EFTERMIDDAG':
                return 'Eftermiddag'
            case 'KLUBBMASTERSKAP':
                return 'Klubbmästerskap'
            case 'IPO_KVAL':
                return 'IPO-kval'
            case 'PARSLAPP':
                return 'Parsläpp'
            case 'SINGELSLAPP':
                return 'Singelslapp'
            default:
                return identifier
        }
    }
}
