<template>
  <div class="columns">
    <div class="column">
      <div class="card">
        <header class="card-header">
          <h2 class="card-header-title">
            {{ f.familyName }}, {{ f.givenName }}
          </h2>
        </header>
        <div class="card-content">
          <div class="content">
            <table class="is-fullwidth">
              <tbody>
              <tr>
                <td class="has-text-left">Personnummer:</td>
                <td class="has-text-left">{{ f.personnummer }}</td>
              </tr>
              <tr>
                <td class="has-text-left">Adress:</td>
                <td class="has-text-left">
                  {{ f.address1 }}<br/>
                  <template v-if="f.address2 !== ''">
                    {{ f.address2 }}<br/>
                  </template>
                  {{ f.postalCode }}, {{ f.city }}<br/>
                </td>
              </tr>
              <tr>
                <td class="has-text-left">Email:</td>
                <td class="has-text-left">{{ f.email }}</td>
              </tr>
              <tr>
                <td class="has-text-left">Phone:</td>
                <td class="has-text-left">{{ phoneNumbers }}</td>
              </tr>
              <tr>
                <td class="has-text-left">Distrikt:</td>
                <td class="has-text-left">{{ f.district.name }}</td>
              </tr>
              <tr>
                <td class="has-text-left">Grenar:</td>
                <td class="has-text-left">
                  <domain-label v-for="it in f.domains" :key="it" :domain="it"/>
                </td>
              </tr>
              <tr>
                <td class="has-text-left">Auktorisationer:</td>
                <td class="has-text-left">
                  <functionary-authorization-label v-for="it in f.authorizations" :key="it" :authorization="it"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button @click="$router.go(-1)" class="button is-link">Tillbaka</button>
</template>

<script>
// @ is an alias to /src
import {mapActions, mapState} from "vuex"
import _ from 'lodash'
import DomainLabel from "@/components/labels/DomainLabel";
import FunctionaryAuthorizationLabel from "@/components/labels/FunctionaryAuthorizationLabel"

export default {
  name: "Functionary",
  components: {DomainLabel, FunctionaryAuthorizationLabel},
  props: ['id'],
  methods: {
    ...mapActions('functionaries', ['get'])
  },
  created() {
    this.get(this.id)
  },
  computed: {
    f() {
      return this.functionaries.functionary
    },
    phoneNumbers() {
      const numbers = [
        this.f.phoneMobile,
        this.f.phoneWork,
        this.f.phoneHome,
      ]
      return _.join(_.filter(numbers, function (obj) {
        return obj !== ''
      }), ', ')
    },
    ...mapState(['functionaries'])
  }
}
</script>
