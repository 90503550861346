<template>
  <div v-if="dogs.dog">
    <div class="columns">
      <div class="column">
        <div class="card">
          <header class="card-header">
            <h2 class="card-header-title">
              {{ dog.registeredName }} ({{ dog.registration }})
            </h2>
          </header>
          <div class="card-content">
            <div class="content">
              <table>
                <tbody>
                <tr>
                  <td class="has-text-left">Tillhör konto:</td>
                  <td class="has-text-left">
                    <router-link :to="'/accounts/' + dog.account.id">
                      {{ dog.account.name }}
                    </router-link>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Tilltalsnamn:</td>
                  <td class="has-text-left">{{ dog.name }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Ras:</td>
                  <td class="has-text-left">{{ dog.breed.name }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Tävlar i:</td>
                  <td class="has-text-left">
                    {{ ccts }}
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Mankhöjd:</td>
                  <td class="has-text-left">
                    {{ mankhojd }}
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Chip:</td>
                  <td class="has-text-left">
                    {{ dog.chipNumber }}
                    <template v-if="dog.chipPlacement">{{ dog.chipPlacement }}</template>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Tatuering:</td>
                  <td class="has-text-left">{{ dog.tattoo }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Födelsedatum:</td>
                  <td class="has-text-left">{{ dog.birthDate }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Kön:</td>
                  <td class="has-text-left">{{ dogGenderLabel(dog.gender) }}</td>
                </tr>
                <tr v-if="dog.mankhojd">
                  <td class="has-text-left">Mankhöjd:</td>
                  <td class="has-text-left">
                    {{ dog.mankhojd }} cm
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Verifierad av SKK:</td>
                  <td class="has-text-left">{{ booleanLabel(dog.skkVerified) }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Kan erhålla certifikat:</td>
                  <td class="has-text-left">{{ booleanLabel(dog.eligibleForCertificate) }}</td>
                </tr>
                <tr v-if="dog.prohibited">
                  <td class="has-text-left">Avstängd:</td>
                  <td class="has-text-left">Till och med {{ dog.prohibitedEnds }}</td>
                </tr>
                <tr>
                  <td class="has-text-left">Skapad:</td>
                  <td class="has-text-left">{{ timestamp(dog.createdAt) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button @click="$router.go(-1)" class="button is-link">Tillbaka</button>
    </div>

    <debug
        v-if="env.debug"
        :aggregate="dog"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import {mapActions, mapState} from "vuex"
import {FormattingFilters} from "@/mixins/FormattingFilters";
import Debug from "@/components/shared/Debug"

export default {
  name: "Dog",
  props: ['id'],
  mixins: [FormattingFilters],
  components: {
    Debug
  },
  methods: {
    ...mapActions('dogs', ['get'])
  },
  created() {
    this.get(this.id)
  },
  computed: {
    dog() {
      return this.dogs.dog
    },
    ccts() {
      if (this.dog.competitionClassTypes) {
        return this.dog.competitionClassTypes.map(it => it.name).join(', ')
      } else {
        return 'Inga klasser angivna'
      }
    },
    mankhojd() {
      if (this.dog.mankhojd && this.dog.mankhojd > 0) {
        let s = this.dog.mankhojd + ' cm'
        if (this.dog.officialMankhojd) {
          s += ' (Official inmätning)'
        } else {
          s += ' (Förarens uppgift)'
        }
        return s
      } else {
        return 'Ingen uppgift'
      }
    },
    ...mapState(['dogs'])
  },
}
</script>
