import HandlerService from "@/services/HandlerService"
import handleApiError from "@/store/handleApiError"

export const namespaced = true

export const state = {
    handler: null,
    list: [],
    nTotal: 0
}

export const mutations = {
    SET_HANDLER(state, handler) {
        state.handler = handler
    },
    SET_HANDLERS(state, handlers) {
        state.list = handlers
    },
    SET_N_HANDLERS(state, nTotal) {
        state.nTotal = nTotal
    }
}

export const actions = {
    getList({commit, dispatch}, {perPage, page, q}) {
        commit('SET_HANDLERS', [])
        HandlerService.get(perPage, page, q)
            .then(response => {
                commit('SET_N_HANDLERS', parseInt(response.headers['x-total-count']))
                commit('SET_HANDLERS', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching handlers: ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    },
    get({commit, dispatch}, id) {
        commit('SET_HANDLER', null)
        HandlerService.getById(id)
            .then(response => {
                commit('SET_HANDLER', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching handler ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    }
}
