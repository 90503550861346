import {createStore} from "vuex"
import * as accounts from "@/store/accounts"
import * as competitions from "@/store/competitions"
import * as competitionClasses from "@/store/competitionClasses"
import * as dogs from "@/store/dogs"
import * as functionaries from "@/store/functionaries"
import * as handlers from "@/store/handlers"
import * as notifications from "@/store/notifications"
import * as organizers from "@/store/organizers"
import * as results from "@/store/results"
import * as starts from "@/store/starts"
import * as user from "@/store/user"
import * as wizards from "@/store/wizards"

export default createStore({
    modules: {
        accounts,
        competitions,
        competitionClasses,
        dogs,
        functionaries,
        handlers,
        notifications,
        organizers,
        results,
        starts,
        user,
        wizards
    }
})
