<template>
  <div v-if="competitionClasses.competitionClass">
    <div class="columns">
      <div class="column is-one-fifth">
        <competition-class-operations :competition-class="competitionClass" :operations="availableOperations"/>
      </div>
      <div class="column is-three-fifths">
        <div class="card">
          <header class="card-header">
            <h3 class="card-header-title">
              <competition-link :competition="competitionClass.competition"/>&nbsp;&nbsp;•&nbsp;&nbsp;
              <competition-class-link :competition-class="competitionClass"/>&nbsp;&nbsp;•&nbsp;&nbsp;
              #{{ competitionClass.id }}
            </h3>
          </header>
          <div class="card-content">
            <div class="content">
              <table>
                <tbody>
                <tr>
                  <td class="has-text-left">Status:</td>
                  <td class="has-text-left">
                    <competition-class-state :state="competitionClass.state"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Tävling:</td>
                  <td class="has-text-left">
                    <competition-link :competition="competitionClass.competition"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Kategorier:</td>
                  <td class="has-text-left">
                    {{ categories }}
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Antal starter:</td>
                  <td class="has-text-left">
                    {{ competitionClass.nstarts }} totalt.
                    {{ nDirectPayments }} direktbetalningar, {{ nOnlinePayments }} betalda online
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Max antal starter:</td>
                  <td class="has-text-left">
                    {{ maxStartsLabel(competitionClass.maxStarts) }}
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Officiell:</td>
                  <td class="has-text-left">
                    <boolean-label :predicate="competitionClass.official"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Publicera startlista:</td>
                  <td class="has-text-left">
                    <boolean-label :predicate="competitionClass.publishStartList"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Lottningstyp:</td>
                  <td class="has-text-left">
                    <shuffle-type-label :shuffle-type="competitionClass.shuffleType"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Anmälningsavgift:</td>
                  <td class="has-text-left">
                    <price-label :amount-in-cents="competitionClass.price"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Tävlingsledare:</td>
                  <td class="has-text-left">
                    <functionary-link
                        v-if="competitionClass.competitionOfficer"
                        :functionary="competitionClass.competitionOfficer"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Domare:</td>
                  <td class="has-text-left">
                    <functionary-link
                        v-for="(functionary, index) in competitionClass.referees"
                        :key="functionary.id"
                        :functionary="functionary"
                        :last-entry="parseInt(index) === maxRefereeIndex"
                    />
                  </td>
                </tr>
                <tr v-if="hasFigs">
                  <td class="has-text-left">Figuranter:</td>
                  <td class="has-text-left">
                    <functionary-link
                        v-for="(functionary, index) in competitionClass.figs"
                        :key="functionary.id"
                        :functionary="functionary"
                        :last-entry="parseInt(index) === maxFigIndex"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Skrivare/sekreterare:</td>
                  <td class="has-text-left">
                    <functionary-link
                        v-if="competitionClass.secretary"
                        :functionary="competitionClass.secretary"
                    />
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-fifth">
      </div>
    </div>
    <table class="table is-fullwidth is-striped">
      <thead>
      <tr>
        <th class="has-text-left">ID</th>
        <th class="has-text-left">Startnr</th>
        <th class="has-text-left">Konto</th>
        <th class="has-text-left">Anon.</th>
        <th class="has-text-left">Klubb</th>
        <th class="has-text-left" title="Hunden är officiellt inmätt">Inm.</th>
        <th class="has-text-left">Hund</th>
        <th class="has-text-left">Förare</th>
        <th class="has-text-left">Betalning</th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <draggable
          v-model="competitionClass.starts"
          group="starts"
          @end="onDragEnd"
          item-key="id"
          tag="tbody"
      >
        <template #item="{element}">
          <start-table-row :it="element" :draggable="allowSorting" :competition-class-state="competitionClass.state"/>
        </template>
      </draggable>
    </table>
    <h3>Återbetalade starter</h3>
    <table class="table is-fullwidth is-striped" v-if="competitionClasses.competitionClass">
      <thead>
      <tr>
        <th class="has-text-left">ID</th>
        <th class="has-text-left">Konto</th>
        <th class="has-text-left">Hund</th>
        <th class="has-text-left">Förare</th>
        <th class="has-text-left">Betalning</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <reimbursed-start-table-row v-for="it in competitionClass.reimbursedStarts" :key="it" :it="it"/>
      </tbody>
    </table>
    <router-link :to="'/competitions/' + competitionClass.competition.id" class="router-link">
      <button class="button is-link mr-3">Tillbaka till tävlingen</button>
    </router-link>
    <debug
        v-if="env.debug"
        :aggregate="competitionClasses.competitionClass.aggregate"
        :extra="[competitionClasses.competitionClass.operations, competitionClasses.competitionClass.editableFields]"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import {mapActions, mapState} from 'vuex'
import draggable from "vuedraggable"
import {FormattingFilters} from "@/mixins/FormattingFilters"
import CompetitionClassService from "@/services/CompetitionClassService"
import BooleanLabel from "@/components/labels/BooleanLabel"
import CompetitionClassCategoryTranslator from "@/i18n/CompetitionClassCategoryTranslator"
import CompetitionClassLink from '@/components/competition-classes/CompetitionClassLink'
import CompetitionClassOperations from '@/components/competition-classes/CompetitionClassOperations'
import CompetitionClassState from "@/components/competition-classes/CompetitionClassState"
import CompetitionLink from '@/components/competitions/CompetitionLink'
import Debug from '@/components/shared/Debug'
import DomainConfig from '@/domain/DomainConfig'
import FunctionaryLink from "@/components/functionaries/FunctionaryLink"
import PriceLabel from "@/components/labels/PriceLabel"
import ReimbursedStartTableRow from "@/components/competition-classes/ReimbursedStartTableRow"
import ShuffleTypeLabel from "@/components/labels/ShuffleTypeLabel"
import StartTableRow from '@/components/competition-classes/StartTableRow'

export default {
  name: "CompetitionClass",
  components: {
    draggable,
    FunctionaryLink,
    BooleanLabel,
    CompetitionClassLink,
    CompetitionClassOperations,
    CompetitionClassState,
    CompetitionLink,
    Debug,
    PriceLabel,
    ReimbursedStartTableRow,
    ShuffleTypeLabel,
    StartTableRow
  },
  props: ['id'],
  mixins: [FormattingFilters],
  methods: {
    onDragEnd() {
      CompetitionClassService.updateStartOrder(this.competitionClass)
      this.$router.go()
    },
    ...mapActions('competitionClasses', ['get'])
  },
  created() {
    this.get(this.id)
  },
  computed: {
    allowSorting() {
      return this.competitionClass.state === 'SHUFFLED'
    },
    competitionClass() {
      return this.competitionClasses.competitionClass.aggregate
    },
    availableOperations() {
      return this.competitionClasses.competitionClass.operations
    },
    categories() {
      return _.join(_.map(this.competitionClass.categories,
          ((it) => CompetitionClassCategoryTranslator.translate(it.name))), ', ')
    },
    hasFigs() {
      return DomainConfig[this.competitionClass.type.domain].hasFigs
    },
    nDirectPayments() {
      return _.filter(this.competitionClass.starts, (it) => it.payment.state === 'PAID_DIRECTLY').length
    },
    nOnlinePayments() {
      return _.filter(this.competitionClass.starts, (it) => it.payment.state === 'PAID_ONLINE').length
    },
    maxFigIndex() {
      let maxIndex = 0
      if (this.competitionClass.figs['1']) maxIndex = 1
      if (this.competitionClass.figs['2']) maxIndex = 2
      if (this.competitionClass.figs['3']) maxIndex = 3
      return maxIndex
    },
    maxRefereeIndex() {
      let maxIndex = 0
      if (this.competitionClass.referees['1']) maxIndex = 1
      if (this.competitionClass.referees['2']) maxIndex = 2
      if (this.competitionClass.referees['3']) maxIndex = 3
      if (this.competitionClass.referees['4']) maxIndex = 4
      if (this.competitionClass.referees['5']) maxIndex = 5
      if (this.competitionClass.referees['6']) maxIndex = 6
      if (this.competitionClass.referees['7']) maxIndex = 7
      if (this.competitionClass.referees['8']) maxIndex = 8
      return maxIndex
    },
    ...mapState(['competitionClasses'])
  },
}
</script>

