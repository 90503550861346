export default {
    propertyName(id) {
      switch (id) {
          case 'OUTCOME':
              return 'outcome'
          case 'IP_GRADE':
              return 'ipGrade'
          case 'DSB':
              return 'dsb'
          default:
              return id
      }
    },
    translate(id) {
        switch (id) {
            case 'OUTCOME':
                return "Omdöme"
            case 'IP_GRADE':
                return "Omdöme"
            case 'DSB':
                return "DSB"

            case 'GODKAND':
                return 'Godkänd'
            case 'EJ_GODKAND':
                return 'Ej godkänd'
            case 'M':
                return 'M - Bristfällig'
            case 'B':
                return 'B - Tillfredsställande'
            case 'G':
                return 'G - God'
            case 'SG':
                return 'SG - Mycket god'
            case 'V':
                return 'V - Utmärkt'
            case 'NG':
                return 'NG - Otillräcklig'
            case 'VH':
                return 'VH - Tillräcklig'
            case 'A':
                return 'A - Utpräglad'
            case 'E':
                return 'E - Eliminated'
            case 'GOOD':
                return 'Good'
            case 'VERY_GOOD':
                return 'Very Good'
            case 'EXCELLENT':
                return 'Excellent'
            case 'PRICE_0':
                return '0-pris'
            case 'PRICE_1':
                return '1:a pris'
            case 'PRICE_2':
                return '2:a pris'
            case 'PRICE_3':
                return '3:e pris'
            case 'NC':
                return 'NC'
            case 'KEP':
                return 'KEP - Kan ej prisbedömas'
            case 'TO_EJ_VILT':
                return 'Godkänt tollingarbete, vilt ej fällt'
            case 'TO_G_APPORT':
                return 'Godkänt apporteringsarbete (tollingarbete godkänt vid tidigare tillfälle)'
            default:
                return id
        }
    }
}
