export default {
    get(identifier) {
        switch (identifier) {
            case 'ME_MH':
            case 'ME_MT':
                return 'MENTAL'
            default:
                return 'DEFAULT'
        }
    }
}
