<template>
  <div class="field has-addons">
    <div class="control">
      <!--suppress HtmlFormInputWithoutLabel -->
      <input @keyup.enter="search" class="input" type="text" v-model="q" placeholder="Ange text att söka på">
    </div>
    <div class="control">
      <a @click="search" class="button is-info">
        Sök
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Search',
  data() {
    return {
      q: this.$route.query.q
    }
  },
  watch: {
    '$route.query.q'() {
      this.q = this.$route.query.q
      this.search()
    }
  },
  methods: {
    search() {
      this.$router.push({path: this.$route.path, query: {q: this.q}})
    }
  }
}
</script>

<style scoped>
input {
  width: 160px;
}

.field {
  padding-right: 32px;
  padding-top: 6px;
}
</style>
