<template>
  <tr>
    <td class="has-text-left">
      <router-link :to="{ name: 'CompetitionClass', params: { id: it.id } }">
        #{{ it.id }}
      </router-link>
    </td>
    <td class="has-text-left">
      <result-list-link :competition-class="it"/>
    </td>
    <td class="has-text-left">{{ it.nstarts }}</td>
    <td class="has-text-left">
      <boolean-label :predicate="it.official"/>
    </td>
    <td class="has-text-left">
      <competition-class-state :state="it.state"/>
    </td>
    <td>
      <router-link :to="{ name: 'ResultList', params: { id: it.id } }">
        <i class="fas fa-eye"></i>
      </router-link>
    </td>
  </tr>
</template>

<script>
import BooleanLabel from "@/components/labels/BooleanLabel"
import CompetitionClassState from "@/components/competition-classes/CompetitionClassState"
import ResultListLink from "@/components/result-lists/ResultListLink";

export default {
  name: "ResultListTableRow",
  components: {
    BooleanLabel,
    CompetitionClassState,
    ResultListLink
  },
  props: {
    it: {
      type: Object,
      required: true
    }
  },
  computed: {
    maxRefereeIndex() {
      let maxIndex = 0
      if (this.it.referees['1']) maxIndex = 1
      if (this.it.referees['2']) maxIndex = 2
      if (this.it.referees['3']) maxIndex = 3
      if (this.it.referees['4']) maxIndex = 4
      if (this.it.referees['5']) maxIndex = 5
      if (this.it.referees['6']) maxIndex = 6
      if (this.it.referees['7']) maxIndex = 7
      if (this.it.referees['8']) maxIndex = 8
      return maxIndex
    }
  }
}
</script>
