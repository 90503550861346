import ResultService from "@/services/ResultService"
import handleApiError from "@/store/handleApiError"

export const namespaced = true

export const state = {
    result: null
}

export const mutations = {
    SET_RESULT(state, result) {
        state.result = result
    }
}

export const actions = {
    get({commit, dispatch}, id) {
        commit('SET_RESULT', null)
        ResultService.getResult(id)
            .then(response => {
                commit('SET_RESULT', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching result ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    },
    getFormData({commit, dispatch}, id) {
        commit('SET_RESULT', null)
        ResultService.getWithFormData(id)
            .then(response => {
                commit('SET_RESULT', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching result ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    }
}
