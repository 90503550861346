import CompetitionClassService from "@/services/CompetitionClassService"
import handleApiError from "@/store/handleApiError"

export const namespaced = true

export const state = {
    competitionClass: null
}

export const mutations = {
    SET_COMPETITION_CLASS(state, competitionClass) {
        state.competitionClass = competitionClass
    }
}

export const actions = {
    get({commit, dispatch}, id) {
        commit('SET_COMPETITION_CLASS', null)
        CompetitionClassService.getCompetitionClass(id)
            .then(response => {
                commit('SET_COMPETITION_CLASS', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching competition class ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    },
    getFormData({commit, dispatch}, id) {
        commit('SET_COMPETITION_CLASS', null)
        CompetitionClassService.getCompetitionClassWithFormData(id)
            .then(response => {
                commit('SET_COMPETITION_CLASS', response.data)
            })
            .catch(error => {
                handleApiError(error)
                const notification = {
                    type: 'error',
                    message: 'There was a problem fetching competition class ' + id + ': ' + error.message
                }
                dispatch('notifications/add', notification, { root: true })
            })
    }
}
