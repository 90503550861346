<template>
  <router-link :to="'/competition-classes/' + competitionClass.id">
    {{ competitionClass.type.name }}
  </router-link><template v-if="!lastEntry">, </template>
</template>

<script>
export default {
  name: "CompetitionClassLink",
  props: {
    competitionClass: {
      type: Object,
      required: true
    },
    lastEntry: {
      type: Boolean,
      default: true
    }
  }
}
</script>
