<template>
  <ul class="progress-indicator">
    <li :class="stateForStep(1)">
      <span class="bubble"></span> Om dig som ansöker
    </li>
    <li :class="stateForStep(2)">
      <span class="bubble"></span> Klubb & datum
    </li>
    <li :class="stateForStep(3)">
      <span class="bubble"></span> Typ av tävling
    </li>
    <li :class="stateForStep(4)">
      <span class="bubble"></span> Klasser
    </li>
    <li :class="stateForStep(5)">
      <span class="bubble"></span> Sammanfattning
    </li>
  </ul>

  <div v-if="wizards.newCompetition">
    <div class="columns is-centered">
      <div class="column mt-5">

        <form @submit.prevent="handleSubmit">

          <div v-if="currentStep === 1">
            <base-input
                v-model="w.contact.name"
                label="Namn"
                type="text"
                :layout="{horizontal: false}"
                :is-valid="isValid('name')"
                validation-message="Namn måste anges"
                :validation-callback="validateName"
            />
            <base-input
                v-model="w.contact.email"
                label="E-postadress"
                type="text"
                :layout="{horizontal: false}"
                :is-valid="isValid('email')"
                validation-message="En giltig e-postadress till den som ansöker måste anges"
                :validation-callback="validateEmail"
            />
            <base-input
                v-model="w.contact.phone"
                label="Telefonnummer"
                type="text"
                :layout="{horizontal: false}"
                :is-valid="isValid('phone')"
                validation-message="Ett giltigt telefonnummer till den som ansöker måste anges"
                :validation-callback="validatePhone"
            />
          </div>

          <div v-if="currentStep === 2">
            <base-select
                :options="newCompetition.organizerOptions"
                v-model="w.organizer"
                label="Arrangör"
                :layout="{horizontal: false}"
                :is-valid="isValid('organizer')"
                validation-message="Arrangör måste anges"
                :validation-callback="validateOrganizer"
            />
            <base-select
                :options="newCompetition.districtOptions"
                v-model="w.district"
                label="Distrikt"
                :layout="{horizontal: false}"
                :is-valid="isValid('district')"
                validation-message="Distrikt måste anges"
                :validation-callback="validateDistrict"
            />
            <base-input
                v-model="w.startDate"
                label="Datum"
                type="date"
                :layout="{horizontal: false}"
                :is-valid="isValid('startDate')"
                validation-message="Ett giltigt startdatum måste anges"
                :validation-callback="validateStartDate"
            />
            <base-input
                v-model="w.secondDate"
                label="Datum för dubblerad eller delad tävling"
                type="date"
                :layout="{horizontal: false}"
                :is-valid="isValid('secondDate')"
                validation-message="Fältet måste vara tomt eller innehålla ett giltigt datum"
                :validation-callback="validateSecondDate"
            />
          </div>

          <div v-if="currentStep === 3">
            <div v-for="domain in newCompetition.domains" :key="domain">
              <h3>{{ domain.name }}</h3>
              <table class="table is-striped is-narrowed is-fullwidth">
                <thead>
                <tr>
                  <th></th>
                  <th>Klass</th>
                  <th>Avgift</th>
                  <th title="Internationell">Int.</th>
                  <th>Inomhus</th>
                  <th>Förmiddag</th>
                  <th>Eftermiddag</th>
                  <th>Kväll</th>
                  <th>Natt</th>
                  <th>Dubblerad</th>
                  <th>Delad</th>
                  <th title="Rasmästerskap">RM</th>
                  <th>DM</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="cct in domain.competitionClassTypes" :key="cct">
                  <td><input type="checkbox"/></td>
                  <td>{{ cct.name }}</td>
                  <td><input type="text" :value="cct.defaultPrice / 100" style="width: 45px;"/> kr</td>
                  <td class="has-text-centered">
                    <input v-if="showCategory(cct.availableCategories, 'INTERNATIONELL')" type="checkbox"/>
                  </td>
                  <td class="has-text-centered">
                    <input v-if="showCategory(cct.availableCategories, 'INOMHUS')" type="checkbox"/>
                  </td>
                  <td class="has-text-centered">
                    <input v-if="showCategory(cct.availableCategories, 'FORMIDDAG')" type="checkbox"/>
                  </td>
                  <td class="has-text-centered">
                    <input v-if="showCategory(cct.availableCategories, 'EFTERMIDDAG')" type="checkbox"/>
                  </td>
                  <td class="has-text-centered">
                    <input v-if="showCategory(cct.availableCategories, 'KVALL')" type="checkbox"/>
                  </td>
                  <td class="has-text-centered">
                    <input v-if="showCategory(cct.availableCategories, 'NATT')" type="checkbox"/>
                  </td>
                  <td class="has-text-centered">
                    <input v-if="showCategory(cct.availableCategories, 'DUBBLERAD')" type="checkbox"/>
                  </td>
                  <td class="has-text-centered">
                    <input v-if="showCategory(cct.availableCategories, 'DELAD')" type="checkbox"/>
                  </td>
                  <td class="has-text-centered">
                    <input v-if="showCategory(cct.availableCategories, 'RASMASTERSKAP')" type="checkbox"/>
                  </td>
                  <td class="has-text-centered">
                    <input v-if="showCategory(cct.availableCategories, 'DM')" type="checkbox"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

        </form>
        <button
            class="button is-link is-pulled-left mt-5"
            v-if="currentStep > 1"
            @click="goToPreviousStep()"
        >
          <span class="icon">
            <i class="fa fa-arrow-circle-left"></i>
          </span>
          <span>Föregående steg</span>
        </button>
        <button
            class="button is-link is-pulled-right mt-5"
            v-if="currentStep < maxSteps"
            @click="goToNextStep()"
        >
          <span>Nästa steg</span>
          <span class="icon">
            <i class="fa fa-arrow-circle-right"></i>
          </span>
        </button>
      </div>
    </div>
    <debug
        v-if="env.debug"
        :aggregate="wizards"
    />
  </div>
</template>

<script>
import BaseInput from "@/components/forms/BaseInput"
import BaseSelect from "@/components/forms/BaseSelect"
import Debug from "@/components/shared/Debug"
import {mapActions, mapState} from "vuex"
import _ from 'lodash'

export default {
  name: "NewCompetitionWizard",
  components: {
    BaseInput,
    BaseSelect,
    Debug
  },
  data() {
    return {
      maxSteps: 5,
      currentStep: 3,
      validationStatus: {
        district: 'initial',
        email: 'initial',
        organizer: 'initial',
        name: 'initial',
        phone: 'initial',
        secondDate: 'initial',
        startDate: 'initial'
      },
      stepIsValidated: false,
      stepStatuses: {
        1: 'active',
        2: 'incomplete',
        3: 'incomplete',
        4: 'incomplete',
        5: 'incomplete'
      },
      w: {
        contact: {
          name: null,
          email: null,
          phone: null
        },
        organizer: {
          id: null,
          name: null
        },
        district: {
          id: null,
          name: null
        },
        secondDate: null,
        startDate: null
      }
    }
  },
  created() {
    const storedWizard = localStorage.getItem('newCompetitionWizard')
    if (storedWizard !== null) {
      this.w = JSON.parse(storedWizard)
    }
    this.getNewCompetition()
  },
  computed: {
    newCompetition() {
      return this.wizards.newCompetition
    },
    ...mapState(['wizards'])
  },
  methods: {
    goToPreviousStep() {
      this.storeState()
      this.stepStatuses[this.currentStep] = 'incomplete'
      this.currentStep--
      this.stepStatuses[this.currentStep] = 'active'
    },
    goToNextStep() {
      this.storeState()
      this.validateCurrent()
      if (this.stepIsValidated) {
        this.stepStatuses[this.currentStep] = 'completed'
        this.currentStep++
        this.stepStatuses[this.currentStep] = 'active'
      }
    },
    isValid(field) {
      return this.validationStatus[field] === 'initial' || this.validationStatus[field] === 'valid'
    },
    showCategory(categories, categoryId) {
      return _.includes(categories, categoryId)
    },
    stateForStep(step) {
      switch (this.stepStatuses[step]) {
        case 'active':
          return 'active'
        case 'incomplete':
          return 'bubble'
        case 'completed':
          return 'completed'
      }
    },
    storeState() {
      localStorage.setItem('newCompetitionWizard', JSON.stringify(this.w))
    },
    validateCurrent() {
      console.log('Validating step ' + this.currentStep)
      this.stepIsValidated = true
      switch (this.currentStep) {
        case 1:
          this.validateName()
          this.validateEmail()
          this.validatePhone()
          break
        case 2:
          this.validateOrganizer()
          this.validateDistrict()
          this.validateStartDate()
          break
      }
    },
    validateDistrict() {
      const district = this.w.district
      if (district.id === null) {
        this.validationStatus.district = 'invalid'
        this.stepIsValidated = false
      } else {
        this.validationStatus.district = 'valid'
      }
    },
    validateEmail() {
      const email = this.w.contact.email
      if (email === '' || email === null || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.validationStatus.email = 'invalid'
        this.stepIsValidated = false
      } else {
        this.validationStatus.email = 'valid'
      }
    },
    validateName() {
      const name = this.w.contact.name
      if (name === '' || name === null) {
        this.validationStatus.name = 'invalid'
        this.stepIsValidated = false
      } else {
        this.validationStatus.name = 'valid'
      }
    },
    validateOrganizer() {
      const organizer = this.w.organizer
      if (organizer.id === null) {
        this.validationStatus.organizer = 'invalid'
        this.stepIsValidated = false
      } else {
        this.validationStatus.organizer = 'valid'
      }
    },
    validatePhone() {
      const phone = this.w.contact.phone
      if (phone === '' || phone === null || !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(phone)) {
        this.validationStatus.phone = 'invalid'
        this.stepIsValidated = false
      } else {
        this.validationStatus.phone = 'valid'
      }
    },
    validateSecondDate() {
      const secondDate = this.w.secondDate
      if ((secondDate !== '' || secondDate !== null || secondDate === 'yyyy-mm-dd') && !/\d{4}-\d{2}-\d{2}/.test(secondDate)) {
        this.validationStatus.secondDate = 'invalid'
        this.stepIsValidated = false
      } else {
        this.validationStatus.secondDate = 'valid'
      }
    },
    validateStartDate() {
      const startDate = this.w.startDate
      if (startDate === '' || startDate === null || !/\d{4}-\d{2}-\d{2}/.test(startDate)) {
        this.validationStatus.startDate = 'invalid'
        this.stepIsValidated = false
      } else {
        this.validationStatus.startDate = 'valid'
      }
    },
    ...mapActions('wizards', ['getNewCompetition'])
  }
}
</script>

<style scoped>
.progress-indicator {
  margin-top: 40px;
}
</style>
