<template>
  {{ label }}
</template>

<script>
import _ from 'lodash'

export default {
  name: "DomainLabel",
  props: {
    domain: {
      type: String,
      required: true
    }
  },
  computed: {
    label() {
      switch (this.domain) {
        case "IGP":
          return "IGP"
        default:
          return _.capitalize(this.domain)
      }
    }
  }
}
</script>
