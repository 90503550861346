<template>
  <div v-if="starts.start">
    <div class="columns">
      <div class="column is-one-fifth">
        <div class="buttons">
          <router-link
              v-if="showOperation('EDIT')"
              :to="{ name: 'StartForm', id: start.id }"
              class="router-link"
          >
            <button class="button is-fullwidth is-link mb-3">
              <span class="icon">
                <i class="fa fa-edit"></i>
              </span>
              <span>Redigera starten</span>
            </button>
          </router-link>
          <template v-if="start.resultId">
            <router-link
                :to="{name: 'Result', params: {id: start.resultId}}"
                class="router-link"
            >
              <button class="button is-fullwidth is-link mb-3">
              <span class="icon">
                <i class="fa fa-eye"></i>
              </span>
                <span>Visa resultat</span>
              </button>
            </router-link>
            <router-link
                :to="{name: 'ResultForm', params: {id: start.resultId}}"
                class="router-link"
            >
              <button class="button is-fullwidth is-link mb-3">
                <span>Redigera resultat</span>
              </button>
            </router-link>
          </template>
        </div>
      </div>
      <div class="column is-four-fifths">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <table>
                <tbody>
                <tr>
                  <td class="has-text-left">Tävling:</td>
                  <td class="has-text-left">
                    <organizer-link :organizer="start.organizer"/>&nbsp;&nbsp;•&nbsp;
                    <competition-link :competition="start.competition"/>&nbsp;&nbsp;•&nbsp;
                    {{ start.competition.startDate }}
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Klass:</td>
                  <td class="has-text-left">
                    <competition-class-link :competition-class="start.competitionClass"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Hund:</td>
                  <td class="has-text-left">
                    <dog-link :dog="start.dog"/>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-left">Förare:</td>
                  <td class="has-text-left">
                    <handler-link :handler="start.handler"/>
                  </td>
                </tr>
                <tr v-if="start.payment">
                  <td class="has-text-left">Betalning:</td>
                  <td class="has-text-left">
                    <payment-link :payment="start.payment"/>&nbsp;&nbsp;•&nbsp;
                    <payment-state-label :identifier="start.payment.state"/>&nbsp;
                    {{ start.payment.createdAt.substring(0, 10) }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button @click="$router.go(-1)" class="button is-link">Tillbaka</button>
    <debug
        v-if="env.debug"
        :aggregate="starts.start.aggregate"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex"
import CompetitionClassLink from "@/components/competition-classes/CompetitionClassLink"
import CompetitionLink from "@/components/competitions/CompetitionLink"
import Debug from "@/components/shared/Debug"
import DogLink from "@/components/dogs/DogLink"
import HandlerLink from "@/components/handlers/HandlerLink"
import OrganizerLink from "@/components/organizers/OrganizerLink"
import PaymentLink from "@/components/payments/PaymentLink"
import PaymentStateLabel from "@/components/labels/PaymentStateLabel"
import _ from "lodash";

export default {
  name: "Start",
  components: {
    CompetitionClassLink,
    CompetitionLink,
    Debug,
    DogLink,
    HandlerLink,
    OrganizerLink,
    PaymentLink,
    PaymentStateLabel
  },
  props: ['id'],
  methods: {
    showOperation(op) {
      return _.includes(this.starts.start.operations, op)
    },
    ...mapActions('starts', ['get'])
  },
  created() {
    this.get(this.id)
  },
  computed: {
    start() {
      console.log(this.starts.start)
      return this.starts.start.aggregate
    },
    ...mapState(['starts'])
  },
}
</script>
