import ApiClient from '@/services/ApiClient'
import _ from "lodash";

export default {
    getCompetitions(perPage, page, states) {
        const url = '/competitions?perPage=' + perPage + '&page=' + page + '&states=' + states
        console.log(url)
        return ApiClient.get(url)
    },

    getCompetition(id) {
        return ApiClient.get('/competitions/' + id)
    },

    getCompetitionWithFormData(id) {
        return ApiClient.get(`/competitions/${id}/form-data`)
    },

    getFinancials(id) {
        return ApiClient.get(`/competitions/${id}/financials`)
    },

    updateCompetition(competition) {
        const aggregate = competition.aggregate

        const payload = {
            aggregateId: aggregate.id,
        }

        if (aggregate.contact && _.includes(competition.editableFields, 'CONTACT')) {
            payload['contact'] = {
                name: aggregate.contact.name,
                email: aggregate.contact.email,
                phone: aggregate.contact.phone
            }
            payload['url'] = aggregate.url
        }

        if (_.includes(competition.editableFields, 'DISTRICT')) {
            payload['districtId'] = aggregate.district.id
            payload['coOrganizerIds'] = aggregate.coOrganizers.map(it => it.id)
        }

        if (_.includes(competition.editableFields, 'LOCATION')) {
            payload['city'] = aggregate.city
            payload['location'] = aggregate.location
            payload['locationCoords'] = aggregate.locationCoords
        }

        if (_.includes(competition.editableFields, 'NAME')) {
            payload['name'] = aggregate.name
            payload['freeText'] = aggregate.freeText
        }

        if (_.includes(competition.editableFields, 'PAYMENT_CHANNELS')) {
            payload['bankgiro'] = aggregate.bankgiro
            payload['plusgiro'] = aggregate.plusgiro
            payload['swish'] = aggregate.swish
        }

        if (_.includes(competition.editableFields, 'START_DATE')) {
            payload['startDate'] = aggregate.startDate
            payload['secondDate'] = aggregate.secondDate
            payload['registrationDeadline'] = aggregate.registrationDeadline
        }

        if (_.includes(competition.editableFields, 'FUNCTIONARIES')) {
            if (aggregate.competitionOfficer) {
                payload['competitionOfficerId'] = aggregate.competitionOfficer.id
            }
        }

        console.log(JSON.stringify(payload, null, 2))
        return ApiClient.post('/update-competition', payload)
    },

    changeState(competition, toState) {
        const payload = {
            aggregateId: competition.id,
            toState: toState
        }
        console.log(JSON.stringify(payload, null, 2))
        return ApiClient.post('/change-state', payload)
    },

    shuffle(competition) {
        const payload = {
            aggregateId: competition.id
        }
        console.log(JSON.stringify(payload, null, 2))
        return ApiClient.post('/shuffle', payload)
    }
}
