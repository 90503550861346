<template>
  <h3>
    Resultat för
    {{ result.dog.registeredName }} med
    {{ result.handler.givenName }} {{ result.handler.familyName }} i
    {{ result.competitionClass.name }}
  </h3>
  <table v-if="useRefereeGrades" class="table is-fullwidth is-striped">
    <thead>
    <tr>
      <th class="has-text-left">Moment</th>
      <th>Koeff.</th>
      <th
          v-for="i in result.competitionClass.nreferees"
          :key="i"
      >
        Domare {{ i }}
      </th>
      <th v-if="result.competitionClass.nreferees > 1">Medel</th>
      <th>Poäng</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="partGrade in result.partGrades" :key="partGrade.id">
      <td class="has-text-left">
        <ResultPartLabel :result-part="partGrade.id"/>
      </td>
      <td>{{ partGrade.coefficient }}</td>
      <td
          v-for="refereeNumber in result.competitionClass.nreferees"
          :key="refereeNumber"
      >
        <input
            class="referee-grade"
            @blur="validateRefereeGrade"
            v-model="partGrade.refereeGrades[refereeNumber]"
            type="text"
        />
      </td>
      <td v-if="result.competitionClass.nreferees > 1">
        {{ partAverage(partGrade) }}
      </td>
      <td class="has-text-weight-semibold">
        {{ partScore(partGrade) }}
      </td>
    </tr>
    <tr v-if="result.competitionClass.nreferees > 1">
      <td class="has-text-weight-semibold">
        Totalt
      </td>
      <td>
        {{ totalCoefficient }}
      </td>
      <td v-for="i in result.competitionClass.nreferees" :key="i"/>
      <td v-if="result.competitionClass.nreferees > 1"></td>
      <td class="has-text-weight-bold is-size-5">{{ totalScore }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import ResultPartLabel from "@/components/labels/ResultPartLabel"
import _ from "lodash";
import DomainConfig from "@/domain/DomainConfig";

export default {
  name: "DefaultResultForm",
  components: {
    ResultPartLabel
  },
  methods: {
    partAverage(partGrade) {
      const nReferees = this.result.competitionClass.nreferees
      let sum = 0.0
      for (let i = 1; i <= nReferees; i++) {
        sum += parseFloat(partGrade.refereeGrades[i])
      }
      return sum / nReferees
    },
    partScore(partGrade) {
      return partGrade.coefficient * this.partAverage(partGrade)
    },
    validateRefereeGrade(evt) {
      const grade = parseFloat(evt.target.value)
      if (this.domainConfig.validGrade(grade)) {
        evt.target.className = evt.target.className.replace(' invalid-grade', '')
      } else {
        evt.target.className += ' invalid-grade'
      }
    }
  },
  computed: {
    domainConfig() {
      return DomainConfig[this.result.competitionClass.type.domain]
    },
    useRefereeGrades() {
      return this.domainConfig.useRefereeGrades
    },
    totalCoefficient() {
      return _.sumBy(this.result.partGrades, function (pg) {
        return pg.coefficient
      })
    },
    totalScore() {
      const nReferees = this.result.competitionClass.nreferees
      return _.sumBy(this.result.partGrades, function (pg) {
        let sum = 0.0
        for (let i = 1; i <= nReferees; i++) {
          sum += pg.refereeGrades[i] * pg.coefficient
        }
        return sum
      }) / nReferees
    }
  },
  props: {
    result: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.invalid-grade {
  background-color: #f39997;
}
</style>