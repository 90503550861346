import moment from "moment";

export const FormattingFilters = {
    methods: {
        booleanLabel(b) {
            return b ? "Ja" : "Nej"
        },
        dogGenderLabel(gender) {
            if (gender === 'MALE') {
                return "Hane"
            } else {
                return "Tik"
            }
        },
        maxStartsLabel(n) {
            if (n >= 10000000) {
                return 'Obegränsat'
            } else {
                return n
            }
        },
        timestamp(t) {
            return moment(t).format('YYYY-MM-DD HH:mm:ss')
        }
    }
}
