import axios from "axios";

let token = null
const user = JSON.parse(localStorage.getItem('user'))
if (user) {
    token = user.token
}

export default axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json; charset=UTF-8"
    }
})
