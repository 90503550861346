<template>
  <div v-if="results.result">
    <template v-if="isEditable('EDIT')">
      <form @submit.prevent="handleSubmit">

        <default-result-form
            v-if="resultType === 'DEFAULT'"
            :result="result"
        />
        <mental-result-form
            v-if="resultType === 'MENTAL'"
            :result="result"
        />

        <property-select
            v-for="key in selectableGradeKeys"
            :key="key"
            :options="results.result.selectableGrades[key]"
            v-model="result.selectableGrades[selectableGradePropertyName(key)]"
            :label="selectableGradeLabel(key)"
            :translator="selectableGradeTranslator"
        />

        <property-multiple-select
            v-if="meritOptions.length > 0"
            :options="meritOptions"
            v-model="result.merits"
            :size="meritOptions.length"
            label="Meriter"
            :translator="meritTranslator"
        />

        <property-multiple-select
            v-if="callOptions.length > 0"
            :options="callOptions"
            v-model="result.calls"
            :size="callOptions.length"
            label=" "
            :translator="callTranslator"
        />

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label"></label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <button
                    class="button mr-6"
                    @click.prevent="onCancel"
                >
                  Avbryt
                </button>
                <button class="button is-link">Spara ändringarna</button>
              </div>
            </div>
          </div>
        </div>

      </form>
    </template>
    <template v-else>
      Ditt konto har inte tillräckliga rättigheter för att redigera det här resultatet.
    </template>
    <debug
        v-if="env.debug"
        :aggregate="results.result.aggregate"
        :extra="[
            results.result.operations,
            results.result.editableFields,
            results.result.selectableGrades,
            results.result.calls,
            results.result.merits
        ]"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import {mapActions, mapState} from "vuex";
import PropertyMultipleSelect from "@/components/forms/PropertyMultipleSelect"
import PropertySelect from "@/components/forms/PropertySelect"
import Debug from "@/components/shared/Debug"
import DefaultResultForm from "@/components/results/DefaultResultForm"
import MentalResultForm from "@/components/results/MentalResultForm"
import ResultService from "@/services/ResultService"
import SelectableGradeTranslator from "@/i18n/SelectableGradeTranslator"
import ResultType from "@/helpers/ResultType"
import CallTranslator from "@/i18n/CallTranslator"
import MeritTranslator from "@/i18n/MeritTranslator"
import DomainConfig from "@/domain/DomainConfig"

export default {
  components: {
    PropertyMultipleSelect,
    PropertySelect,
    Debug,
    DefaultResultForm,
    MentalResultForm
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    isEditable(field) {
      return _.includes(this.results.result.operations, field)
    },
    handleSubmit() {
      if (this.invalidGrades()) return
      const resultId = this.id
      const competitionClassId = this.results.result.aggregate.competitionClass.id
      const router = this.$router
      if (this.resultType === 'MENTAL') {
        this.result.partGrades = _.map(document.querySelectorAll(".meScore"), (it) => {
          return {
            id: it.id,
            coefficient: 1,
            grade: parseInt(it.value),
            refereeGrades: {
              1: parseInt(it.value)
            }
          }
        })
      }
      ResultService.update(this.results.result)
          .then(function (response) {
            console.log('Successfully updated result ' + resultId, response)
            router.push({name: 'ResultList', params: {id: competitionClassId}})
          })
          .catch(function (error) {
            console.log('An exception occurred while updating result ' + resultId, error)
          })
    },
    invalidGrades() {
      try {
        _.forEach(this.result.partGrades, (partGrade) => {
          console.log(partGrade.refereeGrades)
          _.forEach(partGrade.refereeGrades, (refereeGrade) => {
                console.log('Checking ' + refereeGrade)
                if (!DomainConfig[this.result.competitionClass.type.domain].validGrade(parseFloat(refereeGrade))) {
                  throw 'Invalid grade ' + refereeGrade
                }
              }
          )
        })
      } catch (e) {
        console.log(e)
        return true
      }
      return false
    },
    selectableGradeLabel(id) {
      return SelectableGradeTranslator.translate(id)
    },
    selectableGradePropertyName(id) {
      return SelectableGradeTranslator.propertyName(id)
    },
    onCancel() {
      this.$router.back()
    },
    ...mapActions('results', ['get', 'getFormData']),
  },
  created() {
    this.getFormData(this.id)
  },
  computed: {
    callTranslator() {
      return CallTranslator.translate
    },
    meritTranslator() {
      return MeritTranslator.translate
    },
    selectableGradeTranslator() {
      return SelectableGradeTranslator.translate
    },
    result() {
      return this.results.result.aggregate
    },
    resultType() {
      return ResultType.get(this.result.competitionClass.type.id)
    },
    callOptions() {
      return this.results.result.calls
    },
    meritOptions() {
      return this.results.result.merits
    },
    selectableCalls() {
      return this.results.result.calls
    },
    selectableGradeKeys() {
      let a = Array()
      _.forOwn(this.results.result.selectableGrades, function (value, key) {
        a.push(key)
      })
      return a
    },
    availableOperations() {
      return this.results.result.operations
    },
    editableFields() {
      return this.results.result.editableFields
    },
    ...mapState(['results'])
  },
}
</script>
